import {
	Grid,
	makeStyles,
	Container,
	Paper,
	TextField,
	Tabs,
	Tab,
	TablePagination,
	TableHead,
	TableRow,
	TableCell,
	Checkbox,
	Table,
	TableBody,
	TableSortLabel,
	Toolbar,
	TableContainer,
	Modal,
	Typography,
	Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Loading from '../../../components/Loading';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import '.././FirmaAvanzada/Pdf.css';
import '.././FirmaAvanzada/Sign.css';
import info from '../../../assets/img/info.svg';
import guion from '../../../assets/img/guion.png';
import nube from '../../../assets/img/nube.svg';
import download from '../../../assets/img/download.svg';
import eliminar from '../../../assets/img/eliminar.svg';
import firma from '../../../assets/img/firma.svg';
import carpeta from '../../../assets/img/carpeta.png';
import { API_HOSTNAME_AWS, SPECIFIC_USERS } from '../../../redux/types/ApiConstants';
import MenuContextual from '../../../components/MenuContextual';
import { useSnackbar } from 'notistack';
import ModalDelete from './modales/ModalDelete';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteClickDer,
	firmClickDer,
	infoItem,
	modalDelete,
	modalFirmaAvanzadaCf,
	noAction,
	readAllAction,
} from '../../../redux/actions/CircuitoFirmasAction';
import { helpHttp } from '../../../helpers/helpHttp';
import GoogleDrive from './GoogleDrive';
import Dropbox from './Dropbox';
import { downloadFromS3CF } from '../../../redux/actions/ConsultSignedDocumentsAction';
import ModalFirmaAvanzadaCF from './modales/ModalFirmaAvanzadaCF';
import ConfirmDropboxCF from './ConfirmDropboxCF';
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------
import LineaProgreso from './LineaProgreso';
import OneDrive from './OneDrive';
import { useHistory } from 'react-router-dom';
import OneDriveCF from '../OneDriveCF';

const useStyles = makeStyles(() => ({
	root: {
		flexGrow: 1,
	},
	title: {
		margin: 0,
		height: '100px',
		paddingTop: '20px',
		color: 'white',
		paddingLeft: "2%",
	},
	tabs: {
		margin: '15px 25px 0',
	},
	seleccion: {
		textTransform: 'none',
		fontFamily: 'Mulish',
		fontSize: '16px',
		fontWeight: '600',
	},
	iconosMenu: {
		padding: '1px',
		margin: '9px',
		backgroundColor: 'transparent',
		color: '#878787',
		width: '20px',
		height: '20px',
		borderRadius: '5px',
		filter:
			'invert(54%) sepia(5%) saturate(10%) hue-rotate(18deg) brightness(98%) contrast(93%)',
	},
	filasMenu: {
		display: 'flex',
		justifyContent: 'start',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: '#878787',
			color: 'white',
			'& img': {
				filter:
					'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(209deg) brightness(110%) contrast(106%)',
			},
		},
	},
	textoMini: {
		marginLeft: '13px',
		fontSize: '10px',
	},
	menuDerecho: {
		boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
		width: '185px',
		height: '340px',
		borderRadius: '5px',
		backgroundColor: 'white',
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	titleEncabezado: {
		color: '#E55200',
		fontFamily: 'Mulish',
		padding: '2px 0',
		borderCollapse: 'collapse',
		fontWeight: '700',
		wordBreak: 'normal',
		boxSizing: 'border-box',
		fontSize: '13px',
		fontStyle: 'normal',
		lineHeight: '16px',
	},
	fila: {
		cursor: 'pointer',
		'&:hover': {
			'& span': {
				opacity: '1',
			},
		},
	},
	searchBar: {
		backgroundColor: '#EDEDED',
		height: '72px',
		width: '100%',
		padding: '15px',
		boxSizing: 'border-box',
		WebkitBorderTopLeftRadius: '10px',
		WebkitBorderTopRightRadius: '10px',
		MozBorderRadiusTopleft: '10px',
		MozBorderRadiusTopRigth: '10px',
		borderTopLeftRadius: '10px',
		borderTopRightRadius: '10px',
		'& input[type=number]': {
			'-moz-appearance': 'textfield',
		},
		'& input[type=number]::-webkit-outer-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},
		'& input[type=number]::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},
	},
	fechas: {
		fontSize: '14px',
	},
}));
function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}

// table headers with their styles
const headCells = [
	{
		id: 'idCircuito',
		numeric: true,
		disablePadding: false,
		label: 'No. Radicado',
		align: 'center',
		width: '12%',
	},
	{
		id: 'carpeta',
		numeric: false,
		disablePadding: true,
		label: '⠀ ⠀Documento',
		align: 'Left',
		width: '37%',
	},
	{
		id: 'fechaCreacion',
		numeric: false,
		disablePadding: true,
		label: 'Fecha de inicio',
		align: 'left',
		width: '15%',
	},
	{
		id: 'fechaModificacion',
		numeric: false,
		disablePadding: true,
		label: 'Última actualización',
		align: 'left',
		width: '18%',
	},
	{
		id: 'estadoCircuito',
		numeric: false,
		disablePadding: true,
		label: 'Estado',
		align: 'center',
		width: '15%',
	},
];

function EnhancedTableHead(props) {
	const classes = useStyles();
	const { order, orderBy, onRequestSort, value } = props;

	const createSortHandler = property => event => {
		onRequestSort(event, property);
	};

	const headCellsRender = [1, 3,  4].includes(value)
		? headCells.slice(0, headCells.length - 1).map(headCell => ({
				...headCell,
				width: headCell.width === '37%' ? '52%' : headCell.width,
		  }))
		: headCells;

	return (
		<TableHead className={'headerTableDocumentCircuit'}>
			<TableRow>
				<TableCell padding='none' />
				{headCellsRender.map(headCell => (
					<TableCell
						key={headCell.id}
						width={headCell.width}
						align={headCell.align}
						className={classes.titleEncabezado}
						padding='none'
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							className={classes.titleEncabezado}
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'desc'}
							onClick={createSortHandler(headCell.id)}
						>
							<span className={classes.titleEncabezado}>{headCell.label}</span>
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	title: {
		flex: '1 1 100%',
		textAlign: 'left',
	},
	grupoIcons: {
		display: 'flex',
		gap: '20px',
		borderLeft: '2px solid #d6d6d6',
		padding: '0 20px',
	},
	flex: {
		display: 'flex',
	},
	iconos: {
		border: '1px solid #F5AE19',
		backgroundColor: '#F5AE19',
		width: '32px',
		height: '32px',
		borderRadius: '5px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
	},
	estado: {
		border: '1px solid',
		margin: '0 3px',
		borderRadius: '10px',
		backgroundColor: '#058D78',
		color: 'white',
		fontSize: '11px',
		padding: '2px 0',
		width: '80px',
		margin: 'auto',
	},
}));

const useStylesCheckOranges = makeStyles({
	root: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: {
		borderRadius: 0,
		width: 16,
		height: 16,
		marginRight: '15px',
		boxShadow: 'inset 0 0 0 2px rgba(157, 157, 156,1.0)',
		backgroundColor: 'white',
		backgroundImage:
			'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.6)',
			outlineOffset: 2,
		},
	},
	checkedIcon: {
		backgroundColor: 'white',
		'&:before': {
			display: 'block',
			width: 16,
			height: 16,
			backgroundImage: `url(${guion})`,
			backgroundRepeat: 'no-repeat',
			marginTop: '40%',
			marginLeft: '18%',
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: 'white',
		},
	},
	iconRes: {
		borderRadius: 2,
		width: 18,
		height: 18,
		boxShadow: 'inset 0 0 0 1px rgba(157, 157, 156,1.0)',
		backgroundColor: 'white',
		backgroundImage:
			'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.6)',
			outlineOffset: 2,
		},
	},
	checkedIconRes: {
		backgroundColor: 'white',
		'&:before': {
			display: 'block',
			width: 18,
			height: 18,
			backgroundImage: `url(${guion})`,
			backgroundRepeat: 'no-repeat',
			marginTop: '40%',
			marginLeft: '20%',
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: 'white',
		},
	},
});

function StyledCheckboxes(props) {
	const classes = useStylesCheckOranges();
	return (
		<Checkbox
			className={classes.root}
			disableRipple
			checkedIcon={
				<span
					className={
						props.responsiveMode
							? clsx(classes.iconRes, classes.checkedIconRes)
							: clsx(classes.icon, classes.checkedIcon)
					}
				/>
			}
			icon={
				<span
					className={props.responsiveMode ? classes.iconRes : classes.icon}
				/>
			}
			inputProps={{ 'aria-label': 'decorative checkbox' }}
			{...props}
		/>
	);
}

const useStylesCheckOrange = makeStyles({
	root: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: {
		borderRadius: 0,
		width: 16,
		height: 16,
		opacity: '0',
		boxShadow: 'inset 0 0 0 2px rgba(157, 157, 156,1.0)',
		backgroundColor: 'white',
		backgroundImage:
			'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.6)',
			outlineOffset: 2,
		},
	},
	checkedIcon: {
		opacity: '1',
		backgroundColor: 'white',
		backgroundImage:
			'linear-gradient(10deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
			display: 'block',
			width: 16,
			height: 16,
			backgroundImage:
				"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
				" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
				"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='rgb(229, 82, 0)'/%3E%3C/svg%3E\")",
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: 'white',
		},
	},
});

function StyledCheckbox(props) {
	const classes = useStylesCheckOrange();
	return (
		<Checkbox
			className={classes.root}
			disableRipple
			checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
			icon={<span className={classes.icon} />}
			inputProps={{ 'aria-label': 'decorative checkbox' }}
			{...props}
		/>
	);
}

const EnhancedTableToolbarAll = props => {
	const { numSelected, selected, data, handleSelectAllClick, setSelected } =
		props;
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const classes = useToolbarStyles();
	const [loading, setLoading] = useState(false);

	const handleDelete = () => {
		// Mostrar modal de eliminar
		dispatch(modalDelete(true));
		//Envio de los datos a eliminar
		dispatch(deleteClickDer(selected));
	};

	function toDataUrl(url, callback) {
		var xhr = new XMLHttpRequest();
		xhr.onload = function () {
			var reader = new FileReader();
			reader.onloadend = function () {
				callback(reader.result);
			};
			reader.readAsDataURL(xhr.response);
		};
		xhr.open('GET', url);
		xhr.responseType = 'blob';
		xhr.send();
	}
	const his = useHistory();
	const handleClickItem = async e => {
		const listSelect = [];
		selected.forEach(valor => {
			listSelect.push(data.find(n => n.idCircuito === valor));
		});
		let dx = listSelect[0].cifrado;
		his.push(`/home/DetalleCircuito/${dx}`);
	};

	const handleClickDownload = async e => {
		//Cortina de carga
		setLoading(true);
		//Ajustando la estructura de los datos para enviarlos al dispatch de la funcionalidad
		const listSelect = [];
		selected.forEach(valor => {
			listSelect.push(data.find(n => n.idCircuito === valor));
		});
		const files = listSelect.map(el => el.documentos).flat();
		//Envio de los datos para la descarga
		dispatch(downloadFromS3CF(files, enqueueSnackbar));
		//Desseleccionando los datos
		setSelected([]);
		//Quitando cortina de carga
		setLoading(false);
	};

	if (loading) return <Loading />;

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0,
			})}
		>
			{numSelected > 0 && (
				<>
					<StyledCheckboxes
						className={'checkButtonDocumentSigned'}
						indeterminate={
							props.numSelected > 0 && props.numSelected < props.rowCount
						}
						onChange={handleSelectAllClick}
						checked={true}
					/>
					<div className={classes.flex}>
						{numSelected === 1 && (
							<div className={classes.grupoIcons}>
								<div
									className={classes.iconos}
									onClick={handleClickItem}
									data-title='Ver detalle'
								>
									<img src={info} />
								</div>
							</div>
						)}
						<div className={classes.grupoIcons}>
							<div
								className={classes.iconos}
								data-title='Descargar'
								onClick={e => handleClickDownload(e)}
							>
								<img src={download} />
							</div>
							<div
								className={classes.iconos}
								data-title='Eliminar'
								onClick={handleDelete}
							>
								<img src={eliminar} />
							</div>
						</div>
					</div>
				</>
			)}
		</Toolbar>
	);
};

EnhancedTableToolbarAll.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = props => {
	const dispatch = useDispatch();
	const classes = useToolbarStyles();
	const { enqueueSnackbar } = useSnackbar();
	const {
		numSelected,
		handleSelectAllClickCompleted,
		data,
		selected,
		setSelected,
	} = props;
	const [loading, setLoading] = useState(false);
	const [statusModalGoogleDrive, setStatusModalGoogleDrive] = useState(false);
	const [statusModalOneDrive, setStatusModalOneDrive] = useState(false);
	const [tokenOneDrive, setTokenOneDrive] = useState(null);

	const handleDelete = () => {
		// Mostrar modal de eliminar
		dispatch(modalDelete(true));
		//Envio de los datos a eliminar
		dispatch(deleteClickDer(selected));
	};

	const handleClickDownload = async e => {
		//Cortina de carga
		setLoading(true);
		//Ajustando la estructura de los datos para enviarlos al dispatch de la funcionalidad
		const listSelect = [];
		selected.forEach(valor => {
			listSelect.push(data.find(n => n.idCircuito === valor));
		});
		const files = listSelect.map(el => el.documentos).flat();
		//Envio de los datos para la descarga
		dispatch(downloadFromS3CF(files, enqueueSnackbar));
		//Desseleccionando los datos
		setSelected([]);
		//Quitando cortina de carga
		setLoading(false);
	};

	const handleFirmar = () => {
		//dispatch para que aparezca el modal
		dispatch(modalFirmaAvanzadaCf(true));
		//Envio de datos seleccionados
		dispatch(firmClickDer(selected));
	};
	const his = useHistory();

	const handleClickItem = async e => {
		const listSelect = [];
		selected.forEach(valor => {
			listSelect.push(data.find(n => n.idCircuito === valor));
		});
		let dx = listSelect[0].cifrado;
		his.push(`/home/DetalleCircuito/${dx}`);
	};

	const handleClickOneDrive = e => {
		//Cambiando el estado del modal
		setStatusModalOneDrive(true);
	};

	if (loading) return <Loading />;

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0,
			})}
		>
			{numSelected === 1 && (
				<>
					<StyledCheckboxes
						className={'checkButtonDocumentSigned'}
						indeterminate={
							props.numSelected > 0 && props.numSelected < props.rowCount
						}
						onChange={handleSelectAllClickCompleted}
						checked={true}
					/>
					<div className={classes.flex}>
						<div className={classes.grupoIcons}>
							<div
								className={classes.iconos}
								onClick={handleClickItem}
								data-title='Ver detalle'
							>
								<img src={info} />
							</div>
							<div
								className={classes.iconos}
								data-title='Firmar'
								onClick={() => handleFirmar()}
							>
								<img src={firma} />
							</div>
						</div>
						<div className={classes.grupoIcons}>
							<div
								className={classes.iconos}
								data-title='Descargar'
								onClick={e => handleClickDownload(e)}
							>
								<img src={download} />
							</div>
							<div
								className={classes.iconos}
								data-title='Eliminar'
								onClick={handleDelete}
							>
								<img src={eliminar} />
							</div>
						</div>
						<div className={classes.grupoIcons}>
							<div
								className={classes.iconos}
								data-title='Guardar en Google Drive'
							>
								<GoogleDrive
									open={statusModalGoogleDrive}
									disableBackdropClick
									onClose={() => setStatusModalGoogleDrive(false)}
									setStatusModalGoogleDrive={setStatusModalGoogleDrive}
									selected={selected}
									rows1={data}
									setSelected={setSelected}
								></GoogleDrive>
							</div>
							<div className={classes.iconos}>
								<Dropbox
									selected={selected}
									setSelected={setSelected}
									rows={data}
								/>
							</div>
							<div
								className={classes.iconos}
								data-title='Guardar en OneDrive'
								onClick={handleClickOneDrive}
							>
								<img src={nube} />
							</div>
						</div>
					</div>
					<Modal
						open={statusModalOneDrive}
						disableBackdropClick
						onClose={() => {
							setStatusModalOneDrive(0);
						}}
						className={classes.modalpopUpshare}
					>
						<div>
							<OneDrive
								tokenOneDrive={tokenOneDrive}
								setTokenOneDrive={setTokenOneDrive}
								setStatusModalOneDrive={setStatusModalOneDrive}
								selected={selected}
								rows1={data}
								setSelected={setSelected}
							/>
						</div>
					</Modal>
				</>
			)}
			{numSelected > 1 && (
				<>
					<StyledCheckboxes
						className={'checkButtonDocumentSigned'}
						indeterminate={
							props.numSelected > 0 && props.numSelected < props.rowCount
						}
						onChange={handleSelectAllClickCompleted}
						checked={true}
					/>
					<div className={classes.flex}>
						<div className={classes.grupoIcons}>
							<div
								className={classes.iconos}
								data-title='Descargar'
								onClick={e => handleClickDownload(e)}
							>
								<img src={download} />
							</div>
							<div
								className={classes.iconos}
								data-title='Eliminar'
								onClick={handleDelete}
							>
								<img src={eliminar} />
							</div>
						</div>
						<div className={classes.grupoIcons}>
							<div
								className={classes.iconos}
								data-title='Guardar en Google Drive'
							>
								<GoogleDrive
									open={statusModalGoogleDrive}
									disableBackdropClick
									onClose={() => setStatusModalGoogleDrive(false)}
									setStatusModalGoogleDrive={setStatusModalGoogleDrive}
									selected={selected}
									rows1={data}
									setSelected={setSelected}
								></GoogleDrive>
							</div>
							<div className={classes.iconos} data-title='Guardar en Dropbox'>
								<Dropbox
									selected={selected}
									setSelected={setSelected}
									rows={data}
								/>
							</div>
							<div
								className={classes.iconos}
								data-title='Guardar en OneDrive'
								onClick={handleClickOneDrive}
							>
								<img src={nube} />
							</div>
						</div>
					</div>
					<Modal
						open={statusModalOneDrive}
						disableBackdropClick
						onClose={() => {
							setStatusModalOneDrive(0);
						}}
						className={classes.modalpopUpshare}
					>
						<div>
							<OneDrive
								tokenOneDrive={tokenOneDrive}
								setTokenOneDrive={setTokenOneDrive}
								setStatusModalOneDrive={setStatusModalOneDrive}
								selected={selected}
								rows1={data}
								setSelected={setSelected}
							/>
						</div>
					</Modal>
				</>
			)}
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

//------------------------------------------------------------------------------------------------------
const EnhancedTableToolbarActive = props => {
	const dispatch = useDispatch();
	const classes = useToolbarStyles();
	const { enqueueSnackbar } = useSnackbar();
	const {
		numSelected,
		handleSelectAllClickActive,
		data,
		selected,
		setSelected,
	} = props;
	const [loading, setLoading] = useState(false);
	const [statusModalOneDrive, setStatusModalOneDrive] = useState(false);
	// const [tokenOneDrive, setTokenOneDrive] = useState(null);

	const handleDelete = () => {
		// Mostrar modal de eliminar
		dispatch(modalDelete(true));
		dispatch(deleteClickDer(selected));
	};
	const handleClickDownload = async e => {
		setLoading(true);
		const listSelect = [];
		selected.forEach(valor => {
			listSelect.push(data.find(n => n.idCircuito === valor));
		});
		const files = listSelect.map(el => el.documentos).flat();
		dispatch(downloadFromS3CF(files, enqueueSnackbar));
		setSelected([]);
		setLoading(false);
	};
	const his = useHistory();
	const handleClickItem = async e => {
		const listSelect = [];
		selected.forEach(valor => {
			listSelect.push(data.find(n => n.idCircuito === valor));
		});
		let dx = listSelect[0].cifrado;

		his.push(`/home/DetalleCircuito/${dx}`);
	};

	if (loading) return <Loading />;

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0,
			})}
		>
			{numSelected === 1 && (
				<>
					<StyledCheckboxes
						className={'checkButtonDocumentSigned'}
						indeterminate={
							props.numSelected > 0 && props.numSelected < props.rowCount
						}
						onChange={handleSelectAllClickActive}
						checked={true}
					/>
					<div className={classes.flex}>
						<div className={classes.grupoIcons}>
							<div
								className={classes.iconos}
								onClick={handleClickItem}
								data-title='Ver detalle'
							>
								<img src={info} />
							</div>
						</div>
						<div className={classes.grupoIcons}>
							<div
								className={classes.iconos}
								data-title='Descargar'
								onClick={e => handleClickDownload(e)}
							>
								<img src={download} />
							</div>
							<div
								className={classes.iconos}
								data-title='Eliminar'
								onClick={handleDelete}
							>
								<img src={eliminar} />
							</div>
						</div>
					</div>
				</>
			)}
			{numSelected > 1 && (
				<>
					<StyledCheckboxes
						className={'checkButtonDocumentSigned'}
						indeterminate={
							props.numSelected > 0 && props.numSelected < props.rowCount
						}
						onChange={handleSelectAllClickActive}
						checked={true}
					/>
					<div className={classes.flex}> 
						<div className={classes.grupoIcons}>
							<div
								className={classes.iconos}
								data-title='Descargar'
								onClick={e => handleClickDownload(e)}
							>
								<img src={download} />
							</div>
							<div
								className={classes.iconos}
								data-title='Eliminar'
								onClick={handleDelete}>
								<img src={eliminar} />
							</div>
						</div>
					</div>
				</>
			)}
		</Toolbar>
	);
};

EnhancedTableToolbarActive.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

const EnhancedTableToolbarDelete = props => {
	const dispatch = useDispatch();
	const classes = useToolbarStyles();
	const { enqueueSnackbar } = useSnackbar();
	const {
		numSelected,
		handleSelectAllClickDeleted,
		data,
		selected,
		setSelected,
	} = props;
	const [loading, setLoading] = useState(false);

	const handleDelete = () => {
		// Mostrar modal de eliminar
		dispatch(modalDelete(true));
		dispatch(deleteClickDer(selected));
	};

	const handleClickDownload = async e => {
		setLoading(true);
		const listSelect = [];
		selected.forEach(valor => {
			listSelect.push(data.find(n => n.idCircuito === valor));
		});
		const files = listSelect.map(el => el.documentos).flat();
		dispatch(downloadFromS3CF(files, enqueueSnackbar));
		setSelected([]);
		setLoading(false);
	};
	const his = useHistory();
	const handleClickItem = async e => {
		const listSelect = [];
		selected.forEach(valor => {
			listSelect.push(data.find(n => n.idCircuito === valor));
		});
		let dx = listSelect[0].cifrado;
		his.push(`/home/DetalleCircuito/${dx}`);
	};

	if (loading) return <Loading />;

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0,
			})}
		>
			{numSelected === 1 && (
				<>
					<StyledCheckboxes
						className={'checkButtonDocumentSigned'}
						indeterminate={
							props.numSelected > 0 && props.numSelected < props.rowCount
						}
						onChange={handleSelectAllClickDeleted}
						checked={true}
					/>
					<div className={classes.flex}>
						<div className={classes.grupoIcons}>
							<div
								className={classes.iconos}
								onClick={handleClickItem}
								data-title='Ver detalle'
							>
								<img src={info} />
							</div>
						</div>
						<div className={classes.grupoIcons}>
							<div
								className={classes.iconos}
								data-title='Descargar'
								onClick={e => handleClickDownload(e)}
							>
								<img src={download} />
							</div>
							<div
								className={classes.iconos}
								data-title='Eliminar'
								onClick={handleDelete}
							>
								<img src={eliminar} />
							</div>
						</div>
					</div>
				</>
			)}
			{numSelected > 1 && (
				<>
					<StyledCheckboxes
						className={'checkButtonDocumentSigned'}
						indeterminate={
							props.numSelected > 0 && props.numSelected < props.rowCount
						}
						onChange={handleSelectAllClickDeleted}
						checked={true}
					/>
					<div className={classes.flex}>
						<div className={classes.grupoIcons}>
							<div
								className={classes.iconos}
								data-title='Descargar'
								onClick={e => handleClickDownload(e)}
							>
								<img src={download} />
							</div>
							<div
								className={classes.iconos}
								data-title='Eliminar'
								onClick={handleDelete}
							>
								<img src={eliminar} />
							</div>
						</div>
					</div>
				</>
			)}
		</Toolbar>
	);
};

const EnhancedTableToolbarExpired = props => {
	const dispatch = useDispatch();
	const classes = useToolbarStyles();
	const { enqueueSnackbar } = useSnackbar();
	const {
		numSelected,
		handleSelectAllClickExpired,
		data,
		selected,
		setSelected,
	} = props;
	const [loading, setLoading] = useState(false);

	const handleDelete = () => {
		// Mostrar modal de eliminar
		dispatch(modalDelete(true));
		dispatch(deleteClickDer(selected));
	};

	const handleClickDownload = async e => {
		setLoading(true);
		const listSelect = [];
		selected.forEach(valor => {
			listSelect.push(data.find(n => n.idCircuito === valor));
		});
		const files = listSelect.map(el => el.documentos).flat();
		dispatch(downloadFromS3CF(files, enqueueSnackbar));
		setSelected([]);
		setLoading(false);
	};
	const his = useHistory();
	const handleClickItem = async e => {
		const listSelect = [];
		selected.forEach(valor => {
			listSelect.push(data.find(n => n.idCircuito === valor));
		});
		let dx = listSelect[0].cifrado;
		his.push(`/home/DetalleCircuito/${dx}`);
	};

	if (loading) return <Loading />;

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0,
			})}
		>
			{numSelected === 1 && (
				<>
					<StyledCheckboxes
						className={'checkButtonDocumentSigned'}
						indeterminate={
							props.numSelected > 0 && props.numSelected < props.rowCount
						}
						onChange={handleSelectAllClickExpired}
						checked={true}
					/>
					<div className={classes.flex}>
						<div className={classes.grupoIcons}>
							<div
								className={classes.iconos}
								onClick={handleClickItem}
								data-title='Ver detalle'
							>
								<img src={info} />
							</div>
						</div>
						<div className={classes.grupoIcons}>
							<div
								className={classes.iconos}
								data-title='Descargar'
								onClick={e => handleClickDownload(e)}
							>
								<img src={download} />
							</div>
							<div
								className={classes.iconos}
								data-title='Eliminar'
								onClick={handleDelete}
							>
								<img src={eliminar} />
							</div>
						</div>
					</div>
				</>
			)}
			{numSelected > 1 && (
				<>
					<StyledCheckboxes
						className={'checkButtonDocumentSigned'}
						indeterminate={
							props.numSelected > 0 && props.numSelected < props.rowCount
						}
						onChange={handleSelectAllClickExpired}
						checked={true}
					/>
					<div className={classes.flex}>
						<div className={classes.grupoIcons}>
							<div
								className={classes.iconos}
								data-title='Descargar'
								onClick={e => handleClickDownload(e)}
							>
								<img src={download} />
							</div>
							<div
								className={classes.iconos}
								data-title='Eliminar'
								onClick={handleDelete}
							>
								<img src={eliminar} />
							</div>
						</div>
					</div>
				</>
			)}
		</Toolbar>
	);
};

EnhancedTableToolbarDelete.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

//------------------------------------------------------------------------------------------------------------------
const CircuitoDeFirmas = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const states = useSelector(state => state);
	const db = states.CircuitoFirmasReducer.db;
	const [todosLosDatos, setTodosLosDatos] = useState([]);
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('documento');
	const [selected, setSelected] = useState([]);
	const [selectedCompleted, setSelectedCompleted] = useState([]);
	const [page, setPage] = React.useState(0);
	const [loading, setLoading] = useState(false);
	const [pageCompleted, setPageCompleted] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [rowsPerPageCompleted, setRowsPerPageCompleted] = React.useState(5);
	const [indexRow, setIndex] = useState(null);
	const [dataFilter, setDataFilter] = useState([]);
	const [data, setData] = useState([]);
	const [showClickDerecho, setShowClickDerecho] = useState(false);
	const [dataCompletados, setDataCompletados] = useState([]);
	const [dataFilterCompleted, setDataFilterCompleted] = useState([]);
	const [isFilterIsNull, setIsFilterIsNull] = useState(false);
	const [value, setValue] = useState(0);
	const [pointsX, setPointsX] = useState(0);
	const [pointsY, setPointsY] = useState(0);
	const [busqueda, setBusqueda] = useState([]);
	const jwt = localStorage.getItem('JWT');
	const URL = API_HOSTNAME_AWS + 'webCircuit/api/Status';
	const [evento, setEvento] = useState();
	const modalDeletes = states.CircuitoFirmasReducer.modalDelete;
	const statusModalAvanzado = states.CircuitoFirmasReducer.modalFirmaAvanzadaCf;
	const [error, setError] = useState(null);
	let api = helpHttp();
	const modalClickDerDropbox = states.CircuitoFirmasReducer.modalDropbox;
	const modalClickDerOneDrive = states.CircuitoFirmasReducer.modalOneDrive;
	const [selecteds, setSelecteds] = useState();
	const [tokenOneDrive, setTokenOneDrive] = useState(null);
	const [statusModalOneDrive, setStatusModalOneDrive] = useState(true);
	//-------------------------------------------------------------------------------------------------------------
	const rechazados = data.filter(item => item.estadoCircuito === 4);
	const expirados = data.filter(item => item.estadoCircuito === 3);
	const [dataRechazados, setDataRechazados] = useState([]);
	const enProceso = data.filter(item => item.estadoCircuito === 1);
	const [dataEnProceso, setDataEnProceso] = useState([]);
	const [pageActive, setPageActive] = React.useState(0);
	const [selectedDeleted, setSelectedDeleted] = useState([]);
	const [selectedActive, setSelectedActive] = useState([]);
	const [selectedExpired, setSelectedExpired] = useState([])
	const [rowsPerPageActive, setRowsPerPageActive] = React.useState(5);
	const [rowsPerPageDeleted, setRowsPerPageDeleted] = React.useState(5);
	const [rowsPerPageExpired, setRowsPerPageExpired] = React.useState(5);
	const [pageDeleted, setPageDeleted] = React.useState(0);
	//-------------------------------------------------------------------------------------------------------------
	
	const [pageExpired, setPageExpired] = useState(0)
	const [dataExpirados, setDataExpirados] = useState([]);

	const userInfo = useSelector(({ auth }) => auth.userInfo);

	useEffect(() => {
		document.body.className = 'bodyMiddle';
		setBusqueda('');
		setLoading(true);
		const losDatos = async () => {
			let raw = {
				circuito: '',
				estado: '',
			};
			let options = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: ` Bearer ${jwt}`,
				},
				body: raw,
				redirect: 'follow',
			};
			await api.post(URL, options).then(res => {
				if (!res.err) {
					dispatch(readAllAction(res.circuito));
					setError(null);
				} else {
					dispatch(noAction());
					setError(null);
				}
				setLoading(false);
			});
		};
		losDatos();
	}, [URL, dispatch, value]);

	useEffect(() => {
		setTodosLosDatos(db);
		setData(db.filter(item => item.estadoCircuito != 5));
		setDataCompletados(db.filter(item => item.estadoCircuito === 2));
		setDataFilter(db.filter(item => item.estadoCircuito != 5));
		setDataFilterCompleted(db.filter(item => item.estadoCircuito === 2));
		setDataRechazados(db.filter(item => item.estadoCircuito === 4));
		setDataEnProceso(db.filter(item => item.estadoCircuito === 1));
		setDataExpirados(db.filter(item => item.estadoCircuito === 3))
	}, [db]);

	useEffect(() => {
		const handleClickDer = () => setShowClickDerecho(false);
		window.addEventListener('click', handleClickDer);
		return () => window.removeEventListener('click', handleClickDer);
	}, []);

	useEffect(() => {
		switch (value) {
			case 4:
				setSelectedExpired([]);
				setPageExpired(0);
				break;
			case 3:
				setSelectedDeleted([]);
				setPageDeleted(0);
				break;
			case 2:
				setSelectedActive([]);
				setPageActive(0);
				break;
			case 1:
				setSelectedCompleted([]);
				setPageCompleted(0);
				break;
			case 0:
				setSelected([]);
				setPage(0);
				break;
			default:
				break;
		}
	}, [value]);

		/**
 * Esta función maneja el cierre del modal de firma avanzada según el tipo especificado.
 * Despacha una acción para actualizar el estado del modal de firma avanzada.
 * @returns {void} No retorna nada, simplemente hace un dispatch a la acción para cerrar el modal.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const onClose = type => {
		dispatch(modalFirmaAvanzadaCf(false));
	};

		/**
 * Esta función maneja el evento de clic derecho en un elemento, mostrando un menú contextual.
 * @param {Event} e - El evento de clic derecho.
 * @param {number} index - El índice del elemento.
 * @returns {void} No retorna nada, pero establece varios estados y desencadena acciones de despacho.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleClickDerecho = (e, index) => {
		e.preventDefault();
		setShowClickDerecho(true);
		setEvento(index);
		setPointsX(e.pageX);
		setPointsY(e.pageY);
		let id = e.currentTarget.id;
		dispatch(infoItem(db.filter(item => item.idCircuito == id)));
	};

		/**
 * Esta función maneja el cambio de valor de un componente controlado.
 * @param {Event} event - El evento que desencadena el cambio de valor.
 * @param {any} newValue - El nuevo valor seleccionado o ingresado.
 * @returns {void} No retorna nada, simplemente actualiza el estado con el nuevo valor.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	/**
 * Esta función maneja la solicitud de ordenación de una tabla por una propiedad específica.
 * @param {Event} event - El evento que desencadena la solicitud de ordenación.
 * @param {string} property - La propiedad por la cual se desea ordenar la tabla.
 * @returns {void} No retorna nada, simplemente actualiza el estado de orden y propiedad.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

		/**
 * Componente funcional que muestra la fecha de firma formateada.
 * @param {Object} props - Las propiedades del componente.
 * @param {string} props.fechaFirma - La fecha de firma en formato de cadena.
 * @returns {JSX.Element} Un elemento Typography que muestra la fecha de firma formateada.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const DateSign = props => {
		const classes = useStyles();
		const { fechaFirma } = props;
		var date = new Date(fechaFirma);
		return (
			<Typography className={classes.fechas}>
				{date.getDate() +
					'/' +
					(date.getMonth() + 1) +
					'/' +
					date.getFullYear()}
			</Typography>
		);
	};

		/**
 * Esta función maneja el evento de selección/deselección de todos los elementos en una tabla.
 * Si se selecciona, establece todos los elementos como seleccionados.
 * Si se deselecciona, limpia la lista de elementos seleccionados.
 * @param {Event} event - El evento de clic en el checkbox de selección de todos.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleSelectAllClick = event => {
		if (event.target.checked) {
			const newSelecteds = data.map(n => n.radicado);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

		/**
 * Esta función maneja el clic en un elemento de una lista seleccionable.
 * Actualiza la lista de elementos seleccionados dependiendo del estado actual y el elemento clicado.
 * @param {Event} event - El evento de clic en el elemento.
 * @param {string} name - El nombre o identificador del elemento clicado.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

		/**
 * Esta función maneja el evento de selección/deselección de todos los elementos en una lista de elementos completados.
 * Si se selecciona, establece todos los elementos como seleccionados.
 * Si se deselecciona, limpia la lista de elementos seleccionados.
 * @param {Event} event - El evento de clic en el checkbox de selección de todos los elementos completados.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleSelectAllClickCompleted = event => {
		if (event.target.checked) {
			const newSelecteds = dataCompletados.map(n => n.radicado);
			setSelectedCompleted(newSelecteds);
			return;
		}
		setSelectedCompleted([]);
	};

	/**
 * Esta función maneja el clic en un elemento de una lista de elementos completados.
 * Actualiza la lista de elementos seleccionados dependiendo del estado actual y el elemento clicado.
 * @param {Event} event - El evento de clic en el elemento.
 * @param {string} name - El nombre o identificador del elemento clicado.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleClickCompleted = (event, name) => {
		const selectedIndex = selectedCompleted.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selectedCompleted, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selectedCompleted.slice(1));
		} else if (selectedIndex === selectedCompleted.length - 1) {
			newSelected = newSelected.concat(selectedCompleted.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selectedCompleted.slice(0, selectedIndex),
				selectedCompleted.slice(selectedIndex + 1)
			);
		}

		setSelectedCompleted(newSelected);
	};

		/**
 * Esta función maneja el clic en un elemento de una lista de elementos expirados.
 * Actualiza la lista de elementos expirados dependiendo del estado actual y el elemento clicado.
 * @param {Event} event - El evento de clic en el elemento.
 * @param {string} name - El nombre o identificador del elemento clicado.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleClickExpired = (event, name) => {
		const selectedIndex = selectedExpired.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selectedExpired, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selectedExpired.slice(1));
		} else if (selectedIndex === selectedExpired.length - 1) {
			newSelected = newSelected.concat(selectedExpired.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selectedExpired.slice(0, selectedIndex),
				selectedExpired.slice(selectedIndex + 1)
			);
		}

		setSelectedExpired(newSelected);
	};

		/**
 * Esta función maneja el cambio de página en la paginación.
 * @param {Event} event - El evento que desencadena el cambio de página.
 * @param {number} newPage - El número de la nueva página seleccionada.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

		/**
 * Esta función maneja el cambio de cantidad de filas por página en la paginación.
 * @param {Event} event - El evento que desencadena el cambio de cantidad de filas por página.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleChangeRowsPerPage = event => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

		/**
 * Esta función maneja el cambio de página en una paginación de elementos completados.
 * @param {Event} event - El evento que desencadena el cambio de página.
 * @param {number} newPage - El número de la nueva página seleccionada.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleChangePageCompleted = (event, newPage) => {
		setPageCompleted(newPage);
	};

		/**
 * Esta función maneja el cambio de cantidad de filas por página en una paginación de elementos completados.
 * @param {Event} event - El evento que desencadena el cambio de cantidad de filas por página.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleChangeRowsPerPageCompleted = event => {
		setRowsPerPageCompleted(parseInt(event.target.value, 10));
		setPageCompleted(0);
	};

	/**
 * Verifica si un elemento está seleccionado en una lista.
 * @param {string} name - El nombre o identificador del elemento a verificar.
 * @returns {boolean} Verdadero si el elemento está seleccionado, falso en caso contrario.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const isSelected = name => selected.indexOf(name) !== -1;

		/**
 * Verifica si un elemento está seleccionado en una lista de elementos completados.
 * @param {string} name - El nombre o identificador del elemento a verificar.
 * @returns {boolean} Verdadero si el elemento está seleccionado, falso en caso contrario.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const isSelectedCompleted = name => selectedCompleted.indexOf(name) !== -1;

/**
 * Esta función maneja el cambio en el campo de búsqueda.
 * Actualiza el estado de búsqueda y filtra las listas según el nuevo término de búsqueda.
 * @param {Event} e - El evento que desencadena el cambio en el campo de búsqueda.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleChangeBuscar = e => {
		setBusqueda(e.target.value);
		filtrar(e.target.value);
		filtrarCompletados(e.target.value);
		filtrarActivos(e.target.value);
		filtrarRechazado(e.target.value);
		filtrarExpirado(e.target.value);
	};

		/**
 * Filtra los elementos completados según un término de búsqueda.
 * @param {string} terminoBusqueda - El término de búsqueda utilizado para filtrar los elementos completados.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const filtrarCompletados = terminoBusqueda => {
		let resultadosBusqueda = dataCompletados.filter(elemento => {
			if (elemento.radicado.toString().trim().includes(terminoBusqueda)) {
				return elemento;
			}
		});
		setDataFilterCompleted(resultadosBusqueda);
	};

		/**
 * Filtra los elementos activos en proceso según un término de búsqueda.
 * @param {string} terminoBusqueda - El término de búsqueda utilizado para filtrar los elementos activos en proceso.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const filtrarActivos = terminoBusqueda => {
		let resultadosBusqueda = enProceso.filter(elemento => {
			if (elemento.radicado.toString().trim().includes(terminoBusqueda)) {
				return elemento;
			}
		});
		setDataEnProceso(resultadosBusqueda);
	};

		/**
 * Filtra los elementos rechazados según un término de búsqueda.
 * @param {string} terminoBusqueda - El término de búsqueda utilizado para filtrar los elementos rechazados.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const filtrarRechazado = terminoBusqueda => {
		let resultadosBusqueda = rechazados.filter(elemento => {
			if (elemento.radicado.toString().trim().includes(terminoBusqueda)) {
				return elemento;
			}
		});
		setDataRechazados(resultadosBusqueda);
	};

			/**
 * Filtra los elementos expirados según un término de búsqueda.
 * @param {string} terminoBusqueda - El término de búsqueda utilizado para filtrar los elementos expirados.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const filtrarExpirado = terminoBusqueda => {
		let resultadosBusqueda = expirados.filter(elemento => {
			if (elemento.radicado.toString().trim().includes(terminoBusqueda)) {
				return elemento;
			}
		});
		setDataExpirados(resultadosBusqueda);
	};

		/**
 * Filtra los elementos según un término de búsqueda.
 * @param {string} terminoBusqueda - El término de búsqueda utilizado para filtrar los elementos.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const filtrar = terminoBusqueda => {
		let resultadosBusqueda = data.filter(elemento => {
			if (elemento.radicado.toString().trim().includes(terminoBusqueda))
				return elemento;
		});
		setDataFilter(resultadosBusqueda);
	};

		/**
 * Corta un nombre si excede los 35 caracteres y muestra un tooltip con el nombre completo.
 * @param {string} name - El nombre a cortar si excede los 35 caracteres.
 * @returns {JSX.Element} Un elemento JSX que muestra el nombre cortado con un tooltip si excede los 35 caracteres.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const cutName = name =>
		name.length > 35 ? (
			<Tooltip
				title={name}
				style={{
					display: 'inline',
					color: '#000000',
					font: 'normal normal normal 12px/15px Muli;',
				}}
			>
				<div>{name.substring(0, 35).concat('...')}</div>
			</Tooltip>
		) : (
			<div
				style={{
					display: 'inline',
					color: '#000000',
					font: 'normal normal normal 12px/15px Muli;',
				}}
			>
				{name}
			</div>
		);

	//------------------------------------------------------------------------------------------------------------------------------------------------------------------
		/**
 * Verifica si un elemento está seleccionado en una lista de elementos activos.
 * @param {string} name - El nombre o identificador del elemento a verificar.
 * @returns {boolean} Verdadero si el elemento está seleccionado, falso en caso contrario.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const isSelectedActive = name => selectedActive.indexOf(name) !== -1;
	
	/**
 * Verifica si un elemento está seleccionado en una lista de elementos eliminados.
 * @param {string} name - El nombre o identificador del elemento a verificar.
 * @returns {boolean} Verdadero si el elemento está seleccionado, falso en caso contrario.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const isSelectedDeleted = name => selectedDeleted.indexOf(name) !== -1;
	
	/**
 * Verifica si un elemento está seleccionado en una lista de elementos expirados.
 * @param {string} name - El nombre o identificador del elemento a verificar.
 * @returns {boolean} Verdadero si el elemento está seleccionado, falso en caso contrario.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const isSelectedExpired = name => selectedExpired.indexOf(name) !== -1;

		/**
 * Esta función maneja el evento de selección/deselección de todos los elementos en una lista de elementos eliminados.
 * Si se selecciona, establece todos los elementos como seleccionados.
 * Si se deselecciona, limpia la lista de elementos seleccionados.
 * @param {Event} event - El evento de clic en el checkbox de selección de todos los elementos eliminados.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleSelectAllClickDeleted = event => {
		if (event.target.checked) {
			const newSelecteds = dataRechazados.map(n => n.radicado);
			setSelectedDeleted(newSelecteds);
			return;
		}
		setSelectedDeleted([]);
	};

		/**
 * Esta función maneja el evento de selección/deselección de todos los elementos en una lista de elementos expirados.
 * Si se selecciona, establece todos los elementos como seleccionados.
 * Si se deselecciona, limpia la lista de elementos seleccionados.
 * @param {Event} event - El evento de clic en el checkbox de selección de todos los elementos expirados.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleSelectAllClickExpired = event => {
		if (event.target.checked) {
			const newSelecteds = dataExpirados.map(n => n.radicado);
			setSelectedExpired(newSelecteds);
			return;
		}
		setSelectedExpired([]);
	};

		/**
 * Esta función maneja el evento de selección/deselección de todos los elementos en una lista de elementos activos en proceso.
 * Si se selecciona, establece todos los elementos como seleccionados.
 * Si se deselecciona, limpia la lista de elementos seleccionados.
 * @param {Event} event - El evento de clic en el checkbox de selección de todos los elementos activos en proceso.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleSelectAllClickActive = event => {
		if (event.target.checked) {
			const newSelecteds = dataEnProceso.map(n => n.radicado);
			setSelectedActive(newSelecteds);
			return;
		}
		setSelectedActive([]);
	};

		/**
 * Esta función maneja el clic en un elemento de una lista de elementos eliminados.
 * Actualiza la lista de elementos seleccionados dependiendo del estado actual y el elemento clicado.
 * @param {Event} event - El evento de clic en el elemento.
 * @param {string} name - El nombre o identificador del elemento clicado.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleClickDeleted = (event, name) => {
		const selectedIndex = selectedDeleted.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selectedDeleted, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selectedDeleted.slice(1));
		} else if (selectedIndex === selectedDeleted.length - 1) {
			newSelected = newSelected.concat(selectedDeleted.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selectedDeleted.slice(0, selectedIndex),
				selectedDeleted.slice(selectedIndex + 1)
			);
		}

		setSelectedDeleted(newSelected);
	};

	/**
 * Esta función maneja el cambio de cantidad de filas por página en una paginación de elementos eliminados.
 * @param {Event} event - El evento que desencadena el cambio de cantidad de filas por página.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleChangeRowsPerPageDeleted = event => {
		setRowsPerPageDeleted(parseInt(event.target.value, 10));
		setPageDeleted(0);
	};

		/**
 * Esta función maneja el cambio de cantidad de filas por página en una paginación de elementos expirados.
 * @param {Event} event - El evento que desencadena el cambio de cantidad de filas por página.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleChangeRowsPerPageExpired = event => {
		setRowsPerPageExpired(parseInt(event.target.value, 10));
		setPageExpired(0);
	};

		/**
 * Esta función maneja el cambio de cantidad de filas por página en una paginación de elementos activos en proceso.
 * @param {Event} event - El evento que desencadena el cambio de cantidad de filas por página.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleChangeRowsPerPageActive = event => {
		setRowsPerPageActive(parseInt(event.target.value, 10));
		setPageActive(0);
	};

		/**
 * Esta función maneja el clic en un elemento de una lista de elementos activos en proceso.
 * Actualiza la lista de elementos seleccionados dependiendo del estado actual y el elemento clicado.
 * @param {Event} event - El evento de clic en el elemento.
 * @param {string} name - El nombre o identificador del elemento clicado.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleClickActive = (event, name) => {
		const selectedIndex = selectedActive.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selectedActive, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selectedActive.slice(1));
		} else if (selectedIndex === selectedActive.length - 1) {
			newSelected = newSelected.concat(selectedActive.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selectedActive.slice(0, selectedIndex),
				selectedActive.slice(selectedIndex + 1)
			);
		}
		setSelectedActive(newSelected);
	};

		/**
 * Esta función maneja el cambio de página en una paginación de elementos activos en proceso.
 * @param {Event} event - El evento que desencadena el cambio de página.
 * @param {number} newPage - El número de la nueva página seleccionada.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleChangePageActive = (event, newPage) => {
		setPageActive(newPage);
	};

		/**
 * Esta función maneja el cambio de página en una paginación de elementos eliminados.
 * @param {Event} event - El evento que desencadena el cambio de página.
 * @param {number} newPage - El número de la nueva página seleccionada.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleChangePageDeleted = (event, newPage) => {
		setPageDeleted(newPage);
	};

		/**
 * Esta función maneja el cambio de página en una paginación de elementos expirados.
 * @param {Event} event - El evento que desencadena el cambio de página.
 * @param {number} newPage - El número de la nueva página seleccionada.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
	const handleChangePageExpired = (event, newPage) => {
		setPageExpired(newPage);
	};
	//---------------------------------------------------------------------------------------------------------------------------------------------------------------------
	return (
		<>
			<Grid container spacing={0} direction='row'>
				<Container component='main' maxWidth='lg'>
					<h1 className={classes.title}>Mis circuitos de firmas</h1>
				</Container>
				<Container component='main'>
					<Paper
						elevation={3}
						className={'mainContentPaperDocuments mainContentPaperHeight'}
					>
						<div className={classes.searchBar}>
							<TextField
								onKeyDown={e =>
									['e', '.', 'E', '+', ',', '-'].includes(e.key) &&
									e.preventDefault()
								}
								type='number'
								className={'fieldSearch'}
								value={busqueda}
								id='txtBuscar'
								onChange={handleChangeBuscar}
								style={{ marginLeft: '7px', width: '64%' }}
							></TextField>
						</div>
						<Tabs
							value={value}
							onChange={handleChange}
							indicatorColor='primary'
							textColor='primary'
							className={classes.tabs}
						>
							<Tab className={classes.seleccion} label='Todos' />
							<Tab className={classes.seleccion} label='Completados' />
							<Tab
								className={classes.seleccion}
								label={`En proceso: ${dataEnProceso.length}`}
							/>
							<Tab
								className={classes.seleccion}
								label={`Rechazados: ${dataRechazados.length}`}
							/>
							{userInfo.userDigitalWare&& 
							<Tab
								className={classes.seleccion}
								label={`Expirados: ${dataExpirados.length}`}
							/>}
						</Tabs>
						<hr
							style={{
								padding: 0,
								margin: '-2px 25px 0',
								height: '3px',
								backgroundColor: '#C1C1C1',
							}}
						/>
						<div className={'contentDocumentSignedHome'}>
							{value === 0 && (
								<>
									<EnhancedTableToolbarAll
										handleSelectAllClick={e => handleSelectAllClick(e)}
										numSelected={selected.length}
										selected={selected}
										setSelected={setSelected}
										data={data}
									/>
									<TableContainer>
										<Table
											className={'tableSignedDocumentsCF'}
											aria-labelledby='tableTitle'
											size='medium'
											aria-label='enhanced table'
											cellSpacing='0px'
										>
											<EnhancedTableHead
												classes={classes}
												numSelected={selected.length}
												order={order}
												orderBy={orderBy}
												onRequestSort={handleRequestSort}
												rowCount={dataFilter.length}
												value={value}
											/>
											{dataFilter.length > 0 ? (
												<TableBody>
													{stableSort(dataFilter, getComparator(order, orderBy))
														.slice(
															page * rowsPerPage,
															page * rowsPerPage + rowsPerPage
														)
														.map((row, index) => {
															const isItemSelected = isSelected(row.idCircuito);
															const labelId = `enhanced-table-checkbox-${index}`;
															return (
																<TableRow
																	hover
																	onClick={event =>
																		handleClick(event, row.idCircuito)
																	}
																	role='checkbox'
																	aria-checked={isItemSelected}
																	tabIndex={-1}
																	id={row.idCircuito}
																	key={row.radicado}
																	selected={isItemSelected}
																	style={{ cursor: 'pointer' }}
																	className={`${classes.fila} ${
																		showClickDerecho && evento === index
																			? 'borderActive'
																			: ' '
																	}`}
																	onMouseEnter={() => setIndex(index)}
																	onMouseLeave={() => setIndex(null)}
																	onContextMenu={e => {
																		handleClickDerecho(e, index);
																	}}
																>
																	<TableCell padding='none'>
																		{isFilterIsNull ? null : (
																			<StyledCheckbox
																				checked={isItemSelected}
																				className={'checkButtonDocumentSigned'}
																				inputProps={{
																					'aria-labelledby': labelId,
																				}}
																			/>
																		)}
																	</TableCell>
																	<TableCell
																		align='left'
																		component='th'
																		id={labelId}
																		scope='row'
																		padding='none'
																	>
																		{row.radicado}
																	</TableCell>
																	{row.documentos.length > 1 ? (
																		<TableCell
																			align='left'
																			padding='none'
																			// style={{ whiteSpace:"nowrap", }}
																			// style={{ whiteSpace:"nowrap", }}
																		>
																			<img
																				src={carpeta}
																				style={{
																					marginRight: '8px',
																					padding: '0',
																				}}
																			/>
																			{cutName(row.carpeta)}
																		</TableCell>
																	) : (
																		<TableCell align='left' padding='none'>
																			<p
																				style={{
																					margin: '0 0 0 25px',
																					padding: '0',
																					textOverflow: 'ellipsis',
																					width: '98%',
																				}}
																			>
																				{cutName(row.documentos[0].nombre)}
																			</p>
																		</TableCell>
																	)}
																	<TableCell align='left' padding='none'>
																		<DateSign
																			fechaFirma={row.fechaCreacion}
																			index={index}
																			indexRow={indexRow}
																		/>
																	</TableCell>
																	<TableCell align='left' padding='none'>
																		<DateSign
																			fechaFirma={row.fechaModificacion}
																			index={index}
																			indexRow={indexRow}
																		/>
																	</TableCell>
																	{row.estadoCircuito === 2 && (
																		<TableCell align='center' padding='none'>
																			<div
																				style={{
																					border: '1px solid',
																					margin: '0',
																					borderRadius: '10px',
																					backgroundColor: '#058D78',
																					color: 'white',
																					fontSize: '11px',
																					padding: '2px 0',
																					width: '80px',
																					margin: 'auto',
																				}}
																			>
																				Completado
																			</div>
																		</TableCell>
																	)}
																	{row.estadoCircuito === 4 && (
																		<TableCell align='center' padding='none'>
																			<div
																				style={{
																					border: '1px solid',
																					margin: '0',
																					borderRadius: '10px',
																					backgroundColor: '#DD0640',
																					color: 'white',
																					fontSize: '11px',
																					padding: '2px 0',
																					width: '80px',
																					margin: 'auto',
																				}}
																			>
																				Rechazado
																			</div>
																		</TableCell>
																	)}
																	{row.estadoCircuito === 1 && (
																		<TableCell align='left' padding='none'>
																			<div
																				style={{
																					border: '1px solid',
																					margin: '0',
																					borderRadius: '10px',
																					backgroundColor: '#8D8D8D',
																					fontSize: '11px',
																					padding: '2px 0',
																					width: '80px',
																					margin: 'auto',
																					color: '#FFF',
																					textAlign: 'center',
																					fontFamily: 'Muli',
																					fontHeight: '700',
																					lineHeight: '15.6px ',
																				}}
																			>
																				En proceso
																			</div>
																		</TableCell>
																	)}
																	{row.estadoCircuito === 3 && (
																		<TableCell align='left' padding='none' >
																			<div
																				style={{
																					border: '1px solid',
																					margin: '0',
																					borderRadius: '10px',
																					backgroundColor: '#1199C8',
																					fontSize: '11px',
																					padding: '2px 0',
																					width: '80px',
																					margin: 'auto',
																					color: '#FFF',
																					textAlign: 'center',
																					fontFamily: 'Muli',
																					fontHeight: '700',
																					lineHeight: '15.6px ',
																				}}
																			>
																				Expirado
																			</div>
																		</TableCell>
																	)}
																</TableRow>
															);
														})}
												</TableBody>
											) : (
												<TableBody>
													<TableCell />
													<TableCell />
													<TableCell>No se encontraron circuitos</TableCell>
													<TableCell />
													<TableCell />
													<TableCell />
												</TableBody>
											)}
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[5, 10, 25]}
										component='div'
										count={dataFilter.length}
										rowsPerPage={rowsPerPage}
										page={page}
										labelRowsPerPage='Filas por página'
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
									{showClickDerecho && (
										<MenuContextual x={pointsX} y={pointsY} todos={true} />
									)}
									{modalDeletes && (
										<ModalDelete
											numSelected={selected.length}
											selected={selected}
											setSelected={setSelected}
											setLoading={setLoading}
										/>
									)}
									{statusModalAvanzado && <ModalFirmaAvanzadaCF />}
								</>
							)}
							{value === 1 && (
								<>
									<EnhancedTableToolbar
										handleSelectAllClickCompleted={e =>
											handleSelectAllClickCompleted(e)
										}
										numSelected={selectedCompleted.length}
										selected={selectedCompleted}
										setSelected={setSelectedCompleted}
										data={dataCompletados}
									/>
									<TableContainer>
										<Table
											className={'tableSignedDocumentsCF'}
											aria-labelledby='tableTitle'
											size='medium'
											aria-label='enhanced table'
											cellSpacing='0px'
										>
											<EnhancedTableHead
												classes={classes}
												numSelected={selectedCompleted.length}
												order={order}
												orderBy={orderBy}
												onRequestSort={handleRequestSort}
												rowCount={dataFilterCompleted.length}
												value={value}
											/>
											{dataFilterCompleted.length > 0 ? (
												<TableBody>
													{stableSort(
														dataFilterCompleted,
														getComparator(order, orderBy)
													)
														.slice(
															pageCompleted * rowsPerPageCompleted,
															pageCompleted * rowsPerPageCompleted +
																rowsPerPageCompleted
														)
														.map((row, index) => {
															const isItemSelected = isSelectedCompleted(
																row.idCircuito
															);
															const labelId = `enhanced-table-checkbox-${index}`;

															return (
																<TableRow
																	hover
																	onClick={event =>
																		handleClickCompleted(event, row.idCircuito)
																	}
																	role='checkbox'
																	aria-checked={isItemSelected}
																	tabIndex={-1}
																	id={row.idCircuito}
																	key={row.radicado}
																	selected={isItemSelected}
																	style={{ cursor: 'pointer' }}
																	className={`${classes.fila} ${
																		showClickDerecho && evento === index
																			? 'borderActive'
																			: ' '
																	}`}
																	onMouseEnter={() => setIndex(index)}
																	onMouseLeave={() => setIndex(null)}
																	onContextMenu={e => {
																		handleClickDerecho(e, index);
																	}}
																>
																	<TableCell padding='none'>
																		{isFilterIsNull ? null : (
																			<StyledCheckbox
																				checked={isItemSelected}
																				className={'checkButtonDocumentSigned'}
																				inputProps={{
																					'aria-labelledby': labelId,
																				}}
																			/>
																		)}
																	</TableCell>
																	<TableCell
																		align='left'
																		component='th'
																		id={labelId}
																		scope='row'
																		padding='none'
																	>
																		{row.radicado}
																	</TableCell>
																	{row.documentos.length > 1 ? (
																		<TableCell align='left' padding='none'>
																			{' '}
																			<img
																				src={carpeta}
																				style={{
																					marginRight: '8px',
																					padding: '0',
																				}}
																			/>
																			{row.carpeta}
																		</TableCell>
																	) : (
																		<TableCell align='left' padding='none'>
																			<p
																				style={{
																					margin: '0 0 0 25px',
																					padding: '0',
																				}}
																			>
																				{row.documentos[0].nombre}
																			</p>
																		</TableCell>
																	)}
																	<TableCell align='left' padding='none'>
																		<DateSign
																			fechaFirma={row.fechaCreacion}
																			index={index}
																			indexRow={indexRow}
																		/>
																	</TableCell>
																	<TableCell align='left' padding='none'>
																		<DateSign
																			fechaFirma={row.fechaModificacion}
																			index={index}
																			indexRow={indexRow}
																		/>
																	</TableCell>
																</TableRow>
															);
														})}
												</TableBody>
											) : (
												<TableBody>
													<TableCell />
													<TableCell />
													<TableCell>No se encontraron circuitos</TableCell>
													<TableCell />
													<TableCell />
													<TableCell />
												</TableBody>
											)}
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[5, 10, 25]}
										component='div'
										count={dataFilterCompleted.length}
										rowsPerPage={rowsPerPageCompleted}
										page={pageCompleted}
										labelRowsPerPage='Filas por página'
										onPageChange={handleChangePageCompleted}
										onRowsPerPageChange={handleChangeRowsPerPageCompleted}
									/>
									{showClickDerecho && (
										<MenuContextual x={pointsX} y={pointsY} todos={false} />
									)}
									{statusModalAvanzado && (
										<ModalFirmaAvanzadaCF
											isOpen={statusModalAvanzado}
											onClose={onClose}
											files={[]}
											filesSize={0}
											style={'style'}
											rows={dataFilterCompleted}
											selected={selectedCompleted}
											setFiles={() => {}}
										/>
									)}
									{modalDeletes && (
										<ModalDelete
											setSelected={setSelectedCompleted}
											setLoading={setLoading}
										/>
									)}
								</>
							)}
							{value === 2 && (
								<>
									<EnhancedTableToolbarActive
										handleSelectAllClickActive={e =>
											handleSelectAllClickActive(e)
										}
										numSelected={selectedActive.length}
										selected={selectedActive}
										setSelected={setSelectedActive}
										data={dataEnProceso}
									/>

									<TableContainer>
										<Table
											className={'tableSignedDocumentsCF'}
											aria-labelledby='tableTitle'
											size='medium'
											aria-label='enhanced table'
											cellSpacing='0px'
										>
											<EnhancedTableHead
												classes={classes}
												numSelected={selectedActive.length}
												order={order}
												orderBy={orderBy}
												onRequestSort={handleRequestSort}
												rowCount={dataEnProceso.length}
												value={value}
											/>
											{dataEnProceso.length > 0 ? (
												<TableBody>
													{stableSort(
														dataEnProceso,
														getComparator(order, orderBy)
													)
														.slice(
															pageActive * rowsPerPageActive,
															pageActive * rowsPerPageActive + rowsPerPageActive
														)
														.map((row, index) => {
															const isItemSelected = isSelectedActive(
																row.idCircuito
															);
															const labelId = `enhanced-table-checkbox-${index}`;

															return (
																<TableRow
																	hover
																	onClick={event =>
																		handleClickActive(event, row.idCircuito)
																	}
																	style={{
																		cursor: 'pointer',
																		color: '#000000',
																	}}
																	role='checkbox'
																	aria-checked={isItemSelected}
																	tabIndex={-1}
																	id={row.idCircuito}
																	key={row.radicado}
																	selected={isItemSelected}
																	className={`${classes.fila} ${
																		showClickDerecho && evento === index
																			? 'borderActive'
																			: ' '
																	}`}
																	onMouseEnter={() => setIndex(index)}
																	onMouseLeave={() => setIndex(null)}
																	onContextMenu={e => {
																		handleClickDerecho(e, index);
																	}}
																>
																	<TableCell padding='none'>
																		{isFilterIsNull ? null : (
																			<StyledCheckbox
																				checked={isItemSelected}
																				className={'checkButtonDocumentSigned'}
																				inputProps={{
																					'aria-labelledby': labelId,
																				}}
																			/>
																		)}
																	</TableCell>
																	<TableCell
																		align='left'
																		component='th'
																		id={labelId}
																		scope='row'
																		padding='none'
																	>
																		{row.radicado}
																	</TableCell>
																	{row.documentos.length > 1 ? (
																		<TableCell
																			align='left'
																			padding='none'
																			// style={{ whiteSpace:"nowrap", }}
																		>
																			<img
																				src={carpeta}
																				style={{
																					marginRight: '8px',
																					padding: '0',
																				}}
																			/>
																			{cutName(row.carpeta)}
																		</TableCell>
																	) : (
																		<TableCell align='left' padding='none'>
																			<p
																				style={{
																					margin: '0 0 0 25px',
																					padding: '0',
																					textOverflow: 'ellipsis',
																					width: '98%',
																				}}
																			>
																				{cutName(row.documentos[0].nombre)}
																			</p>
																		</TableCell>
																	)}
																	<TableCell align='left' padding='none'>
																		<DateSign
																			fechaFirma={row.fechaCreacion}
																			index={index}
																			indexRow={indexRow}
																		/>
																	</TableCell>
																	<TableCell align='left' padding='none'>
																		<DateSign
																			fechaFirma={row.fechaModificacion}
																			index={index}
																			indexRow={indexRow}
																		/>
																	</TableCell>
																	<TableCell align='center' padding='none'>
																		<div
																			style={{
																				marginTop: '10px',
																				padding: '0',
																			}}
																		>
																			<LineaProgreso
																				firmasTotales={row.firmasTotal}
																				firmasRealizadas={row.firmasRealizadas}
																			/>
																		</div>
																	</TableCell>
																</TableRow>
															);
														})}
												</TableBody>
											) : (
												<TableBody>
													<TableCell></TableCell>
													<TableCell />
													<TableCell>No hay Circuitos para mostrar</TableCell>
													<TableCell />
													<TableCell />
													<TableCell />
												</TableBody>
											)}
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[5, 10, 25]}
										component='div'
										count={dataEnProceso.length}
										rowsPerPage={rowsPerPageActive}
										page={pageActive}
										labelRowsPerPage='Filas por página'
										onPageChange={handleChangePageActive}
										onRowsPerPageChange={handleChangeRowsPerPageActive}
									/>
									{showClickDerecho && (
										<MenuContextual deleted={true} x={pointsX} y={pointsY} />
									)}
									{statusModalAvanzado && (
										<ModalFirmaAvanzadaCF
											isOpen={statusModalAvanzado}
											onClose={onClose}
											files={[]}
											filesSize={0}
											style={'style'}
											rows={dataEnProceso}
											selected={selectedActive}
											setFiles={() => {}}
										/>
									)}
									{modalDeletes && (
										<ModalDelete
											setSelected={setSelectedActive}
											setLoading={setLoading}
										/>
									)}
								</>
							)}
							{value === 3 && (
								<>
									<EnhancedTableToolbarDelete
										handleSelectAllClickDeleted={e =>
											handleSelectAllClickDeleted(e)
										}
										numSelected={selectedDeleted.length}
										selected={selectedDeleted}
										setSelected={setSelectedDeleted}
										data={dataRechazados}
										// setStatusModalAvanzado={setStatusModalAvanzado}
									/>

									<TableContainer>
										<Table
											className={'tableSignedDocumentsCF'}
											aria-labelledby='tableTitle'
											size='medium'
											aria-label='enhanced table'
											cellSpacing='0px'
										>
											<EnhancedTableHead
												classes={classes}
												numSelected={selectedDeleted.length}
												order={order}
												orderBy={orderBy}
												onRequestSort={handleRequestSort}
												rowCount={dataRechazados.length}
												value={value}
											/>
											{dataRechazados.length > 0 ? (
												<TableBody>
													{stableSort(
														dataRechazados,
														getComparator(order, orderBy)
													)
														.slice(
															pageDeleted * rowsPerPageDeleted,
															pageDeleted * rowsPerPageDeleted +
																rowsPerPageDeleted
														)
														.map((row, index) => {
															const isItemSelected = isSelectedDeleted(
																row.idCircuito
															);
															const labelId = `enhanced-table-checkbox-${index}`;

															return (
																<TableRow
																	hover
																	onClick={event =>
																		handleClickDeleted(event, row.idCircuito)
																	}
																	role='checkbox'
																	aria-checked={isItemSelected}
																	tabIndex={-1}
																	style={{ cursor: 'pointer' }}
																	id={row.idCircuito}
																	key={row.radicado}
																	selected={isItemSelected}
																	className={`${classes.fila} ${
																		showClickDerecho && evento === index
																			? 'borderActive'
																			: ' '
																	}`}
																	onMouseEnter={() => setIndex(index)}
																	onMouseLeave={() => setIndex(null)}
																	onContextMenu={e => {
																		handleClickDerecho(e, index);
																	}}
																>
																	<TableCell padding='none'>
																		{isFilterIsNull ? null : (
																			<StyledCheckbox
																				checked={isItemSelected}
																				className={'checkButtonDocumentSigned'}
																				inputProps={{
																					'aria-labelledby': labelId,
																				}}
																			/>
																		)}
																	</TableCell>
																	<TableCell
																		align='left'
																		component='th'
																		id={labelId}
																		scope='row'
																		padding='none'
																	>
																		{row.radicado}
																	</TableCell>
																	{row.documentos.length > 1 ? (
																		<TableCell
																			align='left'
																			padding='none'
																			// style={{ whiteSpace:"nowrap", }}
																		>
																			<img
																				src={carpeta}
																				style={{
																					marginRight: '8px',
																					padding: '0',
																				}}
																			/>
																			{cutName(row.carpeta)}
																		</TableCell>
																	) : (
																		<TableCell align='left' padding='none'>
																			<p
																				style={{
																					margin: '0 0 0 25px',
																					padding: '0',
																					textOverflow: 'ellipsis',
																					width: '98%',
																				}}
																			>
																				{cutName(row.documentos[0].nombre)}
																			</p>
																		</TableCell>
																	)}
																	<TableCell align='left' padding='none'>
																		<DateSign
																			fechaFirma={row.fechaCreacion}
																			index={index}
																			indexRow={indexRow}
																		/>
																	</TableCell>
																	<TableCell align='left' padding='none'>
																		<DateSign
																			fechaFirma={row.fechaModificacion}
																			index={index}
																			indexRow={indexRow}
																		/>
																	</TableCell>
																</TableRow>
															);
														})}
												</TableBody>
											) : (
												<TableBody>
													<TableCell></TableCell>
													<TableCell />
													<TableCell>No hay Circuitos para mostrar</TableCell>
													<TableCell />
													<TableCell />
													<TableCell />
												</TableBody>
											)}
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[5, 10, 25]}
										component='div'
										count={dataRechazados.length}
										rowsPerPage={rowsPerPageDeleted}
										page={pageDeleted}
										labelRowsPerPage='Filas por página'
										onPageChange={handleChangePageDeleted}
										onRowsPerPageChange={handleChangeRowsPerPageDeleted}
									/>
									{showClickDerecho && (
										<MenuContextual deleted={true} x={pointsX} y={pointsY} />
									)}
									{statusModalAvanzado && (
										<ModalFirmaAvanzadaCF
											isOpen={statusModalAvanzado}
											onClose={onClose}
											files={[]}
											filesSize={0}
											style={'style'}
											rows={dataRechazados}
											selected={selectedDeleted}
											setFiles={() => {}}
										/>
									)}
									{modalDeletes && (
										<ModalDelete
											setSelected={setSelectedDeleted}
											setLoading={setLoading}
										/>
									)}
								</>
							)}
							{ userInfo.userDigitalWare && value === 4 && (
								<>
									<EnhancedTableToolbarExpired
									handleSelectAllClickExpired={e =>
										handleSelectAllClickExpired(e)
									}
										handleClickExpired={e =>
											handleClickExpired(e)
										}
										numSelected={selectedExpired.length}
										selected={selectedExpired}
										setSelected={setSelectedExpired}
										data={dataExpirados}
										// setStatusModalAvanzado={setStatusModalAvanzado}
									/>

									<TableContainer>
										<Table
											className={'tableSignedDocumentsCF'}
											aria-labelledby='tableTitle'
											size='medium'
											aria-label='enhanced table'
											cellSpacing='0px'
										>
											<EnhancedTableHead
												classes={classes}
												numSelected={selectedExpired.length}
												order={order}
												orderBy={orderBy}
												onRequestSort={handleRequestSort}
												rowCount={dataExpirados.length}
												value={value}
											/>
											{dataExpirados.length > 0 ? (
												<TableBody>
													{stableSort(
														dataExpirados,
														getComparator(order, orderBy)
													)
														.slice(
															pageExpired * rowsPerPageExpired,
															pageExpired * rowsPerPageExpired +
																rowsPerPageExpired
														)
														.map((row, index) => {
															const isItemSelected = isSelectedExpired(
																row.idCircuito
															);
															const labelId = `enhanced-table-checkbox-${index}`;

															return (
																<TableRow
																	hover
																	onClick={event =>
																		handleClickExpired(event, row.idCircuito)
																	}
																	role='checkbox'
																	aria-checked={isItemSelected}
																	tabIndex={-1}
																	style={{ cursor: 'pointer' }}
																	id={row.idCircuito}
																	key={row.radicado}
																	selected={isItemSelected}
																	className={`${classes.fila} ${
																		showClickDerecho && evento === index
																			? 'borderActive'
																			: ' '
																	}`}
																	onMouseEnter={() => setIndex(index)}
																	onMouseLeave={() => setIndex(null)}
																	onContextMenu={e => {
																		handleClickDerecho(e, index);
																	}}
																>
																	<TableCell padding='none'>
																		{isFilterIsNull ? null : (
																			<StyledCheckbox
																				checked={isItemSelected}
																				className={'checkButtonDocumentSigned'}
																				inputProps={{
																					'aria-labelledby': labelId,
																				}}
																			/>
																		)}
																	</TableCell>
																	<TableCell
																		align='left'
																		component='th'
																		id={labelId}
																		scope='row'
																		padding='none'
																	>
																		{row.radicado}
																	</TableCell>
																	{row.documentos.length > 1 ? (
																		<TableCell
																			align='left'
																			padding='none'
																			// style={{ whiteSpace:"nowrap", }}
																		>
																			<img
																				src={carpeta}
																				style={{
																					marginRight: '8px',
																					padding: '0',
																				}}
																			/>
																			{cutName(row.carpeta)}
																		</TableCell>
																	) : (
																		<TableCell align='left' padding='none'>
																			<p
																				style={{
																					margin: '0 0 0 25px',
																					padding: '0',
																					textOverflow: 'ellipsis',
																					width: '98%',
																				}}
																			>
																				{cutName(row.documentos[0].nombre)}
																			</p>
																		</TableCell>
																	)}
																	<TableCell align='left' padding='none'>
																		<DateSign
																			fechaFirma={row.fechaCreacion}
																			index={index}
																			indexRow={indexRow}
																		/>
																	</TableCell>
																	<TableCell align='left' padding='none'>
																		<DateSign
																			fechaFirma={row.fechaModificacion}
																			index={index}
																			indexRow={indexRow}
																		/>
																	</TableCell>
																</TableRow>
															);
														})}
												</TableBody>
											) : (
												<TableBody>
													<TableCell></TableCell>
													<TableCell />
													<TableCell>No hay Circuitos para mostrar</TableCell>
													<TableCell />
													<TableCell />
													<TableCell />
												</TableBody>
											)}
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[5, 10, 25]}
										component='div'
										count={dataExpirados.length}
										rowsPerPage={rowsPerPageDeleted}
										page={pageDeleted}
										labelRowsPerPage='Filas por página'
										onPageChange={handleChangePageExpired}
										onRowsPerPageChange={handleChangeRowsPerPageExpired}
									/>
									{showClickDerecho && (
										<MenuContextual deleted={true} x={pointsX} y={pointsY} />
									)}
									{statusModalAvanzado && (
										<ModalFirmaAvanzadaCF
											isOpen={statusModalAvanzado}
											onClose={onClose}
											files={[]}
											filesSize={0}
											style={'style'}
											rows={dataExpirados}
											selected={selectedDeleted}
											setFiles={() => {}}
										/>
									)}
									{modalDeletes && (
										<ModalDelete
											setSelected={setSelectedExpired}
											setLoading={setLoading}
										/>
									)}
								</>
							)}
							{modalClickDerDropbox && <ConfirmDropboxCF />}
							{modalClickDerOneDrive && (
								<Modal
									open={true}
									disableBackdropClick
									onClose={() => {
										setStatusModalOneDrive(0);
									}}
									className={classes.modalpopUpshare}
								>
									<OneDriveCF
										tokenOneDrive={tokenOneDrive}
										setTokenOneDrive={setTokenOneDrive}
										setStatusModalOneDrive={setStatusModalOneDrive}
										setSelected={setSelecteds}
									/>
								</Modal>
							)}
							{loading && <Loading />}
						</div>
					</Paper>
				</Container>
			</Grid>
		</>
	);
};
export default CircuitoDeFirmas;
