export const validateEmail = email => {

	const blockedEmails = [
		"yopmail.com",
		"10minutemail.com",
		"temp-mail.org",
		"guerrillamail.com",
		"mailinator.com",
		"emailondeck.com",
		"moakt.com",
		"throwawaymail.com",
		"mytemp.email",
		"getnada.com",
		"tempinbox.com",
		"emailtemp.org",
		"maildrop.cc",
		"fakemail.net",
		"emailfake.com",
		"dispostable.com",
		"trashmail.com",
		"tempail.com",
		"owlymail.com",
		"mailcatch.com",
		"fakemailgenerator.com",
		"spambog.com",
		"mail.tm",
		"anonaddy.com",
		"protonmail.com",
		"minutemailbox.com",
		"harakirimail.com",
		"luxusmail.com",
		"spambox.xyz",
		"burnermail.io",
		"mailsac.com",
		"tempmailaddress.com",
		"mailnesia.com",
		"iea.so",
		"spamgourmet.com",
		"throwawayemail.com",
		"anonbox.net",
		"e4ward.com",
		"easytrashmail.com",
		"sharklasers.com",
		"tempmailo.com",
		"nospamtoday.net",
		"mailnull.com",
		"spamb.ee",
		"mohmal.com",
		"tmail.ws",
		"spamfree24.org",
		"dropmail.me",
		"adath.info",
		"mailhog.com",
		"tempinbox.org",
		"byom.de",
		"33mail.com",
		"jetable.org",
		"meltmail.com",
		"gishpuppy.com",
		"mailnesia.com",
		"spamdecoy.net",
		"fakemail.io",
		"anonymousemail.me",
		"tempr.email",
	];

	const allowedEmails = [
		'mio@yopmail.com',
		'pruebastodosqa@yopmail.com',
		'lizeth.delgadillo@yopmail.com',
		"antoniolopez01@yopmail.com",
				"karen666@yopmail.com",
				"juansanchez01@yopmail.com",
				"radafalca09@yopmail.com",
				"pruebastodosqa@yopmail.com",
				"juanitoperezqa@yopmail.com",
				"digitalware@yopmail.com",
				"hugolopez001@yopmail.com",
				"user1@yopmail.com",
				"user2@yopmail.com",
				"user3@yopmail.com",
				"user4@yopmail.com",
				"user5@yopmail.com",
				"user6@yopmail.com",
				"user7@yopmail.com",
				"user8@yopmail.com",
				"user9@yopmail.com",
				"user10@yopmail.com"
	];

	// const re = /^[a-zA-ZñÑ0-9._%+-]+@[a-zA-ZñÑ0-9.-]+\.[a-zA-ZñÑ]{2,}$/;
	const re = new RegExp("^[\\w!#$%&'*+/=?`{|}~^-]+(?:\\.[\\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$");
	if (!email) {
		return false;
	}

	const parts = email.split('@');
	if (parts.length !== 2) {
		return false;
	}
	const [user, domain] = parts;
	if (user.length > 64 || domain.length > 255) {
		return false;
	}
	if (!re.test(email)) {
		return false;
	}
	if (allowedEmails.includes(email)) {
		return true;
	}
	if (blockedEmails.includes(domain)) {
		return false;
	}
	return true;
};

export const validateName = name => {
	const Name = name.trim();
	const caracteresValidos = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ, ]+$/;
	if (Name.length >= 3 && caracteresValidos.test(Name)) {
		return true;
	}
};
