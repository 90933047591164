import React, { useEffect, useState } from "react";
import MessagePantallaCarga from "./modales/MessagePantallaCarga";
import Loading from "../../../components/Loading";
import { useHistory, useParams } from "react-router-dom";
import SignerFromMail from "./SignerFromMail";
import {
  API_HOSTNAME_AWS,
  APP_KEY,
  APP_NAME,
  PASSWORD_FIRMAYA_GENERICO,
  URL_FIRMAYA_LOGIN,
  USUARIO_FIRMAYA_GENERICO,
} from "../../../redux/types/ApiConstants";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { tokenGenerico } from "../../../redux/actions/CircuitoFirmasAction";
import { helpHttp } from "../../../helpers/helpHttp";
import { getFilesCF } from "../../../redux/actions/SignAction";

const PantallaCarga = (props) => {
  const { history, irHome = false } = props;
  const [resultado, setResultado] = useState({});
  const [loading, setLoading] = useState(true);
  const { direccion } = useParams();
  const [noUserFirmaYa, setNoUserFirmaYa] = useState();
  const volver = useHistory();
  const [message, setMessage] = useState("");
  const [modalPantallaCarga, setModalPantallaCarga] = useState(false);
  const [checks, setChecks] = useState(false);
  const [circuitoEliminado, setCircuitoEliminado] = useState(false);
  const dispatch = useDispatch();
  const [personaEliminada, setPersonaEliminada] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const CryptoJS = require("crypto-js");
  const key = APP_KEY;
  const cifrado = direccion;
  const procesar = cifrado.replace(/[-]/g, "+").replace(/[_]/g, "/");
  let decifrado = CryptoJS.AES.decrypt(procesar, key);
  let salida = decifrado.toString(CryptoJS.enc.Utf8);
  const newIdCircuito = salida.split("&")[0].split("=")[1];
  const newIdFirmante = salida.split("=")[2];
  const style = "style";
  const [size, setSize] = useState(0);
  const [msgBtn, setMsgBtn] = useState();
  const [firmantes, setFirmantes] = useState();
  const [ruta, setRuta] = useState();
  const http = helpHttp();
  const [archivos, setArchivos] = useState([]);

   //When loading for the first time it will execute the cambiarEstado  function and consultar function
   useEffect(() => {
    const enterAndConsult = async () => {
      const data = await login();
      changeState(data).finally(checkCircuitStatus(data));
    };
    enterAndConsult();
  }, []);

    // validar los datos
  //Errores especificados:
  // error 01 = El circuito esta en un estado 1. Activo, el estadoFirmante se encuentra en 1.Activo pero el estadoFirmaUsuario se encuentra en un estado diferente a los establecidos en el backend que son : 1. Activo, 2.Completado, 3. Expirado, 4. Cancelado, 5. Eliminado y 6. Leido.
  // error 02 = El circuito esta en un estado 1. Activo, sin embargo estadoFirmante se encuentra en estado 5. Eliminado, por lo cual ya no poseemos su informacion.
  // error 03 = El circuito esta en un estado 1. Activo, sin embargo estadoFirmante se encuentra en un estado diferente a los establecidos en el backend que son : 1. Activo y 5. Eliminado
  // error 04 = El circuito se encuentra en un estado diferente a los establecidos en el backend que son : 1. Activo, 2.Completado, 3. Expirado, 4. Cancelado y 5. Eliminado
  // error 1.1 = El circuito esta en un estado 4. Rechazado, sin embargo estadoFirmaUsuario, se encuentra en un estado diferente e los establecidos en el backend que son : 1. Activo, 2.Completado, 3. Expirado, 4. Cancelado, 5. Eliminado y 6. Leido.
  useEffect(() => {
    if(archivos.length>0){
      switch (resultado.httpStatus) {
        case 500:
        case 401:
        case 400:
          handleError(resultado.responseText);
          break;
  
        case 200:
          switch (resultado.circuito.estado) {
            case 1:
              switch (resultado.firmante.estadoFirmante) {
                case 1:
                  switch (resultado.firmante.estadoFirmaUsuario) {
                    case 1:
                    case 6:
                      if (resultado.firmante.usuarioFirmaya) {
                        setLoading(false);
                        localStorage.setItem('URL_Circuito', JSON.stringify(cifrado));
                        volver.push({
                          pathname: '/home/FirmarCircuito',
                          state: { resultado: resultado, archivos:archivos }
                        });
                      } else {
                        setRuta(resultado.documentos[0].location)
                        setNoUserFirmaYa(true)
                        setLoading(false);
                      }
                      break;
  
                    case 2:
                      if (resultado.firmante.esObservador === 0) {
                        handleError('¡Este documento ya lo firmaste!');
                        setChecks(true);
                      } else {
                        if (resultado.firmante.usuarioFirmaya) {
                          setLoading(false);
                          localStorage.setItem('URL_Circuito', JSON.stringify(cifrado));
                          volver.push({
                            pathname: '/home/FirmarCircuito',
                            state: { resultado: resultado, archivos:archivos }
                          });
                        } else {
                          setRuta(resultado.documentos[0].location)
                          setNoUserFirmaYa(true)
                          setLoading(false);
                        }
                      }
                      break;
  
                    case 4:
                      handleError("¡Este documento ya ha sido rechazado por ti!");
                      setChecks(true);
                      break;
  
                    case 5:
                      handleError("¡Has sido eliminado de este circuito de firmas!");
                      setPersonaEliminada(true);
                      break;
                    default:
                      handleError("Error 01");
                  }
                  break;
  
                case 5:
                  handleError("Error 02");
                  break;
  
                default:
                  handleError("Error 03");
                  break;
              }
              break;
  
            case 2:
              switch (resultado.firmante.estadoFirmaUsuario) {
                case 5:
                  handleError('¡Has sido eliminado de este circuito de firmas!');
                  setPersonaEliminada(true);
                  break;
  
                default:
                  handleError(resultado.firmante.esObservador !== 0
                    ? 'Todos firmaron Documento no disponible.'
                    : '¡Este documento ya lo firmaste!');
                  setChecks(true);
                  break;
              }
              break;
  
            case 3:
              handleError("Este circuito ha expirado");
              setMsgBtn("Aceptar");
              break;
  
            case 4:
              switch (resultado.firmante.estadoFirmaUsuario) {
                case 1:
                  handleError("Este circuito ha sido rechazado");
                  setChecks(true);
                  break;
  
                case 2:
                  handleError("¡Este documento ya lo firmaste!");
                  setChecks(true);
                  break;
  
                case 4:
                  handleError("¡Este documento ya ha sido rechazado por ti!");
                  setChecks(true);
                  break;
  
                case 5:
                  handleError("¡Has sido eliminado de este circuito de firmas!");
                  setPersonaEliminada(true);
                  break;
  
                case 6:
                  handleError("Este circuito ha sido rechazado");
                  setChecks(true);
                  break;
  
                default:
                  handleError("Error 1.1");
              }
              break;
  
            case 5:
              handleError("Este circuito de firmas ha sido eliminado por el creador");
              setCircuitoEliminado(true);
              setMsgBtn("Aceptar");
              break;
  
            default:
              handleError("error 04");
              break;
          }
          break;
      }
    }
  }, [resultado, archivos]);

  /**
 * Esta función maneja el proceso de autenticación para obtener un token de acceso.
  * Realiza una solicitud HTTP POST al endpoint de inicio de sesión con las credenciales y parámetros necesarios.
  * Si la solicitud es exitosa y se recibe un token, se despacha una acción para guardar el token en el estado global.
  * En caso de error o si el token no está disponible en la respuesta, se maneja el error y se retorna null.
  * @async Es una funcion asincrona
  * @function
  * @returns {Promise<string|null>} Retorna una promesa que se resuelve con el token recibido en la respuesta si la autenticación es exitosa,
  *                                  o con null si ocurre un error o el token no está disponible.
  * @throws {Error} Lanza un error si ocurre un problema durante la solicitud.
  * Documentado por: Lizeth Paola Delgadillo Robayo
  */
  const login = async () => {
		try {
			const options = {
				headers: {
					'Content-Type': 'application/json',
				},
				body: {
					user: USUARIO_FIRMAYA_GENERICO,
					password: PASSWORD_FIRMAYA_GENERICO,
					appname: APP_NAME,
				},
			};
			const response= await http.post(URL_FIRMAYA_LOGIN, options);
      if (response.result && response.result.token) {
        const token = response.result.token;
        dispatch(tokenGenerico(token));
        return token;
      } else {
        console.error("Token no disponible en la respuesta:", response);
        return null;
      }
		} catch (error) {
			console.error('error', error);
		}
	};

  /**
 * Esta función actualiza el estado de un circuito y de un firmante específico a través de una solicitud HTTP POST, este estado pasa de ser activo (1) a ser leido (6).
 * Utiliza un token de autenticación para realizar la solicitud al endpoint designado. 
 * Si la solicitud se realiza correctamente, actualiza el estado del circuito y del firmante con los valores proporcionados.
 * En caso de error, se maneja el error y se muestra en la consola.
 * @async Es una funcion asincrona
 * @function
 * @param {string} token - Token de autenticación utilizado para autorizar la solicitud.
 * @returns {Promise<void>} No retorna ningún valor. La función maneja internamente la actualización del estado y los posibles errores.
 * @throws {Error} Lanza un error si ocurre un problema durante la solicitud.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
  const changeState = async (token) => {
    try {
      const headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      };
  
      const body = {
        circuito: newIdCircuito,
        estadoCircuito: '',
        firmante: newIdFirmante,
        estadoFirmante: '6',
        correoFirmante: '',
      };
  
      const options = {
        headers,
        body,
      };
  
      const respCambioEstado = await http.post(
        API_HOSTNAME_AWS + 'webCircuit/api/UpdateCircuit',
        options
      );
  
      const { result } = respCambioEstado;  
    } catch (error) {
      console.error('Ocurrió un error', error);
    }
  };
  
  /**
 * Esta función verifica el estado de un circuito y actualiza la lista de firmantes con la información obtenida.
 * Realiza una solicitud HTTP POST al endpoint de firma utilizando un token de autenticación. 
 * Filtra los firmantes que tienen un estado diferente a 5 y agrega el firmante actual a la lista.
 * La lista de firmantes se ordena por la posición y se actualizan los estados de `resultado` y `firmantes` en el componente.
 * En caso de error, se maneja el error y se actualiza el estado `resultado` con un mensaje de error.
 * @async Es una funcion asincrona
 * @function
 * @param {string} token - Token de autenticación utilizado para autorizar la solicitud.
 * @returns {Promise<void>} No retorna ningún valor. La función actualiza los estados `resultado` y `firmantes`.
 * @throws {Error} Lanza un error si ocurre un problema durante la solicitud.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
  const checkCircuitStatus = async (token) => {
    try {
      const headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      };
  
      const body = {
        circuito: newIdCircuito,
        firmante: newIdFirmante,
      };

      const options = {
        headers,
        body,
      };
  
      const response = await http.post(
        API_HOSTNAME_AWS + 'webCircuit/api/Signatory',
        options
      );
  
      const result_1 = response;
      setResultado(result_1);
      let documents = result_1.documentos.map((doc, index) => ({
        id: index + 1,
        nombre: doc.nombreReal,
        nombrerepositorio: doc.nombre,
        repositorio: 1,
        ruta: doc.location,
      }));
      await handleFileResponse(documents)
  
      const firmantes = result_1.inFirmantes.filter(firm => firm.estado !== 5);
      let list = [...firmantes]; 
  
      list.push({
        email: result_1.firmante.correo,
        estado: result_1.firmante.estadoFirmaUsuario,
        nombre: result_1.firmante.nombres + ' ' + result_1.firmante.apellidos,
        posicion: result_1.firmante.posicion,
        esAprobador: result_1.firmante.esAprobador,
        esObservador: result_1.firmante.esObservador,
      }); 
      list.sort((a, b) => a.posicion - b.posicion); 
      setFirmantes(list);
    } catch (error) {
      console.error("Ocurrió un error", error);
      setResultado('Ocurrió un error al consultar los archivos');
    }
  };

  /**
 * Maneja el estado de error en el componente, actualizando la interfaz de usuario en respuesta a un problema.
 * Esta función desactiva el estado de carga (`loading`), muestra un modal de carga (`modalPantallaCarga`) y establece un mensaje de error.
 * @function
 * @param {string} message - Mensaje de error que se mostrará en el modal de carga.
 * @returns {void} No retorna ningún valor. La función actualiza el estado del componente para reflejar el error.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
  const handleError = (message) => {
    setLoading(false);
    setModalPantallaCarga(true);
    setMessage(message);
  };

  /**
 * Maneja la respuesta de una operación de carga de archivos. 
 * Realiza una llamada a una acción para obtener los archivos, actualiza el estado del componente con la ruta y tamaño de los archivos,
 * y muestra un mensaje de error en caso de falla.
 * @async Es una funcion asincrona
 * @function
 * @param {Array} files - Lista de archivos que se van a cargar.
 * @returns {Promise<void>} No retorna ningún valor. La función actualiza el estado y muestra mensajes según el resultado de la operación.
 * @throws {Error} Lanza un error si ocurre un problema durante la operación.
 * Documentado por: Lizeth Paola Delgadillo Robayo
 */
  const handleFileResponse = async (files) => {
    let response = await dispatch(getFilesCF(files, enqueueSnackbar, history));
    if (response) {
      setArchivos(response.files)
    } else {
      enqueueSnackbar(`ocurrio un error al cargar los archivos`, {
        style: { whiteSpace: 'pre-line' },
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  if (loading) return <Loading />;

  return (
    <>
      {modalPantallaCarga && (
        <MessagePantallaCarga
          message={message}
          checks={checks}
          circuitoEliminado={circuitoEliminado}
          personaEliminada={personaEliminada}
          msgBtn={msgBtn}
          irHome={resultado.firmante.usuarioFirmaya}
        />
      )}
      {noUserFirmaYa && (
        <SignerFromMail
          style={style}
          data={resultado}
          firmantes={firmantes}
          ruta={ruta}
          archivos={archivos}
        />
      )}
    </>
  );
};

export default PantallaCarga;