import axios from "axios";
import {
  API_HOSTNAME_GET_CERTIFICATE,
  API_HOSTNAME_SIGNS,
  API_HOSTNAME_GET_LIST_HANDWRITTE,
} from "../types/ApiConstants";
import {
  SIGN_OK_FIRMA_AVANZADA,
  SET_SELECT_IDTSA,
  LOADING,
  SET_LIST_HANDWRITTE,
} from "../types/SignCunstomDocumentTypes";
import {
  cambiarEstadoFirmante,
  notificar,
  notificarCreador,
} from "./CircuitActions";

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("JWT");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const getCertificates =
  (onNoCertificates, Activar, setServices) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const response = await axios.get(`${API_HOSTNAME_GET_CERTIFICATE}`);
      const services = response.data.services;
      if (setServices) {
        setServices(services); 
      }
      if (response.data.statusCode === 200) {
        let certificadosLista = response.data.result;
        if (certificadosLista) {
          if (
            certificadosLista.filter(
              (ce) =>
                ce.codigoestadocertificado == "EMC" ||
                ce.codigoestadocertificado == "FIN"
            ).length < 1
          ) {
            onNoCertificates();
            return false;
          }
        }
        dispatch({
          type: SET_SELECT_IDTSA,
          payload: certificadosLista.filter(
            (ce) =>
              ce.codigoestadocertificado == "EMC" ||
              ce.codigoestadocertificado == "FIN"
          ),
        });
        return true;
      }
      if (response.data.statusCode !== 200) {
        if (
          response.data.statusMessage ==
          "No se encontraron certificados para este usuario"
        ) {
          Activar();
          return true;
        }
        dispatch({ type: LOADING, payload: false });
        return true;
      }
    } catch (error) {
      console.log("Errorrr: ", error);
      return true;
    }
  };

export const getHandwrittenImage = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const response = await axios.get(`${API_HOSTNAME_GET_LIST_HANDWRITTE}`);
    dispatch({ type: SET_LIST_HANDWRITTE, payload: response.data });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
  }
};

export const SignCustomWithoutdocument = async (
  sendData,
  enqueueSnackbar,
  setStatusModalAlert,
  terminaFirma
) => {
  let errorMessage = "Error en la firma";
  let detalle;
  let documento;
  if (!valiadrSendData(sendData)) {
    enqueueSnackbar("Documentos sin firma", {
      variant: "warning",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
    terminaFirma(false);
    return;
  }
  await axios
    .post(`${API_HOSTNAME_SIGNS}api/sign/advancedsignature`, sendData)
    .then((response) => {
      if (response.status == 200 && response.data.codeResponse == "RS1") {
        enqueueSnackbar("Firma completa", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setStatusModalAlert(true);
        terminaFirma(true);
        return;
      }
      if (response.data) {
        for (const a in response.data.detalles) {
          detalle = response.data.detalles[a];
          documento = buscarDocumento(sendData, detalle.uuid);
          errorMessage += `\n\r${documento.fileName} (${detalle.detalle})`;
        }
      }
      enqueueSnackbar(errorMessage, {
        autoHideDuration: 6000,
        variant: "error",
        style: { whiteSpace: "pre-line" },
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      terminaFirma(false);
    })
    .catch((error) => {
      console.log(error);
      try {
        if (error.response.status == 401) {
          if (
            error.response.data.descripcionRespuesta ==
            "privilegio de firma digital centralizada"
          ) {
            enqueueSnackbar(
              "No tiene disponibilidad para firmar documentos, por favor aumente su plan Firmaya",
              {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              }
            );
          } else if (
            error.response.data.mensaje ==
            "privilegio de firma digital centralizada"
          ) {
            enqueueSnackbar(
              "No tiene disponibilidad para firmar documentos, por favor aumente su plan Firmaya",
              {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              }
            );
          } else {
            enqueueSnackbar(
              "No tiene disponibilidad para el " +
                error.response.data.mensaje,
              {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              }
            );
          }
        } else if (error.response.status == 400) {
          enqueueSnackbar(error.response.data.mensaje, {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else {
          enqueueSnackbar(
            "Ocurrió un error inesperado al procesar la transacción",
            {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
        }
      } catch (error) {
        enqueueSnackbar(
          "Ocurrió un error inesperado al procesar la transacción",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
      }
      terminaFirma(false);
    });
};

export const SignCustomWithoutdocumentS3 = async (
  sendData,
  enqueueSnackbar,
  setStatusModalAlert,
  terminaFirma
) => {
  try {
    let filesPut = [];
    if (!valiadrSendData(sendData)) {
      enqueueSnackbar("Documentos sin firma", {
        variant: "warning",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      terminaFirma(false);
      return;
    }
    await Promise.all(
      sendData.documents.map((f) => {
        filesPut.push({ uuid: f.uuid, nombre: f.fileName });
      })
    );
    var dataS3 = {
      idClienteFirmante: sendData.signerClientId,
      documentos: filesPut,
    };
    const response = await axios.post(
      `${API_HOSTNAME_SIGNS}api/sign/generateS3Url`,
      dataS3
    );
    if (response.status === 200) {
      const listFiles = [];
      const listSign = [];
      await Promise.all(
        sendData.documents.map(async (f) => {
          listFiles.push({
            uuid: f.uuid,
            base64: f.base64,
            nombre: f.fileName,
          });
        })
      );
      await Promise.all(
        response.data.result.map(async (file) => {
          var bytes = null;
          listFiles.map((f) => {
            if (file.uuid == f.uuid && file.nombreReal == f.nombre) {
              bytes = _base64ToArrayBuffer(f.base64);
            }
          });
          if (bytes != null) {
            let putDocument = await putDocumentsS3(file.urlPut, bytes);
            if (putDocument == 200) {
              await Promise.all(
                sendData.documents.map(async (f) => {
                  if (file.uuid == f.uuid && file.nombreReal == f.fileName) {
                    listSign.push({
                      uuid: f.uuid,
                      fileName: file.nombre,
                      realFileName: file.nombreReal,
                      urlGet: file.urlGet,
                      urlPut: file.urlPut,
                      typeSignature: f.typeSignature,
                      location: file.location,
                      listSigns: f.listSigns,
                    });
                  }
                })
              );
            } else {
              enqueueSnackbar(
                "Error al cargar el archivo " + file.nombreReal + " en S3",
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                }
              );
            }
          }
        })
      );
      const toSend = {
				signerClientId: sendData.signerClientId,
				key: sendData.key,
				firmaelectronica: sendData.firmaelectronica,
				codeId: sendData.codeId,
				documents: listSign,
			};
      const responsePut = await axios
        .post(`${API_HOSTNAME_SIGNS}api/sign/advancedsignatures3`, toSend)
        .then((responsePut) => {
          if (responsePut.status == 200) {
            if (responsePut.data.codeResponse == "RS1") {
              enqueueSnackbar("Firma completa", {
                variant: "success",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              });
              terminaFirma(true);
              return;
            } else {
              responsePut.data.documentos.forEach((element) => {
                enqueueSnackbar(element.mensaje, {
                  variant: "warning",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                });
              });
              terminaFirma(false);
              return;
            }
          }
        })
        .catch((error) => {
          try {
            if (error.response.status == 401) {
              if (
                error.response.data.message ==
                "privilegio de firma digital centralizada"
              ) {
                enqueueSnackbar(
                  "No tiene disponibilidad para firmar documentos, por favor aumente su plan Firmaya",
                  {
                    variant: "warning",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  }
                );
              } else {
                enqueueSnackbar(
                  "No tiene disponibilidad para el " +
                    error.response.data.message,
                  {
                    variant: "warning",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  }
                );
              }
            } else if (error.response.status == 400) {
              enqueueSnackbar(error.response.data.message, {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              });
            } else {
              enqueueSnackbar(
                "Ocurrió un error inesperado al procesar la transacción",
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                }
              );
            }
          } catch (error) {
            enqueueSnackbar(
              "Ocurrió un error inesperado al procesar la transacción",
              {
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              }
            );
          }
          terminaFirma(false);
        });
    } else {
      enqueueSnackbar(response.data.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      terminaFirma(false);
      return;
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status >= 400 && error.response.status < 500) {
        if (error.response.status == 401) {
          enqueueSnackbar(
            "No tiene disponibilidad para el " +
              error.response.data.message,
            {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
        } else {
          enqueueSnackbar(error.response.data.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      } else if (error.response.status === 500) {
        enqueueSnackbar("Ocurrio un error procesando su solicitud.", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } else {
      enqueueSnackbar(
        "Error de red.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.",
        {
          variant: "error",
          style: { whiteSpace: "pre-line" },
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
    }
    terminaFirma(false);
  }
};

export const firmaAvanzadaToken =
  (sendData, enqueueSnackbar, terminaFirma, assistant) => async () => {
    let deleteFiles = [];
    let certificate = null;
    let thumbprint = null;
    try {
      if (!valiadrSendData(sendData)) {
        enqueueSnackbar("Documentos sin firma", {
          variant: "warning",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        terminaFirma(false);
        return;
      }
      var socketConnection = new WebSocket("ws://127.0.0.1:28080/Connection");
      socketConnection.onopen = function () {
        socketConnection.send(0);
      };
      socketConnection.onmessage = async function (e) {
        socketConnection.close();
        let filesPut = [];
        await Promise.all(
          sendData.documents.map((f, index) => {
            filesPut.push({
              uuid: index,
              nombre: f.fileName,
              listSign: f.listSigns,
              typeSignature: f.typeSignature,
            });
          })
        );
        var dataS3 = {
          documentos: filesPut,
        };
        try {
          const response = await axios.post(
            `${API_HOSTNAME_SIGNS}api/sign/generateUrlAdvancedToken`,
            dataS3
          );
          if (response.status === 200) {
            let listFiles = [];
            let listSign = [];
            let signDocuments = [];
            await Promise.all(
              sendData.documents.map(async (f, index) => {
                listFiles.push({
                  uuid: index,
                  base64: f.base64,
                  nombre: f.fileName,
                  listSigns: f.listSigns,
                  typeSignature: f.typeSignature,
                });
              })
            );
            await Promise.all(
              response.data.documentos.map(async (file) => {
                deleteFiles.push(file.nombreBucket);
                deleteFiles.push("temporal_" + file.nombreBucket);
                deleteFiles.push(
                  "propiedades_" +
                    file.nombreBucket.replace(
                      "." + file.nombreBucket.split(".").pop(),
                      ""
                    )
                );
                var bytes = null;
                listFiles.map((f) => {
                  if (file.uuid == f.uuid && file.nombreReal == f.nombre) {
                    bytes = _base64ToArrayBuffer(f.base64);
                    if (f.listSigns) {
                      file.listSigns = f.listSigns;
                    } else {
                      file.listSigns = [];
                    }
                    file.typeSignature = f.typeSignature;
                  }
                });
                if (bytes != null) {
                  let putDocument = await putDocumentsS3(
                    file.urlPutOriginal,
                    bytes
                  );
                  if (putDocument == 200) {
                    listSign.push(file);
                  } else {
                    enqueueSnackbar(
                      "Error al cargar el archivo " +
                        file.nombreReal +
                        " en S3 Token",
                      {
                        variant: "error",
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                      }
                    );
                  }
                }
              })
            );
            let hasCertificate = false;
            await Promise.all(
              sendData.documents.map(async (document, index) => {
                document.typeSignature.map((signature) => {
                  if (
                    signature.signPredefined == 1 ||
                    signature.addDataCert == 1
                  ) {
                    hasCertificate = true;
                  }
                });
                var socketCertificate = new WebSocket(
                  "ws://127.0.0.1:28080/Certificate"
                );
                if (
                  hasCertificate == true &&
                  index == sendData.documents.length - 1
                ) {
                  socketCertificate.onopen = function () {
                    socketCertificate.send(0);
                  };
                  socketCertificate.onmessage = async function (e) {
                    let responseSocket = JSON.parse(e.data);
                    if (responseSocket.Certificate != null) {
                      certificate = await responseSocket.Certificate;
                      thumbprint = await responseSocket.Thumbprint;
                    }
                    socketCertificate.close();
                  };
                  socketCertificate.onclose = async function (e) {
                    const toSend = {
                      documentos: listSign,
                      certificado: certificate,
                    };
                    await signdocumentToken(
                      toSend,
                      thumbprint,
                      enqueueSnackbar,
                      deleteFiles,
                      listFiles,
                      signDocuments,
                      terminaFirma
                    );
                  };
                }
                if (
                  hasCertificate == false &&
                  index == sendData.documents.length - 1
                ) {
                  const toSend = {
                    documentos: listSign,
                    certificado: null,
                  };
                  await signdocumentToken(
                    toSend,
                    null,
                    enqueueSnackbar,
                    deleteFiles,
                    listFiles,
                    signDocuments,
                    terminaFirma
                  );
                }
              })
            );
          } else {
            enqueueSnackbar(response.data.descripcionRespuesta, {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            terminaFirma(false);
          }
        } catch (e) {
          if (deleteFiles.length > 0) {
            let toSend = {
              folderName: null,
              repository: null,
              documentsName: deleteFiles,
            };
            const responseClean = await axios.post(
              `${API_HOSTNAME_SIGNS}api/sign/cleanBucket`,
              toSend
            );
            console.log(responseClean);
          }
          terminaFirma(false);
          if (e.response) {
            if (e.response.status == 400) {
              enqueueSnackbar("Los datos enviados son incorrectos", {
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              });
            } else if (e.response.status == 401) {
              enqueueSnackbar(
                "No tiene disponibilidad para el " +
                  e.response.data.descripcionRespuesta,
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                }
              );
            } else if (e.response.status == 404) {
              enqueueSnackbar("El servicio solicitado no se encontró", {
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              });
            } else if (e.response.status == 500) {
              enqueueSnackbar(
                "Ocurrió un error inesperado al procesar la transacción",
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                }
              );
            }
          }
        }
      };
      if (deleteFiles.length > 0) {
        let toSend = {
          folderName: null,
          repository: null,
          documentsName: deleteFiles,
        };
        const responseClean = await axios.post(
          `${API_HOSTNAME_SIGNS}api/sign/cleanBucket`,
          toSend
        );
        console.log(responseClean);
      }

      socketConnection.onerror = function () {
        assistant();
        terminaFirma(false);
      };
    } catch (error) {
      if (deleteFiles.length > 0) {
        let toSend = {
          folderName: null,
          repository: null,
          documentsName: deleteFiles,
        };
        const responseClean = await axios.post(
          `${API_HOSTNAME_SIGNS}api/sign/cleanBucket`,
          toSend
        );
        console.log(responseClean);
      }
      if (error.response) {
        if (error.response.status == 400) {
          enqueueSnackbar("Los datos enviados son incorrectos", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else if (error.response.status == 401) {
          enqueueSnackbar(
            "No tiene autorización para procesar la transacción",
            {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
        } else if (error.response.status == 404) {
          enqueueSnackbar("El servicio solicitado no se encontró", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else if (error.response.status == 500) {
          enqueueSnackbar(
            "Ocurrió un error inesperado al procesar la transacción",
            {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
        }
        terminaFirma(false);
      }
    }
  };
export const firmaAvanzadaTokenCF =
  (
    sendData,
    enqueueSnackbar,
    terminaFirma,
    assistant,
    dataSignatory,
    circuito,
    firmante,
    ultimaPosicion,
    abrirModalfinalizar
  ) =>
  async () => {
    let deleteFiles = [];
    let certificate = null;
    let thumbprint = null;

    try {
      var socketConnection = new WebSocket("ws://127.0.0.1:28080/Connection");
      socketConnection.onopen = function () {
        socketConnection.send(0);
      };
      socketConnection.onmessage = async function (e) {
        socketConnection.close();
        let filesPut = [];
        await Promise.all(
          sendData.documents.map((f, index) => {
            filesPut.push({
              uuid: index,
              nombre: f.fileName,
              listSign: f.listSigns,
              typeSignature: f.typeSignature,
            });
          })
        );
        function nuevoArray(array) {
          const { nombre, location, nombreReal } = array;
          return {
            nombreBucket: nombre,
            ruta: location,
            nombreReal: nombreReal,
          };
        }
        const newArray = dataSignatory.map(nuevoArray);
        function mergeArrays(array, dataSignatoryN) {
          const mergedArray = [];
          array.forEach((obj, index) => {
            const newObj = dataSignatoryN.find(
              (o) => o.nombreReal === obj.nombre
            );

            if (newObj) {
              const { name, ...rest } = obj;
              mergedArray.push({
                uuid: index,
                nombre: name,
                ...newObj,
                ...rest,
              });
            }
          });
          return mergedArray;
        }
        filesPut = mergeArrays(filesPut, newArray);
        var dataS3 = {
          documentos: filesPut,
          ruta: filesPut[0].ruta,
        };
        try {
          const response = await axios.post(
            `${API_HOSTNAME_SIGNS}api/sign/generateS3UrlToken`,
            dataS3
          );
          if (response.status === 200) {
            let listFiles = [];
            let listSign = [];
            let signDocuments = [];
            await Promise.all(
              sendData.documents.map(async (f, index) => {
                listFiles.push({
                  uuid: index,
                  base64: f.base64,
                  nombre: f.fileName,
                  listSigns: f.listSigns,
                  typeSignature: f.typeSignature,
                });
              })
            );
            await Promise.all(
              response.data.documentos.map(async (file) => {
                deleteFiles.push(file.nombreBucket);
                deleteFiles.push("temporal_" + file.nombreBucket);
                deleteFiles.push(
                  "propiedades_" +
                    file.nombreBucket.replace(
                      "." + file.nombreBucket.split(".").pop(),
                      ""
                    )
                );
                var bytes = null;
                listFiles.map((f) => {
                  if (file.uuid == f.uuid && file.nombreReal == f.nombre) {
                    bytes = _base64ToArrayBuffer(f.base64);
                    if (f.listSigns) {
                      file.listSigns = f.listSigns;
                    } else {
                      file.listSigns = [];
                    }
                    file.typeSignature = f.typeSignature;
                  }
                });
                if (bytes != null) {
                  let putDocument = await putDocumentsS3(
                    file.urlPutOriginal,
                    bytes
                  );
                  if (putDocument == 200) {
                    listSign.push(file);
                  } else {
                    enqueueSnackbar(
                      "Error al cargar el archivo " +
                        file.nombreReal +
                        " en S3 Token",
                      {
                        variant: "error",
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                      }
                    );
                  }
                }
              })
            );
            let hasCertificate = false;
            await Promise.all(
              sendData.documents.map(async (document, index) => {
                document.typeSignature.map((signature) => {
                  if (
                    signature.signPredefined == 1 ||
                    signature.addDataCert == 1
                  ) {
                    hasCertificate = true;
                  }
                });
                var socketCertificate = new WebSocket(
                  "ws://127.0.0.1:28080/Certificate"
                );
                if (
                  hasCertificate == true &&
                  index == sendData.documents.length - 1
                ) {
                  socketCertificate.onopen = function () {
                    socketCertificate.send(0);
                  };
                  socketCertificate.onmessage = async function (e) {
                    let responseSocket = JSON.parse(e.data);
                    if (responseSocket.Certificate != null) {
                      certificate = await responseSocket.Certificate;
                      thumbprint = await responseSocket.Thumbprint;
                    }
                    socketCertificate.close();
                  };
                  socketCertificate.onclose = async function (e) {
                    const toSend = {
                      documentos: listSign,
                      certificado: certificate,
                    };

                    await signdocumentTokenCF(
                      toSend,
                      thumbprint,
                      enqueueSnackbar,
                      deleteFiles,
                      listFiles,
                      signDocuments,
                      circuito,
                      firmante,
                      ultimaPosicion,
                      abrirModalfinalizar,
                      terminaFirma
                    );
                  };
                }
                if (
                  hasCertificate == false &&
                  index == sendData.documents.length - 1
                ) {
                  const toSend = {
                    documentos: listSign,
                    certificado: null,
                  };

                  await signdocumentTokenCF(
                    toSend,
                    null,
                    enqueueSnackbar,
                    deleteFiles,
                    listFiles,
                    signDocuments,
                    circuito,
                    firmante,
                    ultimaPosicion,
                    abrirModalfinalizar,
                    terminaFirma
                  );
                }
              })
            );
          } else {
            enqueueSnackbar(response.data.descripcionRespuesta, {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            terminaFirma(true);
          }
        } catch (e) {
          if (deleteFiles.length > 0) {
            let toSend = {
              folderName: null,
              repository: null,
              documentsName: deleteFiles,
            };
            const responseClean = await axios.post(
              `${API_HOSTNAME_SIGNS}api/sign/cleanBucket`,
              toSend
            );
            console.log(responseClean);
          }
          terminaFirma(true);

          if (e.response) {
            if (e.response.status == 400) {
              enqueueSnackbar("Los datos enviados son incorrectos", {
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              });
            } else if (e.response.status == 401) {
              enqueueSnackbar(
                "No tiene disponibilidad para el " +
                  e.response.data.descripcionRespuesta,
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                }
              );
            } else if (e.response.status == 404) {
              enqueueSnackbar("El servicio solicitado no se encontró", {
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              });
            } else if (e.response.status == 500) {
              enqueueSnackbar(
                "Ocurrió un error inesperado al procesar la transacción",
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                }
              );
            }
          }
        }
      };
      if (deleteFiles.length > 0) {
        let toSend = {
          folderName: null,
          repository: null,
          documentsName: deleteFiles,
        };
        const responseClean = await axios.post(
          `${API_HOSTNAME_SIGNS}api/sign/cleanBucket`,
          toSend
        );
        console.log(responseClean);
      }

      socketConnection.onerror = function () {
        assistant();
        terminaFirma(true);
      };
    } catch (error) {
      if (deleteFiles.length > 0) {
        let toSend = {
          folderName: null,
          repository: null,
          documentsName: deleteFiles,
        };
        const responseClean = await axios.post(
          `${API_HOSTNAME_SIGNS}api/sign/cleanBucket`,
          toSend
        );
        console.log(responseClean);
      }
      if (error.response) {
        if (error.response.status == 400) {
          enqueueSnackbar("Los datos enviados son incorrectos", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else if (error.response.status == 401) {
          enqueueSnackbar(
            "No tiene autorización para procesar la transacción",
            {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
        } else if (error.response.status == 404) {
          enqueueSnackbar("El servicio solicitado no se encontró", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else if (error.response.status == 500) {
          enqueueSnackbar(
            "Ocurrió un error inesperado al procesar la transacción",
            {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
        }
        terminaFirma(true);
      }
    }
  };
async function signdocumentToken(
  toSend,
  thumbprint,
  enqueueSnackbar,
  deleteFiles,
  listFiles,
  signDocuments,
  terminaFirma
) {
  const responseHash = await axios.post(
    `${API_HOSTNAME_SIGNS}api/sign/generateAdvancedHash`,
    toSend
  );
  if (responseHash.status === 200) {
    let listSocket = [];
    await Promise.all(
      responseHash.data.documentos.map(async (document) => {
        let dataSocket = {
          Uuid: document.uuid,
          Data: document.hash,
        };
        listSocket.push(dataSocket);
      })
    );
    let payload = {
      token: responseHash.data.token,
      data: listSocket,
      thumbprint: thumbprint,
    };
    var sock = new WebSocket("ws://127.0.0.1:28080/Signer");
    sock.onopen = function () {
      sock.send(JSON.stringify(payload));
    };
    sock.onmessage = async function (e) {
      let responseSocket = JSON.parse(e.data);
      if (responseSocket.Certificate != null) {
        await Promise.all(
          responseHash.data.documentos.map(async (document) => {
            responseSocket.Signature.map((signature) => {
              if (signature.Uuid == document.uuid) {
                document.certificado = responseSocket.Certificate;
                document.hashFirmado = signature.Data;
                listFiles.map((f) => {
                  if (document.uuid == f.uuid) {
                    if (f.listSigns) {
                      document.listSigns = f.listSigns;
                    } else {
                      document.listSigns = [];
                    }
                    document.typeSignature = f.typeSignature;
                  }
                });
                signDocuments.push(document);
              }
            });
          })
        );
        const toSign = {
          documentos: signDocuments,
        };
        const responseSign = await axios.post(
          `${API_HOSTNAME_SIGNS}api/sign/generateAdvancedSign`,
          toSign
        );
        if (responseSign.status === 200) {
          enqueueSnackbar("Firma completa", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          sock.close();
          terminaFirma(true);
        } else {
          enqueueSnackbar(responseSign.data.descripcionRespuesta, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
        sock.close();
        terminaFirma(false);
      } else {
        if (deleteFiles.length > 0) {
          let toSend = {
            folderName: null,
            repository: null,
            documentsName: deleteFiles,
          };
          const responseClean = await axios.post(
            `${API_HOSTNAME_SIGNS}api/sign/cleanBucket`,
            toSend
          );
          console.log(responseClean);
        }
        sock.close();
        terminaFirma(false);
      }
    };
  } else {
    if (deleteFiles.length > 0) {
      let toSend = {
        folderName: null,
        repository: null,
        documentsName: deleteFiles,
      };
      const responseClean = await axios.post(
        `${API_HOSTNAME_SIGNS}api/sign/cleanBucket`,
        toSend
      );
      console.log(responseClean);
    }
    terminaFirma(false);
  }
}

async function signdocumentTokenCF(
  toSend,
  thumbprint,
  enqueueSnackbar,
  deleteFiles,
  listFiles,
  signDocuments,
  circuito,
  firmante,
  ultimaPosicion,
  abrirModalfinalizar,
  terminaFirma
) {
  const responseHash = await axios.post(
    `${API_HOSTNAME_SIGNS}api/sign/generateAdvancedHash`,
    toSend
  );
  if (responseHash.status === 200) {
    let listSocket = [];
    await Promise.all(
      responseHash.data.documentos.map(async (document) => {
        let dataSocket = {
          Uuid: document.uuid,
          Data: document.hash,
        };
        listSocket.push(dataSocket);
      })
    );
    let payload = {
      token: responseHash.data.token,
      data: listSocket,
      thumbprint: thumbprint,
    };
    var sock = new WebSocket("ws://127.0.0.1:28080/Signer");
    sock.onopen = function () {
      sock.send(JSON.stringify(payload));
    };
    sock.onmessage = async function (e) {
      let responseSocket = JSON.parse(e.data);
      if (responseSocket.Certificate != null) {
        await Promise.all(
          responseHash.data.documentos.map(async (document) => {
            responseSocket.Signature.map((signature) => {
              if (signature.Uuid == document.uuid) {
                document.certificado = responseSocket.Certificate;
                document.hashFirmado = signature.Data;
                listFiles.map((f) => {
                  if (document.uuid == f.uuid) {
                    if (f.listSigns) {
                      document.listSigns = f.listSigns;
                    } else {
                      document.listSigns = [];
                    }
                    document.typeSignature = f.typeSignature;
                  }
                });
                signDocuments.push(document);
              }
            });
          })
        );
        const toSign = {
          documentos: signDocuments,
        };
        const responseSign = await axios.post(
          `${API_HOSTNAME_SIGNS}api/sign/generateAdvancedSign`,
          toSign
        );
        if (responseSign.status === 200) {
          enqueueSnackbar("Firma completa", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });

          await cambiarEstadoFirmante(circuito, firmante, 2);
          if (ultimaPosicion) {
            notificarCreador(ultimaPosicion, circuito, firmante);
          } else {
            notificar(circuito);
            notificarCreador(ultimaPosicion, circuito, firmante);
          }
          sock.close();
          abrirModalfinalizar();
          //dispatch(modalFinished(true));
          // dispatch({ type: MODAL_FINISHED, payload: true });
          // return true;
        } else {
          enqueueSnackbar(responseSign.data.descripcionRespuesta, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
        sock.close();
        terminaFirma(true);
      } else {
        if (deleteFiles.length > 0) {
          let toSend = {
            folderName: null,
            repository: null,
            documentsName: deleteFiles,
          };
          const responseClean = await axios.post(
            `${API_HOSTNAME_SIGNS}api/sign/cleanBucket`,
            toSend
          );
          console.log(responseClean);
        }
        sock.close();
        terminaFirma(true);
      }
    };
  } else {
    if (deleteFiles.length > 0) {
      let toSend = {
        folderName: null,
        repository: null,
        documentsName: deleteFiles,
      };
      const responseClean = await axios.post(
        `${API_HOSTNAME_SIGNS}api/sign/cleanBucket`,
        toSend
      );
      console.log(responseClean);
    }
    terminaFirma(true);
  }
}

function _base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

function putDocumentsS3(url, data) {
  return new Promise(function (resolve, reject) {
    let xhr = new XMLHttpRequest();
    xhr.open("PUT", url, true);
    xhr.onload = function () {
      if (xhr.status != 200) {
        reject(xhr.status);
      } else {
        resolve(xhr.status);
      }
    };
    xhr.send(data);
  });
}

/*
Nombre :      signOk
 
Autor:        Juan Sebastian Morales (Bucaramanga)
 
Descripcion:  Modifica el indicador se signOk el cual informa
				  si la carga del modulo se realiza por ser el resultado
				  de una firma avanzada exitosa
 
*/
export const signOkFirmaAvanzada = (state) => async (dispatch) => {
  dispatch({
    type: SIGN_OK_FIRMA_AVANZADA,
    payload: state,
  });
};

const buscarDocumento = (newSenData, uuid) => {
  for (const a in newSenData.documents) {
    if (newSenData.documents[a].uuid != uuid) {
      continue;
    }
    return newSenData.documents[a];
  }
};
const valiadrSendData = (newSenData) => {
  for (const a in newSenData.documents) {
    if (!newSenData.documents[a].typeSignature.length) {
      return false;
    }
  }
  return true;
};

export default getCertificates;
