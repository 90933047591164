import React, { useEffect, useMemo, useState } from 'react';
import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
	Menu,
	MenuItem,
	Box,
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import FolderIcon from '@mui/icons-material/Folder';
import { EnhancedTableHead } from '../../components/EnhancedTableHead';
import {
	getComparator,
	orderListype,
	stableSort,
} from '../../helpers/ConsultSignedDocuments/stableSort';
import ViewResign from '../../components/ViewResign';
import { useHistory } from 'react-router-dom';
import { truncarNombreCompleto } from '../../helpers/helpNames';

const PendingToObserve = ({
	ref,
	classes,
	DateSign,
	handleSelectAllClick,
	indexRow,
	isFilterIsNull,
	rowsFilter,
	setIndex,
	orderMenu,
	setOrderMenu,
	isHome = false,
	loadDocuments,
}) => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('fechaCreacion');
	

	const history = useHistory();

	useEffect(() => {
		setPage(0);
	}, [rowsFilter]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleClick = (event, document) => {
		history.push(`/circuito/${document.encriptado}`);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleAction = () => {
	
		if (loadDocuments) {
			loadDocuments();
		}
	};

	const dateTypeFields = [
		'fechaRecepcion',
		'fechaCreacion',
		'fechaModificacion',
	];

	const ListOrder = useMemo(() => {
		return orderListype(rowsFilter, orderBy, order, dateTypeFields);
	}, [rowsFilter, orderBy, order]);

	const sort = (orderby, order) => {
		setOrderBy(orderby);
		setOrder(order);
		setOrderMenu(null);
	};

	return (
		<>
			<div ref={ref} className={'containerTabSignedDocument'}>
				<TableContainer style={{ minWidth: '868px' }}>
					<Table
						className={'tableSignedDocuments'}
						aria-labelledby='tableTitle'
						size='medium'
						aria-label='enhanced table'
						cellSpacing='0px'
					>
						<EnhancedTableHead
							classes={classes}
							numSelected={0}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={rowsFilter.length}
						/>
						{!isFilterIsNull && rowsFilter.length != 0 ? (
							<TableBody>
								{ListOrder.slice(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage
								).map((row, index) => {
									const labelId = `enhanced-table-checkbox-${index}`;
									return (
										<TableRow
											hover
											id={row.id}
											onClick={
												isFilterIsNull ? null : event => handleClick(event, row)
											}
											role='checkbox'
											tabIndex={-1}
											key={row.id}
											style={{ cursor: 'pointer' }}
											onMouseEnter={() => setIndex(index)}
											onMouseLeave={() => setIndex(null)}
											className={classes.fila}
										>
											<TableCell padding='none'>
												{/* <StyledCheckbox
													checked={isItemSelected}
													className={'checkButtonDocumentSigned'}
													inputProps={{
														'aria-labelledby': labelId,
													}}
												/> */}
											</TableCell>
											{!row.isFolder ? (
												<TableCell
													align='left'
													component='th'
													id={labelId}
													scope='row'
													padding='normal'
												>
													{isFilterIsNull ? (
														<Grid container>
															<Grid item xs={1} />
															<Grid item xs={11}>
																<Typography>{row.nombrereal}</Typography>
															</Grid>
														</Grid>
													) : (
														<Grid container>
															<Grid item xs={1} />
															<Grid item xs={11}>
																<Typography
																	className={classes.nombre}
																	title={row.nombrereal}
																>
																	{truncarNombreCompleto(
																		row.nombrereal,
																		'',
																		30
																	)}
																</Typography>
															</Grid>
														</Grid>
													)}
												</TableCell>
											) : (
												<TableCell
													align='left'
													component='th'
													id={labelId}
													scope='row'
													padding='normal'
												>
													<Grid container>
														<Grid item xs={1}>
															<FolderIcon
																style={{
																	color: '#878787',
																	width: '16px',
																	height: '16px',
																}}
															/>
														</Grid>
														<Grid item xs={11}>
															<Typography
																className={classes.nombre}
																title={row.nombrereal}
															>
																{truncarNombreCompleto(row.nombrereal, '', 30)}
															</Typography>
														</Grid>
													</Grid>
												</TableCell>
											)}
											<TableCell align='start' padding='none'>
												<Typography
													className={classes.origen}
													title={row.creador}
												>
													{isHome
														? truncarNombreCompleto(row.creador, '', 28)
														: row.creador}
												</Typography>
											</TableCell>
											<TableCell align='start' padding='none'>
												<DateSign
													fechaFirma={row.fechaCreacion}
													index={index}
													indexRow={indexRow}
												/>
											</TableCell>
											<TableCell align='start' padding='none'>
												<DateSign
													fechaFirma={row.fechaRecepcion}
													index={index}
													indexRow={indexRow}
												/>
											</TableCell>
											<TableCell
												align={window.innerWidth < 1265 ? 'center' : 'start'}
												padding='none'
											>
												<ViewResign
													index={index}
													indexRow={indexRow}
													isCircuit={row.isCircuit}
													isObserver={true}
												/>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						) : (
							<TableBody>
								<TableCell />
								<TableCell>
									{' '}
									No se encontraron documentos por observar
								</TableCell>
								<TableCell />
								<TableCell />
								<TableCell />
								<TableCell />
							</TableBody>
						)}
					</Table>
				</TableContainer>
			</div>
			<Box
				display='flex'
				justifyContent='space-between'
				alignItems='center'
				width='100%'
				sx={{
					flexDirection: { xs: 'column', sm: 'row' }, // xs para móviles, sm para pantallas más grandes
				}}
			>
				<Box
					component='span'
					onClick={() => {
						handleAction();
					}}
					sx={{
						cursor: 'pointer',
						color: '#181C18',
						marginLeft: { xs: 0, sm: 6 },
						fontSize: '0.87rem',
						textDecoration: 'none',
						'&:hover': {
							color: '#E5682A',
						},
					}}
				>
					Ver más
				</Box>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component='div'
					count={rowsFilter.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					labelRowsPerPage='Filas por página'
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Box>
			<Menu
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={Boolean(orderMenu)}
				anchorEl={orderMenu}
				onClose={() => setOrderMenu(null)}
				getContentAnchorEl={null}
			>
				<MenuItem onClick={() => sort('nombrereal', 'asc')}>A - Z</MenuItem>
				<MenuItem onClick={() => sort('nombrereal', 'desc')}>Z - A</MenuItem>
				<MenuItem onClick={() => sort('fechaCreacion', 'desc')}>
					Más recientes
				</MenuItem>
				<MenuItem onClick={() => sort('fechaCreacion', 'asc')}>
					Más antiguos
				</MenuItem>
			</Menu>
		</>
	);
};

export default PendingToObserve;
