import React, { useState } from 'react';
import {
	Grid,
	makeStyles,
	Button,
	Box,
	IconButton,
	Container,
	Paper,
	Hidden,
	Tooltip,
} from '@material-ui/core';
import agrandar from '../../../assets/img/AgrandarDoc.jpg';
import alejar from '../../../assets/img/AlejarDoc.jpg';
import girarLeft from '../../../assets/img/RotarLeft.jpg';
import girarRight from '../../../assets/img/RotarRight.jpg';
import AddSignature from '../../../assets/img/campofirma.png';
import { useDispatch, useSelector } from 'react-redux';
import siguiente from '../../../assets/img/sig.png';
import atras from '../../../assets/img/ant.png';
import IconManuscrita from '../../../assets/img/ico-manuscrita.png';
import AddIcon from '@material-ui/icons/Add';
import ModalReject from './modales/ModalReject';
import ModalConfirmReject from './modales/ModalConfirmReject';
import ModalUps from './modales/ModalUps';
import ModalFinishedSigning from './modales/ModalFinishedSigning';
import ModalSignatureRejected from './modales/ModalSignatureRejected';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import {
	modalFinished,
	modalMissingSignatures,
	modalReject,
	modalSign,
} from '../../../redux/actions/CircuitoFirmasAction';
import ModalMissingSignatures from './modales/ModalMissingSignatures';
import ModalSign from './modales/ModalSign';
import CanvasDragResize from '../FirmaAvanzada/CanvasDragResize';
import { kbToMb } from '../../../helpers/FilesFunction';
import {
	MAX_MEGAS_FILE_SIZE,
	MAX_MEGAS_FILES_SIZE,
	API_HOSTNAME,
	API_HOSTNAME_AWS,
	LANDING_PAGE_FIRMAYA,
	API_HOSTNAME_GET_CERTIFICATE,
} from '../../../redux/types/ApiConstants';
import { useSnackbar } from 'notistack';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { getListSignature } from '../../../helpers/SignatureUtil';
import { useEffect } from 'react';
import Loading from '../../../components/Loading';
import ModalOtpCF from './modales/ModalOtpCF';
import { getFilesCF } from '../../../redux/actions/SignAction';
import { truncarNombreCompleto } from '../../../helpers/helpNames';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyle = makeStyles({
	boxes: {
		height: '600px',
	},
	flex: {
		display: 'flex',
		justifyContent: 'space-between',
		'@media (max-width: 600px)': {
			flexDirection: 'column', // Apila en columna en pantallas pequeñas
			alignItems: 'flex-start', // Alinea los títulos a la izquierda en pantallas pequeñas
		},
	},
	iconsPdf: {
		display: 'flex',
		justifyContent: 'flex-start',
		padding: '0',
		cursor: 'pointer',
	},
	iconPdf: {
		margin: '0 2px 8px 2px',
		width: '32px',
		height: '20px',
		borderLeft: '2px solid #DFDFDF',
		padding: '0 6px 0 ',
		'@media(max-width:1252px)': {
			pointerEvents: 'none',
			opacity: '0',
		},
	},
	iconSpacing: {
		margin: '0 8px', // Ajusta el margen horizontal según sea necesario
		'@media (max-width: 600px)': {
			fontSize: '1.1rem',
		},
	},
	icon1Pdf: {
		margin: '0 2px 8px 2px',
		width: '32px',
		height: '20px',
		padding: '0 6px 0 ',
		'@media(max-width:1252px)': {
			pointerEvents: 'none',
			opacity: '0',
		},
	},
	size: {
		fontSize: '18px',
		colo: '4F4F4F',
		margin: '8px 0 36px 0',
	},
	parrafo: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '14px',
		color: '#4F4F4F',
		fontFamily: 'mulish',
		// maxWidth: "183px",
		minWidth: '130px',
		margin: '0',
		marginBottom: '16px',
		'@media(max-width:599px)': {
			display: 'none',
		},
	},
	parrafo2: {
		display: 'none',
		justifyContent: 'left',
		alignItems: 'center',
		fontSize: '14px',
		color: '#202427',
		fontFamily: 'mulish',
		// maxWidth: "183px",
		minWidth: '130px',
		margin: '0',
		marginBottom: '16px',
		'@media(max-width:599px)': {
			display: 'flex',
			justifyContent: 'left',
			maxWidth: '599px',
			gap: '10px',
			flexDirection: 'column',
			alignItems: 'baseline',
			marginTop: '20px',
			fontSize: '1rem',
			fontWeight: '700',
		},
	},
	hr: {
		color: '#7B7B7B',
		margin: '0',
		// maxWidth: "183px",
		minWidth: '130px',
		'@media(max-width:599px)': {
			display: 'none',
		},
	},
	div: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '8px',
		padding: '2px 8px',
		border: '1px solid #E3E3E3',
		borderRadius: '5px',
		'@media(max-width:599px)': {
			justifyContent: 'space-around',
			width: '100%',
			gap: '10px',
			marginLeft: '0',
			padding: '12px 8px',
			fontSize: '1.3rem',
			fontWeight: '500',
		},
	},
	listado: {
		fontFamily: 'mulish',
		fontSize: '16px',
		color: '#444444',
		marginTop: '16px',
		'@media(max-width:599px)': { fontSize: '1rem' },
	},
	listado2: {
		fontFamily: 'mulish',
		fontSize: '16px',
		color: '#444444',
		marginTop: '4%',
		'@media(max-width:599px)': {
			fontSize: '1rem',
		},
	},
	btns: {
		display: 'flex',
		gap: '16px',
		alignItems: 'center',
		'@media(max-width:600px)': {
			display: 'none',
		},
	},
	btns2: {
		display: 'none',
		gap: '16px',
		alignItems: 'center',
		'@media(max-width:600px)': {
			gap: '20px',
			flexDirection: 'column-reverse',
			width: '100%',
			display: 'flex',
		},
	},
	btnRechazar: {
		color: '#E55200',
		padding: '8px 24px',
		fontSize: '16px',
		cursor: 'pointer',
		border: '1px solid #E55200',
		borderRadius: '8px',
		backgroundColor: 'transparent',
		fontFamily: 'Mulish',
		fontWeight: '700',
		margin: 0,
		'@media(max-width:600px)': {
			width: '100%',
			padding: '8px 24px',
			fontSize: '1.1rem',
		},
	},
	continue: {
		backgroundColor: '#E55200',
		color: 'white',
		padding: '8px 24px',
		fontSize: '16px',
		cursor: 'pointer',
		fontWeight: '800',
		fontFamily: 'Mulish',
		border: '1px solid #E55200',
		borderRadius: '8px',
		'&:hover': {
			backgroundColor: '#E55200',
		},
		'@media(max-width:600px)': {
			width: '100%',
			padding: '8px 24px',
			fontSize: '1.1rem',
			'& img': {
				width: '24px',
				height: '24px',
			},
		},
	},
	circle: {
		fontSize: '14px',
		fontFamily: 'Mulish',
		width: '24px',
		minWidth: '24px',
		height: '24px',
		borderRadius: '50%',
		display: 'flex',
		color: '#E55200',
		alignItems: 'center',
		justifyContent: 'center',
		border: '1px solid #E55200',
	},
	flexs: {
		display: 'flex',
		alignItems: 'center',
		minWidth: '130px',
		'@media(max-width:599px)': {
			justifyContent: 'center',
		},
	},
	flx: {
		'@media(max-width:599px)': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			minWidth: '130px',
			justifyContent: 'center',
		},
	},
	flexs2: {
		display: 'flex',
		marginTop: '14px',
		alignItems: 'center',
		minWidth: '130px',
		'@media(max-width:599px)': {
			justifyContent: 'start',
		},
	},
	circle2: {
		fontSize: '14px',
		fontFamily: 'Mulish',
		minWidth: '24px',
		maxWidth: '24px',
		maxHeight: '24px',
		minHeight: '24px',
		width: '24px',
		height: '24px',
		borderRadius: '50%',
		display: 'flex',
		color: 'white',
		backgroundColor: '#E55200',
		alignItems: 'center',
		justifyContent: 'center',
		border: '1px solid #E55200',
		fontWeight: '600',
	},
	circleDisabled: {
		fontSize: '14px',
		fontFamily: 'Mulish',
		minWidth: '24px',
		maxWidth: '24px',
		maxHeight: '24px',
		minHeight: '24px',
		width: '24px',
		height: '24px',
		borderRadius: '50%',
		display: 'flex',
		backgroundColor: '#DEDEDE',
		alignItems: 'center',
		justifyContent: 'center',
		fontWeight: '500',
	},
	backgroundTurquoise: {
		backgroundColor: 'transparent',
		width: '25px',
		marginTop: '8px',
	},
	divTurqoise: {
		width: '38px',
		height: '38px',
		backgroundColor: '#FFD984',
		borderTopLeftRadius: '10px',
		borderBottomLeftRadius: '10px',
		alignItems: 'center',
		'@media(max-width:600px)': {
			display: 'flex',
			width: '50px',
			height: '50px',
			fontSize: '1.1rem',
			alignItems: 'center',
			justifyContent: 'center',
		},
	},
	btnCampoFirma: {
		backgroundColor: '#F3F3F3',
		marginBottom: '16px',
		textTransform: 'none',
		// maxWidth: "183px",
		minWidth: '130px',
		height: '40px',
		border: '1px solid #C9C9C9',
		borderRadius: '10px',
		justifyContent: 'space-between',
		fontSize: '14px',
		padding: '0',
		paddingRight: '8px',
		'@media(max-width:600px)': {
			width: '100%',
			height: '50px',
			fontSize: '1.1rem',
		},
	},
	parr: {
		fontSize: '14px',
		color: '#4F4F4F',
		fontFamily: 'mulish',
		wordSpacing: '0.30em',
	},
	miniContainer: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		maxHeight: '600px',
		boxSizing: 'border-box',
		width: '100%',
		'@media(max-width:920px)': {
			display: 'none',
		},
	},
	none: {
		'@media(max-width:959px)': {
			display: 'none',
		},
	},
});

const runTextScript = text => {
	const s = document.createElement('script');
	s.type = 'text/javascript';
	s.async = true;
	s.innerHTML = text;
	document.body.appendChild(s);
	document.body.removeChild(s);
};

const SignerFromMail = props => {
	//This component receives data by props: It is the
	//data of the signers and the files of the circuit "its a object "
	// signers: an ordered list of signers "its a array "
	const { data, firmantes, setStateFirmas, history, archivos } = props;
	const classes = useStyle();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const states = useSelector(state => state.CircuitoFirmasReducer);
	const idPdfContainer = 'tttttcontainer';
	const modalRejects = states.modalReject;
	const modalConfirmRejects = states.modalConfirmReject;
	const modalUpss = states.modalUps;
	const modalSignatureRejects = states.modalSignatureReject;
	const modalMissingSignature = states.modalMissingSignatures;
	const modalSigns = states.modalSign;
	const [imgSign, setImgSign] = useState(null);
	const [currentDoc, setCurrentDoc] = useState(0);
	const [imageSignature, setImageSignature] = useState([]);
	const [isReSign, setReSign] = useState(false);
	const [predefinedSource, setPredefinedSource] = useState(false);
	const [verManuscritaDisable, setVerManuscritaDisable] = useState(false);
	const [configQR, setConfigQR] = useState({
		title: '',
		url: false,
		tPaginas: false,
	});
	const [sendData, setSendData] = useState({
		signerClientId: null,
		ruta: props.ruta,
		firmaelectronica: null,
		key: null,
		documents: [],
	});
	const [signPredefined, setSignPredefined] = useState(0);
	const [addDataCert, setAddDataCert] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [isDigitalSign, setDigitalSign] = useState(false);
	const [isQR, setQR] = useState(false);
	const [showComplete] = useState(true);
	const [digitalImg, setDigitalImg] = useState(null);
	const manuscrita = 'manuscrita';
	const digital = 'digital';
	const estampa = 'estampa';
	const marcaAgua = 'marcaagua';
	const qr = 'qr';
	// const firmasPredefinidas = data.documentos
	const [isStamp, setStamp] = useState(false);
	const [isMarcaAgua, setMarcaAgua] = useState(false);
	const [numerodocumentos, setNumeroDocumentos] = useState(0);
	const [scale, setScale] = useState(0.8);
	const [verManuscrita, setVerManuscrita] = useState(false);
	const [typeImg, setTypeImg] = useState(manuscrita);
	const [tieneFirmaDigital, setTieneFirmaDigital] = useState(false);
	const firmado = states.modalFinished;
	const idThumbnailsContainer = 'idThumbnailsContainer';
	const [firmas, setFirmas] = useState([]);
	const [docs, setDocs] = useState(archivos);
	const [loading, setLoading] = useState(false);
	const firmaLibre = data.firmante.firmaLibre;
	const firmaManuscrita = data.firmante.firmaManuscrita;
	const correoCreador = data.circuito.correoCreador;
	const token = states.tokenGenerico;
	const [statusModalSign, setStatusModalSign] = useState(false);
	const [sendElecSign, setSendElecSign] = useState(0);
	const [ultimaPosicion, setUltimaPosicion] = useState(false);
	const [firmasNecesarias, setFirmasNecesarias] = useState(0);
	const [firmasHechas, setFirmasHechas] = useState(0);
	const [todosLosCampos, setTodosLosCampos] = useState(false);
	const [aplicados, setAplicados] = useState(true);
	const [justBringImage, setJustBringImage] = useState(false);
	const [currentPositionSign, setCurrentPositionSign] = useState('');
	const [totaPositionSign, setTotaPositionSign] = useState([]);
	const [campoFirma, setCampoFirma] = useState(false);
	const [mxSignOnScreen, setMxSignOnScreen] = useState(1);
	const [mnSignOnScreen, setMnSignOnScreen] = useState(1);
	const [disabledBtnsPdf, setDisabledBtnsPdf] = useState(false);
	const [signFields, setSignFields] = useState([]);
	const [numeroFaltante, setNumeroFaltante] = useState(1);
	const [errorS3, setErrorS3] = useState('');
	const [lineal, setLineal] = useState(false);
	const [redireccionar, setRedireccionar] = useState(false);
	const [mobileNumber, setMobileNumber] = useState('');
	const [services, setServices] = useState({
        "sms": false,
        "whatsapp": false
    });
	const [sendOption, setSendOption] = useState('');
	const [targetId, setTargetId] = useState('');
	const [vencimiento, setVencimiento] = useState({
			vence: false,
			fechaVence: data.circuito.fechaVence
	})

	useEffect(() => {
		if (data) {
			setLineal(data.circuito.tipoCircuito === 'lineal' ? true : false);
			setVencimiento({
				vence: data.circuito.vence,
				fechaVence: data.circuito.fechaVence
			});
		}
	}, []);

	const handleMobileNumberChange = newNumber => {
		setMobileNumber(newNumber);
	};

	const onSendOptionChange = newNumber => {
		setSendOption(newNumber);
	};

		const fetchCertificates = async () => {
		  try {
			const response = await fetch(API_HOSTNAME_GET_CERTIFICATE, {
			  method: 'GET',
			  headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`, 
			  },
			});
			if (!response.ok) {
			  throw new Error('Network response was not ok');
			}
			const data = await response.json();
			const servicios = data.services;
			setServices(servicios);
		  } catch (error) {
			console.error('Error fetching data:', error);
		  }
		};

	useEffect(() => {
		document.body.className = 'bodyMiddle';
		let count = 0;
		let documentos = [];
		let documentosCargados = 0;
		setFirmas([]);
		runTextScript(
			"initPdfComponent('" +
				idPdfContainer +
				"','" +
				idThumbnailsContainer +
				"')"
		);
		if (docs?.length > 0) {
			docs.map(doc => {
				const reader = new FileReader();
				reader.numDoc = count;
				reader.filename = doc.name;
				reader.nombre = doc.nombre;
				reader.readAsDataURL(doc);
				reader.onload = event => {
					var document = new Object();
					document.uuid = event.target.numDoc;
					document.fileName = event.target.filename;
					document.nombre = doc.nombre; //event.target.nombre;
					document.base64 = event.target.result.replace(
						'data:' + doc.type + ';base64,',
						''
					);
					document.typeSignature = [];
					document.listSigns = getListSignature(document.base64);
					documentos.push(document);
					documentosCargados++;

					if (documentosCargados >= docs.length) {
						documentos.sort((doc1, doc2) =>
							doc1.uuid > doc2.uuid ? 1 : doc1.uuid < doc2.uuid ? -1 : 0
						);
						let newSenData = { ...sendData };
						newSenData.documents = documentos;
						if (!isReSign) setSendData(newSenData);
						runTextScript(
							'loadDocuments(' +
								scale +
								",'" +
								JSON.stringify(documentos) +
								"')"
						);
					}
					setNumeroDocumentos(documentosCargados);
				};
				count++;
			});
		}
		setLoading(false);
		setTimeout(() => {
			setDisabledBtnsPdf(false);
		}, 1500);
	}, [scale, docs]);

	useEffect(() => {
		// Filtrar los firmantes que no son observadores
		const firmantesNoObservadores = data.inFirmantes.filter(
			firmante => firmante.esObservador === 0
		);

		// Verificar si la posición del firmante actual es la última entre los firmantes no observadores
		if (firmantesNoObservadores.length + 1 === data.firmante.posicion) {
			setUltimaPosicion(true);
		} else {
			setUltimaPosicion(false);
		}
	}, [data.inFirmantes, data.firmante.posicion]);



	useEffect(() => {
		// Recorrer el array de documentos para contar las ubicaciones que requieren firma
		let cantidadFirmasNecesarias = 0;
		data.documentos.forEach(documento => {
			if (documento.manuscrita && documento.manuscrita.length > 0) {
				cantidadFirmasNecesarias += documento.manuscrita.length;
			}
		});
		if (cantidadFirmasNecesarias > 0) {
			setCampoFirma(true);
		}
		setFirmasNecesarias(cantidadFirmasNecesarias);
		setNumeroFaltante(cantidadFirmasNecesarias);
	}, []);

	useEffect(() => {
		const contador = signFields.reduce((total, item) => {
			if (item.source === 'campoVacio') {
				return total + 1;
			} else {
				return total;
			}
		}, 0);
		setNumeroFaltante(contador);
	}, [signFields]);

	const enviarOTP = async () => {
		if (mobileNumber !== '' && sendOption == 'mobile') {
			let myHeaders = new Headers();
			myHeaders.append('Authorization', 'Bearer ' + token);
			myHeaders.append('Content-Type', 'application/json');
			const formattedMobileNumber = mobileNumber.replace(
				/(\d{2})(\d+)/,
				'$1-$2'
			);
			let raw = JSON.stringify({
				celular: mobileNumber,
				TextoOTP:
					'Firmaya te ha enviado tu codigo para tu firma electronica, por favor, no lo compartas con nadie: ',
			});

			let requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw,
				redirect: 'follow',
			};
			setLoading(true);

			try {
				const response = await fetch(
					`${API_HOSTNAME_AWS}sendOtp/api/types/Send`,
					requestOptions
				);
				const respuesta = await response.json();
				if (respuesta.http === 200) {
					return true;
				} else {
					enqueueSnackbar(
						'Ocurrio un error.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.',
						{
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						}
					);
					setLoading(false);
					throw Error(respuesta.result.information);
				}
			} catch (error) {
				console.error(error);
				return false;
			}
		}
		if (mobileNumber !== '' && sendOption == 'WhatsApp') {
			let myHeaders = new Headers();
			myHeaders.append('Authorization', 'Bearer ' + token);
			myHeaders.append('Content-Type', 'application/json');
			const [identificator, phoneNumber] = mobileNumber.split('-');
			let raw = JSON.stringify({
				phoneNumber: phoneNumber,
				identificator: identificator,
				message: 'Tu codigo OTP es: ',
			});

			let requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw,
				redirect: 'follow',
			};
			setLoading(true);

			try {
				const response = await fetch(
					`${API_HOSTNAME_AWS}sendOtp/api/whatsapp/Send`,
					requestOptions
				);
				const respuesta = await response.json();
				if (respuesta.http === 200) {
					return true;
				} else if (respuesta.code === 'A065') {
					enqueueSnackbar('Número de celular no valido', {
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
					setLoading(false);

					throw Error(respuesta.descripcionRespuesta);
				} else {
					enqueueSnackbar(
						'Ocurrio un error.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.',
						{
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						}
					);
					setLoading(false);
					throw Error(respuesta.result.information);
				}
			} catch (error) {
				console.error(error);
				return false;
			}
		}

		if (mobileNumber == '' && sendOption == 'email') {
			let myHeaders = new Headers();
			myHeaders.append('Authorization', 'Bearer ' + token);
			myHeaders.append('Content-Type', 'application/json');

			let raw = JSON.stringify({
				email: data.firmante.correo,
				celular: '',
				aplicacion: '',
			});

			let requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw,
				redirect: 'follow',
			};
			setLoading(true);

			try {
				const response = await fetch(
					`${API_HOSTNAME}/api/SendEmail/SendMailOtp`,
					requestOptions
				);
				const respuesta = await response.json();
				if (respuesta.statusCode === 200) {
					return true;
				} else {
					enqueueSnackbar(
						'Ocurrio un error.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.',
						{
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						}
					);
					setLoading(false);
					throw Error(respuesta.result.information);
				}
			} catch (error) {
				console.error(error);
				return false;
			}
		}
	};

	//Here is the functionality when signing the document, first it will check if signatures are needed in the document, if so, it will show a modal indicating it, if no signatures are needed it will proceed to execute the signing service
	const solicitarCodeOTP = () => {
		if (numeroFaltante > 0) {
			dispatch(modalMissingSignatures(true));
		} else {
			if (firmaLibre) {
				const array = sendData.documents;
				const totalTypeSignatures = array.reduce((total, obj) => {
					return total + obj.typeSignature.length;
				}, 0);
				if (totalTypeSignatures > 0) {
					const ejecutar = async () => {
						const envioDeOtp = await enviarOTP();
						if (envioDeOtp) {
							setSendElecSign(1);
							setStatusModalSign(true);
						} else {
							setStatusModalSign(false);
							setStatusModalSign(true);
						}
					};
					ejecutar();
				} else {
					enqueueSnackbar('Debes insertar al menos una firma manuscrita', {
						variant: 'info',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
				}
			} else {
				const ejecutar = async () => {
					const envioDeOtp = await enviarOTP();
					if (envioDeOtp) {
						setSendElecSign(1);
						setStatusModalSign(true);
					} else {
						setStatusModalSign(false);
						setMobileNumber('');
						setSendOption('email');
						setStatusModalSign(true);
					}
				};
				ejecutar();
			}
		}
	};

	const botonFirmar = async() => {
		await fetchCertificates()
		if (firmaLibre) {
			const array = sendData.documents;
			const totalTypeSignatures = array.reduce((total, obj) => {
				return total + obj.typeSignature.length;
			}, 0);
			if (totalTypeSignatures > 0) {
				setSendElecSign(3);
				setStatusModalSign(true);
			} else {
				enqueueSnackbar('Debes insertar al menos una firma manuscrita', {
					variant: 'info',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			}
		} else {
			if (numeroFaltante > 0) {
				dispatch(modalMissingSignatures(true));
			} else {
				setSendElecSign(3);
				setStatusModalSign(true);
			}
		}
	};

	const onMouseUp = oImg => {
		let offX;
		let offY;
		let uuid;
		let centerImgX = oImg._posX + oImg._imgWidth / 2;
		let centerImgY = oImg._posY + oImg._imgHeight / 2;
		let pdfPages = document.getElementsByClassName('pdf-canvas');
		let oPage = {
			top: null,
			left: null,
			width: null,
			height: null,
		};
		let oFirma = {
			id: oImg._id,
			signatureType: oImg._type,
			signPredefined: 0,
			addDataCert: 0,
			base64Image: null,
			tpag: configQR.tPaginas,
			url: configQR.url,
			title: configQR.title,
			posXY: {
				pageNumber: null,
				x: null,
				y: null,
				width: null,
				height: null,
				pageWidth: null,
				pageHeight: null,
			},
		};
		for (let a = 0; a < pdfPages.length; a++) {
			oPage.top = pdfPages[a].offsetTop;
			oPage.left = pdfPages[a].offsetLeft;
			if (window.innerWidth < 1253) {
				oPage.pageWidth = pdfPages[a].scrollWidth;
				oPage.pageHeight = pdfPages[a].scrollHeight;
			} else {
				oPage.pageWidth = pdfPages[a].width;
				oPage.pageHeight = pdfPages[a].height;
			}
			if (
				centerImgY > oPage.top &&
				centerImgY < oPage.top + oPage.pageHeight &&
				centerImgX > oPage.left &&
				centerImgX < oPage.left + oPage.pageWidth
			) {
				offX = pdfPages[a].offsetLeft;
				offY = pdfPages[a].offsetTop;

				uuid = pdfPages[a].getAttribute('data-doc');
				oFirma.idimg = oImg._id;
				oFirma.base64Image =
					oImg._type === qr
						? 'iVBORw0KGgoAAAANSUhEUgAAAIQAAACEAQMAAABrihHkAAAABlBMVEX///8AAABVwtN+AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAyElEQVRIie2Uyw3DMAxDtQH335IbsKKdBO2lRcQeIxiC/A6M9UvVYz9MEu2PYEzak6DIFc+JYzTbMCb1ByJCMfFBqT5yv09c8GUfvbhPbND5ujmxqD/A6qoFRHCGLv6V6YisidISVUZQ6OOEKyD9stZtR4AB6YIBHigPZ0B8bc2WPjOdka0rgVdPR6Tv3P8RVUK2evmJYEC8IHDVmZE1kNouIl5c35UT/7B7fY/U58Tt49q7gLjwlM61mxJXvmMn+taL++SxL/YC+rrZ93htqY0AAAAASUVORK5CYII='
						: oImg._imgBase64.replace('data:image/png;base64,', '');
				oFirma.signPredefined = signPredefined;
				oFirma.addDataCert = addDataCert;
				oFirma.posXY.pageNumber = pdfPages[a].getAttribute('data-pag');
				oFirma.posXY.pageHeight = oPage.pageHeight;
				oFirma.posXY.pageWidth = oPage.pageWidth;
				oFirma.posXY.x = oImg._posX - offX;
				oFirma.posXY.y = oImg._posY - offY;
				oFirma.posXY.width = oImg._imgWidth;
				oFirma.posXY.height = oImg._imgHeight;
				setCurrentPage(pdfPages[a].getAttribute('data-pag'));
				break;
			}
		}
		for (let i = 0; i < sendData.documents.length; i++) {
			const documento = sendData.documents[i];
			const firmas = documento.typeSignature;

			// Verificar si alguna firma tiene el mismo id que oFirma
			for (let j = 0; j < firmas.length; j++) {
				const firma = firmas[j];
				if (firma.id === oFirma.id) {
					// Eliminar la firma del documento actual
					firmas.splice(j, 1);

					// Como encontramos y manejamos la firma, salimos de la función
				}
			}

			// Verificar si el documento actual es el documento de destino
			if (documento.uuid === Number(uuid)) {
				// Agregar la firma al documento de destino
				documento.typeSignature.push(oFirma);
			}
		}

		if (
			oImg._posX - offX + oImg._imgWidth > oFirma.posXY.pageWidth ||
			oImg._posX - offX === 'NAN' ||
			oImg._posX - offX < 0 ||
			oImg._posY - offY + oImg._imgHeight > oFirma.posXY.pageHeight ||
			oImg._posY - offY < 0
		) {
			enqueueSnackbar(
				'La posicion del campo de firma seleccionado supera los limites de la pagina actual',
				{
					variant: 'info',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				}
			);
		}

		let totaElem = document.getElementsByClassName('pdf-canvas');
		let acumData = 0;
		let listPost = [];
		for (var i = 0; i < totaElem.length; i++) {
			let resPo = totaElem[i].id.split('_');
			let docv = Number(resPo[1]);
			let pagv = Number(resPo[2]);
			let arrd = { d: docv, p: pagv, v: acumData }; //Identifico en donde empieza cada pg
			acumData += totaElem[i].height;
			listPost.push(arrd);
		}

		let fProps = {
			source: 'libre',
			pageWidth: oFirma.posXY.pageWidth,
			idReSign: oImg._id,
			posY: oImg._posY,
			posX: oImg._posX,
			reSignWidth: oImg._imgWidth,
			reSignHeigth: oImg._imgHeight,
			imgRoute: oImg._imgBase64,
			onclick: assignSignatureImage,
			typeImg: oImg._type,
			idPdfContainer: idPdfContainer,
			deleteSign: deleteSign,
		};

		let objetoExistente = false;

		if (signFields.length > 0) {
			objetoExistente = signFields.find(
				obj => obj.idReSign === fProps.idReSign
			);
		}
		if (objetoExistente) {
			const nuevosSignFields = signFields.map(obj =>
				obj.idReSign === fProps.idReSign ? fProps : obj
			);
			setSignFields(nuevosSignFields);
		} else {
			setSignFields([...signFields, fProps]);
		}
		refreshDoc();
	};

	function deleteSign(e, idDelete) {
		let id;
		idDelete ? (id = idDelete) : (id = e.target.id.replace('btn-', ''));
		let newSendData = [];
		let listDocs = [];
		newSendData.signerClientId = sendData.signerClientId;
		newSendData.key = sendData.key;
		document.getElementById('container-' + id).remove();
		sendData.documents.map(document => {
			let listSignatures = [];
			document.typeSignature.map(type => {
				if (type.idimg) {
					if (type.idimg != id) {
						listSignatures.push(type);
					}
				} else {
					listSignatures.push(type);
				}
			});
			document.typeSignature = listSignatures;
			listDocs.push(document);
		});

		const newSignFields = signFields.filter(
			field => field.idReSign !== idDelete
		);
		setSignFields(newSignFields);

		newSendData.documents = listDocs;
		setSendData(newSendData);
		setVerManuscritaDisable(false);
		refreshDoc();
	}

	const aplicarFirma = (state, type) => {
		setReSign(false);
		let newSenData = { ...sendData };
		let cantidad = 0;
		if (type == marcaAgua && state === true) {
			newSenData.documents.map(doc => {
				cantidad += doc.listSigns.length;
			});
		}
		if (cantidad == 0) {
			if (type == manuscrita) {
				if (verManuscritaDisable) {
					return;
				}
				let docAct = null;
				let listMini = document.getElementsByClassName('pdf-canvas-mini');
				if (listMini) {
					docAct = Array.from(listMini)
						.find(mini => mini.classList.contains('active-sheet'))
						.getAttribute('data-documento');
				}
				setTypeImg(manuscrita);
				let doc = sendData.documents[docAct];
				if (doc.uuid == docAct) {
					if (doc.listSigns !== null && doc.listSigns.length > 0) {
						setDigitalSign(true);
						setTieneFirmaDigital(true);
					} else {
						setTieneFirmaDigital(false);
					}
				}
				setVerManuscrita(state);
				return;
			}
			let newFirmas = [];
			let firma;
			let oFirma = {
				signatureType: type,
				signPredefined: 0,
				addDataCert: 0,
			};
			let existe = false;
			if (state) {
				for (var a in newSenData.documents) {
					existe = false;
					for (var b in newSenData.documents[a].typeSignature) {
						firma = newSenData.documents[a].typeSignature[b];
						if (firma.signatureType == type) {
							existe = true;
						}
					}
					if (!existe) {
						newSenData.documents[a].typeSignature.push(oFirma);
					}
				}
			} else {
				for (var a in newSenData.documents) {
					newFirmas = [];
					for (var b in newSenData.documents[a].typeSignature) {
						firma = newSenData.documents[a].typeSignature[b];
						if (firma.signatureType == type) {
							continue;
						}
						newFirmas.push(firma);
					}
					newSenData.documents[a].typeSignature = newFirmas;
				}
			}
			setSendData(newSenData);
			switch (type) {
				case manuscrita:
					setVerManuscrita(state);
					break;
				case estampa:
					setStamp(state);
					break;
				case marcaAgua:
					setMarcaAgua(state);
					break;
			}
		} else {
			if (type == marcaAgua) {
				enqueueSnackbar(
					'Alguno de los documentos cargados tiene una firma por lo tanto no se le puede agregar marca de agua',
					{
						variant: 'info',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
			}
		}
	};

	const refreshDoc = e => {
		let docAct = null;
		let listMini = document.getElementsByClassName('pdf-canvas-mini');
		if (listMini) {
			docAct = Array.from(listMini)
				.find(mini => mini.classList.contains('active-sheet'))
				.getAttribute('data-documento');
		}
		if (e && e.target && e.target.getAttribute('documento')) {
			docAct = e.target.getAttribute('documento');
		}
		if (docAct != null) {
			setVerManuscritaDisable(false);
			setDigitalSign(false);
			setQR(false);
			let doc = sendData.documents[docAct];
			if (doc.listSigns !== null && doc.listSigns.length > 0) {
				for (var j = 0; j < doc.typeSignature.length; j++) {
					switch (doc.typeSignature[j].signatureType) {
						case manuscrita:
							setVerManuscritaDisable(true);
							setDigitalSign(true);
							break;
						case qr:
							setQR(true);
							break;
					}
				}
			}
			if (doc.typeSignature.length > 0) {
				for (var i = 0; i < doc.typeSignature.length; i++) {
					switch (doc.typeSignature[i].signatureType) {
						case digital:
							setDigitalSign(true);
							break;
						case qr:
							setQR(true);
							break;
					}
				}
			}
		}
		aplicarCampos();
	};

	// this is the functionality for when another file is selected in the mini list
	const handlerChange = async () => {
		let archivo = document.getElementById('Upload').files;
		if (archivo[0] != null) {
			await getPageNumber(archivo);
			var fileSizeMb = Math.round(archivo[0].size / 1024);
			var maxSizeMb = Math.round((MAX_MEGAS_FILE_SIZE * 1000000) / 1024);
			archivo[0].sizeBytes = archivo[0].size;
			var extension = '.' + archivo[0].name.split('.').pop();
			if (!extension.toLowerCase().includes('.pdf')) {
				enqueueSnackbar(
					`El archivo ${archivo[0].name} no es permitido, los tipos de archivos permitidos son: .pdf`,
					{
						style: { whiteSpace: 'pre-line' },
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
			} else if (docs.length == 10) {
				enqueueSnackbar(
					`La cantidad de archivos supera el máximo permitido de 10 documentos.`,
					{
						style: { whiteSpace: 'pre-line' },
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
			} else {
				if (fileSizeMb > maxSizeMb) {
					enqueueSnackbar(
						`El tamaño del archivo ${
							archivo[0].name
						} supera el máximo permitido.\nTamaño limite de ${kbToMb(
							MAX_MEGAS_FILE_SIZE * 1000000
						)}`,
						{
							style: { whiteSpace: 'pre-line' },
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						}
					);
				} else {
					let dropedSize = 0;
					docs.map(file => {
						var sizekiloByte = Math.round(file.size / 1024);
						dropedSize += sizekiloByte;
					});
					let maxFilesSizeMb = Math.round(
						(MAX_MEGAS_FILES_SIZE * 1000000) / 1000
					);
					dropedSize += fileSizeMb;
					if (dropedSize > maxFilesSizeMb) {
						enqueueSnackbar(
							`El tamaño total de los archivos supera el máximo permitido.\nTamaño limite de ${kbToMb(
								MAX_MEGAS_FILES_SIZE * 1000000
							)}`,
							{
								style: { whiteSpace: 'pre-line' },
								variant: 'error',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
							}
						);
					} else {
						var results = docs.filter(function (doc) {
							return doc.name == archivo[0].name;
						});
						var existe = results.length > 0 ? results[0] : null;
						if (existe != null) {
							enqueueSnackbar(
								`El archivo ${archivo[0].name} se encuentra actualmente agregado`,
								{
									style: { whiteSpace: 'pre-line' },
									variant: 'error',
									anchorOrigin: {
										vertical: 'top',
										horizontal: 'right',
									},
								}
							);
						} else {
							var filesList = [];
							props.files.map(file => {
								filesList.push(file);
							});
							var newdoc = docs;
							newdoc.push(archivo[0]);
							filesList.push(archivo[0]);
							props.setFiles(filesList);
							setDocs(newdoc);
							document.getElementById('Upload').files = null;
							// updateDocuments();
						}
					}
				}
			}
		}
	};
	async function getPageNumber(pFiles) {
		var validFiles = [];
		for await (const pFile of pFiles) {
			if (pFile.numPages === undefined) {
				if ('.' + pFile.name.split('.').pop() == '.pdf') {
					try {
						const arrayBuffer = await readFile(pFile);
						const pdf = await PDFDocument.load(arrayBuffer);
						pFile.numPages = pdf.getPages().length;
						validFiles.push(pFile);
					} catch (error) {
						enqueueSnackbar(
							`El archivo ${pFile.name} está protegido y no es posible hacerle cambios`,
							{
								style: { whiteSpace: 'pre-line' },
								variant: 'error',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
							}
						);
					}
				} else {
					pFile.numPages = 0;
					validFiles.push(pFile);
				}
			}
		}
	}
	const readFile = file => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
			reader.readAsArrayBuffer(file);
		});
	};

	//This is the button that contains the button to enlarge or reduce the document; the buttons to rotate the document are disabled, for the moment they will not be implemented
	const BotoneraEditar = props => {
		const reSign = async (scale, mode) => {
			props.reSignImage(scale, mode);
		};

		const zoomIn = () => {
			const doc = document.querySelector('.active-sheet');
			const idDoc = doc?.getAttribute('documento');
			const page = doc?.getAttribute('data-page');
			const pdfDoc = document.getElementById(`page_${idDoc}_${page}`);
			if (!disabledBtnsPdf) {
				setDisabledBtnsPdf(true);
				let newScale = 0;
				if (props.scale + 0.2 > 1.2) {
					setDisabledBtnsPdf(false);
					return;
				} else newScale = Math.round((props.scale + 0.2) * 100) / 100;
				reSign(newScale, true);
			}
		};

		const zoomOut = () => {
			const doc = document.querySelector('.active-sheet');
			const idDoc = doc.getAttribute('documento');
			const page = doc.getAttribute('data-page');
			const pdfDoc = document.getElementById(`page_${idDoc}_${page}`);
			if (!disabledBtnsPdf) {
				setDisabledBtnsPdf(true);
				let newScale = 0;
				if (props.scale - 0.2 < 0.7) {
					setDisabledBtnsPdf(false);
					return;
				} else newScale = Math.round((props.scale - 0.2) * 100) / 100;
				reSign(newScale, false);
			}
		};

		return (
			<Box className={classes.iconsPdf}>
				<img src={agrandar} className={classes.icon1Pdf} onClick={zoomIn} />
				<img src={alejar} className={classes.iconPdf} onClick={zoomOut} />
				{/* <img src={girarRight} className={classes.iconPdf} />
                <img src={girarLeft} className={classes.iconPdf} /> */}
			</Box>
		);
	};

	const reSignImage = (newScale, mode) => {
		setScale(newScale);
		setReSign(true);
		let newImageSignature = [];

		signFields.forEach(firmante => {
			let datComp = document.getElementById(idPdfContainer);
			let containerWidth = datComp.clientWidth;
			let prevPageWidth = firmante.pageWidth;
			let pageWidth = mode
				? (firmante.pageWidth / scale) * (scale + 0.2)
				: (firmante.pageWidth / scale) * (scale - 0.2);

			let margValue = containerWidth - pageWidth > 0 ? false : true;
			const posYWithoutMargin = firmante.posY - (firmante.position * 2 - 1);
			const newSignature = {
				pageWidth: pageWidth,
				idPdfContainer: idPdfContainer,
				onclick: assignSignatureImage,
				isFieldempty: true,
				isReSign: true,
				deleteSign: firmante.source === 'libre' && deleteSign,
				reSignWidth: mode
					? (firmante.reSignWidth / scale) * (scale + 0.2)
					: (firmante.reSignWidth / scale) * (scale - 0.2),
				reSignHeigth: mode
					? (firmante.reSignHeigth / scale) * (scale + 0.2)
					: (firmante.reSignHeigth / scale) * (scale - 0.2),
				idReSign: firmante.idReSign,
				posX: margValue
					? mode
						? ((firmante.posX -
								(containerWidth - prevPageWidth > 0
									? containerWidth - prevPageWidth
									: 0) /
									2) /
								scale) *
						  (scale + 0.2)
						: (firmante.posX / scale) * (scale - 0.2) -
						  (containerWidth - prevPageWidth > 0
								? containerWidth - prevPageWidth
								: 0) /
								2
					: mode
					? ((firmante.posX - (containerWidth - prevPageWidth) / 2) / scale) *
					  (mode ? scale + 0.2 : scale - 0.2)
					: (firmante.posX / scale) * (scale - 0.2) +
					  (containerWidth - pageWidth) / 2,
				posY: mode
					? (posYWithoutMargin / scale) * (scale + 0.2) +
					  (firmante.position * 2 - 1)
					: (posYWithoutMargin / scale) * (scale - 0.2) +
					  (firmante.position * 2 - 1),
				imgRoute: firmante.source ? firmante.imgRoute : AddSignature,
				typeImg: firmante.source ? firmante.typeImg : 'png',
				colorFirmante:
					firmante.source === 'campoVacio' ? '#84ffe7' : 'transparent',
			};
			Object.assign(firmante, newSignature);
			newImageSignature.push(newSignature);
		});

		setImageSignature(newImageSignature);
		newImageSignature.forEach(signature => {
			setImgSign(signature.imgRoute);
		});
	};

	//Carga la imagen de acuerdo a la seleccion por campo o por todos
	const setSignImage = (imgB64, predefined) => {
		if (justBringImage) {
			if (todosLosCampos) {
				const modificados = signFields.map(field => {
					if (totaPositionSign.includes(field.idReSign)) {
						return {
							...field,
							typeImg: 'png',
							imgRoute: imgB64,
							colorFirmante: 'transparent',
							source: 'campoFirmado',
						};
					}
					return field;
				});

				setSignFields(modificados);
				replaceImageField(totaPositionSign, modificados);
			} else {
				const modificados = signFields.map(field => {
					if (field.idReSign === currentPositionSign) {
						return {
							...field,
							typeImg: 'png',
							imgRoute: imgB64,
							colorFirmante: 'transparent',
							source: 'campoFirmado',
						};
					}
					return field;
				});
				setSignFields(modificados);
				replaceImageField([currentPositionSign], modificados);
			}
		} else {
			if (predefined) {
				setPredefinedSource(true);
			}
			let docAct = null;
			let listMini = document.getElementsByClassName('pdf-canvas-mini');
			if (listMini) {
				docAct = Array.from(listMini)
					.find(mini => mini.classList.contains('active-sheet'))
					.getAttribute('data-documento');
			}
			let doc = sendData.documents[docAct];
			if (doc.listSigns !== null && doc.listSigns.length > 0) {
				setVerManuscritaDisable(true);
			}
			setImageSignature([
				{
					reSignWidth: 0,
					reSignHeigth: 0,
					idReSign: '',
					posX: 0,
					posY: 0,
					imgRoute: imgB64,
					typeImg: typeImg,
					deleteSign: deleteSign,
				},
			]);
			setVerManuscrita(false);
			setImgSign(imgB64);
		}
	};

	const firmarConCampos = imgB64 => {
		if (firmasNecesarias >= 1 && todosLosCampos) {
			//recorrer el array de firmas para asignar la imagen modificar el sendData, cambiar el setFirmasHechas al total
			//Cada documento debe llevar el typeSignature con las posiciones que traemos del backend
			let newSenData = { ...sendData };
			let array1 = newSenData.documents;
			let array2 = data.documentos;
			let totalFirmas = 0; // inicializa la variable totalFirmas en cero
			for (let i = 0; i < array1.length; i++) {
				for (let j = 0; j < array2.length; j++) {
					if (
						array1[i].nombre === array2[j].nombre &&
						array2[j].manuscrita.length > 0
					) {
						array1[i].typeSignature = [];
						for (let k = 0; k < array2[j].manuscrita.length; k++) {
							let oFirma = {
								id: 'cvSign_' + new Date().getTime() + '_' + i + j + k,
								signatureType: 'manuscrita',
								signPredefined: 0,
								addDataCert: 0,
								base64Image: imgB64.replace('data:image/png;base64,', ''),
								tpag: false,
								url: false,
								title: '',
								posXY: {
									pageNumber: array2[j].manuscrita[k].pageNumber,
									x: array2[j].manuscrita[k].eje_x,
									y: array2[j].manuscrita[k].eje_y,
									width: array2[j].manuscrita[k].ancho,
									height: array2[j].manuscrita[k].alto,
									pageWidth: array2[j].manuscrita[k].pageWidth,
									pageHeight: array2[j].manuscrita[k].pageHeight,
								},
							};
							array1[i].signatureType = 'manuscrita';
							array1[i].typeSignature.push(oFirma);
						}
						totalFirmas += array1[i].typeSignature.length; // suma la cantidad de firmas en el objeto actual a la variable totalFirmas
					}
				}
			}
			setFirmasHechas(totalFirmas);
			newSenData.documents = array1;
		} else if (firmasNecesarias >= 1 && !todosLosCampos) {
			// Aplicar la imagen en el sendData, cambiar el setFirmasHechas a +1
			let newSenData = { ...sendData };
			let array1 = newSenData.documents;
			let array2 = data.documentos;
			// Creamos un nuevo array para almacenar todas las manuscritas.
			const manuscritasTotales = [];
			for (let i = 0; i < array2.length; i++) {
				const documentoActual = array2[i];
				// Si el objeto actual tiene propiedades "manuscrita" y su longitud es mayor a cero, agregamos todas las manuscritas al array "manuscritasTotales"
				if (
					documentoActual.manuscrita &&
					documentoActual.manuscrita.length > 0
				) {
					manuscritasTotales.push(...documentoActual.manuscrita);
				}
			}
			setTimeout(() => {
				// Extraer los valores de x1 y x2
				const [_, x1, x2] = currentPositionSign
					.split('_')
					.map(x => parseInt(x));
				const objArray2 = array2.find(obj => obj.orden === x1);
				const objArray1 = array1.find(obj => obj.nombre === objArray2.nombre);
				objArray1.typeSignature.push({
					id: 'cvSign_' + new Date().getTime(),
					signatureType: 'manuscrita',
					signPredefined: 0,
					addDataCert: 0,
					base64Image: imgB64.replace('data:image/png;base64,', ''),
					tpag: false,
					url: false,
					title: '',
					posXY: {
						pageNumber: manuscritasTotales[x2 - 1].pageNumber,
						x: manuscritasTotales[x2 - 1].eje_x,
						y: manuscritasTotales[x2 - 1].eje_y,
						width: manuscritasTotales[x2 - 1].ancho,
						height: manuscritasTotales[x2 - 1].alto,
						pageWidth: manuscritasTotales[x2 - 1].pageWidth,
						pageHeight: manuscritasTotales[x2 - 1].pageHeight,
					},
				});
			}, 10);
			newSenData.documents = array1;
			if (firmasNecesarias !== firmasHechas) {
				setFirmasHechas(prevFirmasHechas => prevFirmasHechas + 1);
			}
		}
		setSignImage(imgB64, false);
	};

	//Se lanza el modal para cargar la imagen de firma
	const assignSignatureImage = ind => {
		if (ind.target.tagName === 'CANVAS') {
			setTargetId(ind.target.id);
			let firId = ind.target.id;
			setCurrentPositionSign(firId);
			setJustBringImage(true);
			dispatch(modalSign(true));
		}
	};
	//Se asigna una imagen a uno o varios campos de firma disponibles
	function replaceImageField(idRepl, modificados) {
		if (idRepl.length == 1) {
			document.getElementById(`container-${idRepl.flat()}`).remove();

			const objBuscado = modificados.find(
				obj => obj.idReSign === `${idRepl.flat()}`
			);
			CanvasDragResize(objBuscado);
		} else {
			idRepl.forEach(id => {
				if (document.getElementById(`container-${id}`)) {
					document.getElementById(`container-${id}`).remove();
				}
			});

			modificados.forEach(mod => {
				CanvasDragResize(mod);
			});
		}
	}

	function aplicarCampos() {
		let totaElem = document.getElementsByClassName('pdf-canvas');
		let acumData = 0;
		let listPost = [];
		if (aplicados) {
			let totNom = [];
			let datComp = document.getElementById(idPdfContainer);
			// let totaElem = document.getElementsByClassName('pdf-canvas');
			// let acumData = 0;
			// let listPost = [];

			if (datComp) {
				setAplicados(false);
				let posCamp = 1;
				data.documentos.map(doc => {
					doc.manuscrita.map(fir => {
						let maxMarIzq = 0;
						let finaPosX;
						let fProps;
						let tamaPage;
						let paginasAnteriores;
						let tamaMarg;
						let restPage;
						let finaPosY;
						let nomCamp;
						if (window.innerWidth <= 2000) {
							//fir es el recorrido de manuscrita (cada uno de los campos de firma)
							let anchoOriginalPagina;
							let altoOriginalPagina;
							let nuevoAnchoPagina; //Identificar en que pagina estoy y poner el dato aqui
							let nuevoAltoPagina;
							let anchoOriginalFirma;
							let altoOriginalFirma;
							let factorEscalaAncho;
							let factorEscalaAlto;
							let nuevoAnchoFirma;
							let nuevoAltoFirma;
							let posicionOriginalXFirma;
							let posicionOriginalYFirma;
							let nuevoEjeX;
							let nuevoEjeY;
							for (var i = 0; i < totaElem.length; i++) {
								let resPo = totaElem[i].id.split('_');
								let docv = Number(resPo[1]);
								let pagv = Number(resPo[2]);
								let ancho = totaElem[i].offsetWidth;
								let alto = totaElem[i].offsetHeight;
								let arrd = { d: docv, p: pagv, v: acumData, ancho, alto };
								acumData += totaElem[i].offsetHeight;
								listPost.push(arrd);
							}
							anchoOriginalPagina = fir.pageWidth;
							altoOriginalPagina = fir.pageHeight;
							nuevoAnchoPagina = listPost.find(
								a => a.d == doc.orden - 1 && a.p == fir.pageNumber
							).ancho; //Identificar en que pagina estoy y poner el dato aqui
							nuevoAltoPagina = listPost.find(
								a => a.d == doc.orden - 1 && a.p == fir.pageNumber
							).alto;
							anchoOriginalFirma = fir.ancho;
							altoOriginalFirma = fir.alto;
							factorEscalaAncho = nuevoAnchoPagina / anchoOriginalPagina;
							factorEscalaAlto = nuevoAltoPagina / altoOriginalPagina;
							nuevoAnchoFirma = anchoOriginalFirma * factorEscalaAncho;
							nuevoAltoFirma = altoOriginalFirma * factorEscalaAlto;
							posicionOriginalXFirma = fir.eje_x;
							posicionOriginalYFirma = fir.eje_y;
							nuevoEjeX = posicionOriginalXFirma * factorEscalaAncho;
							nuevoEjeY = posicionOriginalYFirma * factorEscalaAlto;

							if (datComp.clientWidth - nuevoAnchoPagina > 0) {
								maxMarIzq = (datComp.clientWidth - nuevoAnchoPagina) / 2;
							} else {
								maxMarIzq = 0;
							}
							paginasAnteriores =
								listPost.findIndex(
									a => a.d === doc.orden - 1 && a.p === fir.pageNumber
								) + 1;
							finaPosX =
								window.innerWidth <= 1252 ? nuevoEjeX : nuevoEjeX + maxMarIzq;
							tamaPage = listPost.find(
								a => a.d == doc.orden - 1 && a.p == fir.pageNumber
							).v;
							//tamaMarg = paginasAnteriores * 2 - 1; //Tengo esta margen (manuscrita.pagina * 2px 1px de arriba, y 1 px de abajo)le quito 1px porque el primer doc no lleva 1 px de la margen de arriba
							restPage = tamaPage;
							finaPosY = nuevoEjeY + restPage < 0 ? 0 : nuevoEjeY + restPage;
							nomCamp = 'cpf_' + doc.orden + '_' + posCamp;
							totNom.push(nomCamp);
							//identifico el documento y la pagina

							fProps = {
								source: 'campoVacio',
								pageWidth: nuevoAnchoPagina,
								isFieldempty: true,
								isReSign: true,
								idReSign: nomCamp,
								posY: finaPosY,
								posX: finaPosX,
								reSignWidth: nuevoAnchoFirma,
								reSignHeigth: nuevoAltoFirma,
								imgRoute: AddSignature,
								onclick: assignSignatureImage,
								typeImg: 'png',
								colorFirmante: '#84ffe7',
								idPdfContainer: idPdfContainer,
								position: paginasAnteriores,
							};
						} else {
							for (var i = 0; i < totaElem.length; i++) {
								let resPo = totaElem[i].id.split('_');
								let docv = Number(resPo[1]);
								let pagv = Number(resPo[2]);
								let arrd = { d: docv, p: pagv, v: acumData };
								acumData += totaElem[i].height;
								listPost.push(arrd);
							}
							finaPosX = fir.eje_x + maxMarIzq;
							tamaPage = listPost.find(
								a => a.d == doc.orden - 1 && a.p == fir.pageNumber
							).v; //identifico el documento y la pagina
							paginasAnteriores =
								listPost.findIndex(
									a => a.d === doc.orden - 1 && a.p === fir.pageNumber
								) + 1;
							tamaMarg = paginasAnteriores * 2 - 1; //Tengo esta margen (manuscrita.pagina * 2px 1px de arriba, y 1 px de abajo)le quito 1px porque el primer doc no lleva 1 px de la margen de arriba
							restPage = tamaPage + tamaMarg;
							finaPosY = fir.eje_y + restPage < 0 ? 0 : fir.eje_y + restPage;
							nomCamp = 'cpf_' + doc.orden + '_' + posCamp;
							totNom.push(nomCamp);
							fProps = {
								source: 'campoVacio',
								pageWidth: fir.pageWidth,
								isFieldempty: true,
								isReSign: true,
								idReSign: nomCamp,
								posY: finaPosY,
								posX: finaPosX,
								reSignWidth: fir.ancho,
								reSignHeigth: fir.alto,
								imgRoute: AddSignature,
								onclick: assignSignatureImage,
								typeImg: 'png',
								colorFirmante: '#84ffe7',
								idPdfContainer: idPdfContainer,
								position: paginasAnteriores,
							};
						}
						setSignFields(prevSignFields => [...prevSignFields, fProps]);

						CanvasDragResize(fProps);
						posCamp += 1;
					});
				});
				setMxSignOnScreen(posCamp - 1);
			}
			setTotaPositionSign(totNom);
		}
	}

	function findNextSignature(btForw) {
		let mxData = mnSignOnScreen;
		if (btForw) {
			mxData = mxData + 1 > mxSignOnScreen ? 1 : mxData + 1;
		} else {
			mxData = mxData - 1 <= 0 ? mxSignOnScreen : mxData - 1;
		}
		let totCamp = document.getElementsByClassName('container-sign');
		let finData = mxData - 1;
		setMnSignOnScreen(mxData);
		document.getElementById(totCamp[finData].id).scrollIntoView();
	}

	const eliminarDataFirma = id => {
		const PosicionesFirmantes = id;
		let array1 = sendData.documents;
		let array2 = data.documentos;
		const [_, x1, x2] = PosicionesFirmantes.split('_').map(x => parseInt(x));
		const listaManuscrita = [];
		for (let i = 0; i < array2.length; i++) {
			const documentoActual = array2[i];
			// Si el objeto actual tiene propiedades "manuscrita" y su longitud es mayor a cero, agregamos todas las manuscritas al array "listaManuscrita"
			if (documentoActual.manuscrita && documentoActual.manuscrita.length > 0) {
				listaManuscrita.push(...documentoActual.manuscrita);
			}
		}

		let objetoEncontrado = array2.find(objeto => objeto.orden === x1);

		// Si encontramos el objeto, buscamos el objeto correspondiente en array1
		if (objetoEncontrado) {
			let objetoArray1 = array1.find(
				objeto => objeto.nombre === objetoEncontrado.nombre
			);

			// Si encontramos el objeto correspondiente en array1, recorremos la propiedad typeSignature
			if (objetoArray1) {
				objetoArray1.typeSignature.forEach((typeSignature, index) => {
					// Comparamos los valores de typeSignature con los de listaManuscrita[x2]
					if (
						typeSignature.posXY.pageNumber ===
							listaManuscrita[x2 - 1].pageNumber &&
						typeSignature.posXY.x === listaManuscrita[x2 - 1].eje_x &&
						typeSignature.posXY.y === listaManuscrita[x2 - 1].eje_y &&
						typeSignature.posXY.width === listaManuscrita[x2 - 1].ancho &&
						typeSignature.posXY.height === listaManuscrita[x2 - 1].alto &&
						typeSignature.posXY.pageWidth ===
							listaManuscrita[x2 - 1].pageWidth &&
						typeSignature.posXY.pageHeight ===
							listaManuscrita[x2 - 1].pageHeight
					) {
						// Si encontramos una coincidencia, eliminamos la propiedad typeSignature de ese objeto
						if (objetoArray1.typeSignature.length === 1) {
							objetoArray1.typeSignature = [];
						} else {
							objetoArray1.typeSignature.splice(index, 1);
						}
					}
				});
			}
		}
		return setSendData({ ...sendData, documents: array1 });
	};

	//Se restablece la imagen con el boton cerrar
	// async function deleteImageField(e, idDelete) {
	//   const modificados = signFields.map(field => {
	//     if (field.idReSign === idDelete) {
	//       return {
	//         ...field, typeImg: "png", imgRoute: AddSignature, colorFirmante: "#84FFE7", source: "campoVacio"
	//       }
	//     }
	//     return field;
	//   })
	//   setSignFields(modificados)
	//   replaceImageField([idDelete], modificados)
	//   await eliminarDataFirma(e);
	//   setFirmasHechas((prevFirmasHechas) => prevFirmasHechas - 1);
	// }

	return (
		<>
			<Grid container spacing={0} direction='row' id='modal-pdf'>
				<Container component='main' maxWidth='lg'>
					<h1 className={'title-background'}>
						{' '}
						{data.firmante.esObservador !== 0
							? 'Observador del documento'
							: 'Firmar documentos'}
					</h1>
				</Container>
				<Container component='main' maxWidth='lg'>
					<Grid
						container
						spacing={0}
						direction='row'
						justifyContent='flex-start'
						alignItems='flex-start'
						className='zone-upload-container'
						maxWidth='md'
					>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Paper elevation={0} className={'addSigner'}>
								<div className={classes.flex}>
									<div>
										<h2
											style={{
												color: '#E55200',
												fontSize: '24px',
												margin: '3px 0 0 0 ',
											}}
										>
											{data.firmante.esObservador !== 0
												? 'Observador del archivo'
												: 'Firma el archivo'}
										</h2>
										<p className={classes.size}>
											<b>
												{data.firmante.esObservador !== 0
													? 'Revisa y observa los documentos'
													: 'Revisa y firma tus documentos.'}
											</b>
										</p>
									</div>
									<div className={classes.btns}>
										{data.firmante.esObservador === 0 ? (
											<>
												<button
													className={classes.btnRechazar}
													onClick={() => dispatch(modalReject(true))}
												>
													Rechazar
												</button>
												<button
													className={classes.continue}
													onClick={() => botonFirmar()}
												>
													Firmar
												</button>
											</>
										) : (
											<button
												className={classes.continue}
												onClick={() =>
													window.location.assign(LANDING_PAGE_FIRMAYA)
												}
											>
												Salir
											</button>
										)}
									</div>
								</div>
								<Grid container>
									<Grid
										item
										xs={12}
										sm={3}
										style={{
											paddingRight: window.innerWidth < 600 ? '0px' : '38px',
										}}
									>
										{firmaLibre && (
											<div className={classes.flx}>
												<p className={classes.parr}>
													{data.firmante.esObservador !== 0
														? ' '
														: '	Si lo deseas, puedes arrastrar esta opción para incluir tu firma en el documento.'}
												</p>
												{data.firmante.esObservador !== 0 ? null : (
													<Button
														onClick={() => dispatch(modalSign(true))}
														className={classes.btnCampoFirma}
													>
														<div className={classes.divTurqoise}>
															<img
																className={classes.backgroundTurquoise}
																src={IconManuscrita}
															/>
														</div>
														Campo de firma <AddIcon fontSize='small' />
													</Button>
												)}
											</div>
										)}
										{!firmaLibre && firmaManuscrita && (
											<div className={classes.parrafo}>
												Faltan {numeroFaltante} firmas{' '}
												<span className={classes.div}>
													<img
														src={atras}
														style={{ cursor: 'pointer' }}
														onClick={() => findNextSignature(false)}
													/>
													{mnSignOnScreen}/{mxSignOnScreen}
													<img
														src={siguiente}
														style={{ cursor: 'pointer' }}
														onClick={() => findNextSignature(true)}
													/>
												</span>
											</div>
										)}
										{firmaManuscrita && <hr className={classes.hr} />}
										<p className={classes.listado}>Listado firmantes</p>
										{firmantes
											?.filter(firmante => firmante.esObservador === 0)
											.map((firmante, index) =>
												firmante.estado == 1 &&
												data.firmante.correo === firmante.email ? (
													<>
														<div className={classes.flexs2}>
															<div className={classes.circle2}>
																{' '}
																{index + 1}{' '}
															</div>
															<span
																title={firmante.nombre}
																style={{
																	marginLeft: '10px',
																	fontSize: '14px',
																	color: '#000000',
																}}
															>
																{truncarNombreCompleto(firmante.nombre, '', 25)}
																{firmante.esAprobador === 1 && (
																	<Tooltip title='Sin aprobar' arrow>
																		<CheckCircleIcon
																			style={{
																				color: 'black',
																				fontSize: '1rem',
																				marginLeft: '8px',
																			}}
																		/>
																	</Tooltip>
																)}
																{firmante.esAprobador === 2 && (
																	<Tooltip title='Aprobado' arrow>
																		<CheckCircleIcon
																			style={{
																				color: '#e55302',
																				fontSize: '1rem',
																				marginLeft: '18px',
																			}}
																		/>
																	</Tooltip>
																)}
															</span>
														</div>
													</>
												) : firmante.estado == 1 &&
												  data.firmante.correo !== firmante.email ? (
													<>
														<div className={classes.flexs2}>
															<div
																className={classes.circleDisabled}
																style={{
																	color: lineal ? '#c8c8c8' : '#000000',
																}}
															>
																{index + 1}{' '}
															</div>
															<span
																title={firmante.nombre}
																style={{
																	marginLeft: '10px',
																	fontSize: '14px',
																	color: lineal ? '#c8c8c8' : '#000000',
																}}
															>
																{truncarNombreCompleto(firmante.nombre, '', 25)}
																{firmante.esAprobador === 1 && (
																	<Tooltip title='Sin aprobar' arrow>
																		<CheckCircleIcon
																			style={{
																				color: 'black',
																				fontSize: '1rem',
																				marginLeft: '8px',
																			}}
																		/>
																	</Tooltip>
																)}
																{firmante.esAprobador === 2 && (
																	<Tooltip title='Aprobado' arrow>
																		<CheckCircleIcon
																			style={{
																				color: '#e55302',
																				fontSize: '1rem',
																				marginLeft: '18px',
																			}}
																		/>
																	</Tooltip>
																)}
															</span>
														</div>
													</>
												) : firmante.estado == 2 ? (
													<>
														<div className={classes.flexs2}>
															<div className={classes.circle}> ✔ </div>
															<span
																title={firmante.nombre}
																style={{
																	marginLeft: '10px',
																	fontSize: '14px',
																	color: lineal ? '#c8c8c8' : '#000000',
																}}
															>
																{truncarNombreCompleto(firmante.nombre, '', 25)}

																{firmante.esAprobador === 2 && (
																	<Tooltip title='Aprobado' arrow>
																		<CheckCircleIcon
																			style={{
																				color: '#e55302',
																				fontSize: '1rem',
																				marginLeft: '18px',
																			}}
																		/>
																	</Tooltip>
																)}
															</span>
														</div>
													</>
												) : firmante.estado == 6 &&
												  data.firmante.correo === firmante.email ? (
													<>
														<div className={classes.flexs2}>
															<div className={classes.circle2}>
																{' '}
																{index + 1}{' '}
															</div>
															<span
																title={firmante.nombre}
																style={{
																	marginLeft: '10px',
																	fontSize: '14px',
																	color: '#000000',
																}}
															>
																{truncarNombreCompleto(firmante.nombre, '', 25)}
																{firmante.esAprobador === 1 && (
																	<Tooltip title='Sin aprobar' arrow>
																		<CheckCircleIcon
																			style={{
																				color: 'black',
																				fontSize: '1rem',
																				marginLeft: '8px',
																			}}
																		/>
																	</Tooltip>
																)}
																{firmante.esAprobador === 2 && (
																	<Tooltip title='Aprobado' arrow>
																		<CheckCircleIcon
																			style={{
																				color: '#e55302',
																				fontSize: '1rem',
																				marginLeft: '18px',
																			}}
																		/>
																	</Tooltip>
																)}
															</span>
														</div>
													</>
												) : firmante.estado == 6 &&
												  data.firmante.correo !== firmante.email ? (
													<>
														<div className={classes.flexs2}>
															<div className={classes.circleDisabled}>
																{index + 1}{' '}
															</div>
															<span
																title={firmante.nombre}
																style={{
																	marginLeft: '10px',
																	fontSize: '14px',
																	color: lineal ? '#c8c8c8' : '#000000',
																}}
															>
																{truncarNombreCompleto(firmante.nombre, '', 25)}
																{firmante.esAprobador === 1 && (
																	<Tooltip title='Sin aprobar' arrow>
																		<CheckCircleIcon
																			style={{
																				color: 'black',
																				fontSize: '1rem',
																				marginLeft: '8px',
																			}}
																		/>
																	</Tooltip>
																)}
																{firmante.esAprobador === 2 && (
																	<Tooltip title='Aprobado' arrow>
																		<CheckCircleIcon
																			style={{
																				color: '#e55302',
																				fontSize: '1rem',
																				marginLeft: '18px',
																			}}
																		/>
																	</Tooltip>
																)}
															</span>
														</div>
													</>
												) : null
											)}
										{firmantes?.some(
											firmante => firmante.esObservador !== 0
										) && (
											<p className={classes.listado2}>Listado de observador</p>
										)}
										{firmantes
											?.filter(firmante => firmante.esObservador !== 0)
											.map((firmante, index) =>
												firmante.estado == 1 &&
												data.firmante.correo === firmante.email ? (
													<>
														<div className={classes.flexs2}>
															<span
																title={firmante.nombre}
																style={{
																	marginLeft: '10px',
																	fontSize: '14px',
																	color: '#000000',
																}}
															>
																{truncarNombreCompleto(firmante.nombre, '', 25)}

																{firmante.esObservador === 1 && (
																	<Tooltip title='Observador' arrow>
																		<VisibilityIcon
																			style={{
																				color: 'black',
																				fontSize: '1rem',
																				marginLeft: '18px',
																			}}
																		/>
																	</Tooltip>
																)}
																{firmante.esObservador === 2 && (
																	<Tooltip title='Observado' arrow>
																		<VisibilityIcon
																			style={{
																				color: '#e55302',
																				fontSize: '1rem',
																				marginLeft: '18px',
																			}}
																		/>
																	</Tooltip>
																)}
															</span>
														</div>
													</>
												) : firmante.estado == 1 &&
												  data.firmante.correo !== firmante.email ? (
													<>
														<div className={classes.flexs2}>
															<span
																title={firmante.nombre}
																style={{
																	marginLeft: '10px',
																	fontSize: '14px',
																	color: lineal ? '#c8c8c8' : '#000000',
																}}
															>
																{truncarNombreCompleto(firmante.nombre, '', 25)}

																{firmante.esObservador === 1 && (
																	<Tooltip title='Observador' arrow>
																		<VisibilityIcon
																			style={{
																				color: 'black',
																				fontSize: '1rem',
																				marginLeft: '18px',
																			}}
																		/>
																	</Tooltip>
																)}
																{firmante.esObservador === 2 && (
																	<Tooltip title='Observado' arrow>
																		<VisibilityIcon
																			style={{
																				color: '#e55302',
																				fontSize: '1rem',
																				marginLeft: '18px',
																			}}
																		/>
																	</Tooltip>
																)}
															</span>
														</div>
													</>
												) : firmante.estado == 2 ? (
													<>
														<div className={classes.flexs2}>
															{/* <div className={classes.circle}> ✔ </div> */}
															<span
																title={firmante.nombre}
																style={{
																	marginLeft: '10px',
																	fontSize: '14px',
																	color: lineal ? '#c8c8c8' : '#000000',
																}}
															>
																{truncarNombreCompleto(firmante.nombre, '', 25)}

																{firmante.esObservador === 1 && (
																	<Tooltip title='Observador' arrow>
																		<VisibilityIcon
																			style={{
																				color: 'black',
																				fontSize: '1rem',
																				marginLeft: '18px',
																			}}
																		/>
																	</Tooltip>
																)}
																{firmante.esObservador === 2 && (
																	<Tooltip title='Observado' arrow>
																		<VisibilityIcon
																			style={{
																				color: '#e55302',
																				fontSize: '1rem',
																				marginLeft: '18px',
																			}}
																		/>
																	</Tooltip>
																)}
															</span>
														</div>
													</>
												) : firmante.estado == 6 &&
												  data.firmante.correo === firmante.email ? (
													<>
														<div className={classes.flexs2}>
															<span
																title={firmante.nombre}
																style={{
																	marginLeft: '10px',
																	fontSize: '14px',
																	color: '#000000',
																}}
															>
																{truncarNombreCompleto(firmante.nombre, '', 25)}

																{firmante.esObservador === 1 && (
																	<Tooltip title='Observador' arrow>
																		<VisibilityIcon
																			style={{
																				color: 'black',
																				fontSize: '1rem',
																				marginLeft: '18px',
																			}}
																		/>
																	</Tooltip>
																)}
																{firmante.esObservador === 2 && (
																	<Tooltip title='Observado' arrow>
																		<VisibilityIcon
																			style={{
																				color: '#e55302',
																				fontSize: '1rem',
																				marginLeft: '18px',
																			}}
																		/>
																	</Tooltip>
																)}
															</span>
														</div>
													</>
												) : firmante.estado == 6 &&
												  data.firmante.correo !== firmante.email ? (
													<>
														<div className={classes.flexs2}>
															{/* <div className={classes.circleDisabled}>
																{index + 1}{' '}
															</div> */}
															<span
																title={firmante.nombre}
																style={{
																	marginLeft: '10px',
																	fontSize: '14px',
																	color: lineal ? '#c8c8c8' : '#000000',
																}}
															>
																{truncarNombreCompleto(firmante.nombre, '', 25)}

																{firmante.esObservador === 1 && (
																	<Tooltip title='Observador' arrow>
																		<VisibilityIcon
																			style={{
																				color: 'black',
																				fontSize: '1rem',
																				marginLeft: '18px',
																			}}
																		/>
																	</Tooltip>
																)}
																{firmante.esObservador === 2 && (
																	<Tooltip title='Observado' arrow>
																		<VisibilityIcon
																			style={{
																				color: '#e55302',
																				fontSize: '1rem',
																				marginLeft: '18px',
																			}}
																		/>
																	</Tooltip>
																)}
															</span>
														</div>
													</>
												) : null
											)}
										{!firmaLibre && firmaManuscrita && (
											<div className={classes.parrafo2}>
												Faltan {numeroFaltante} firmas{' '}
												<span className={classes.div}>
													<img
														src={atras}
														style={{ cursor: 'pointer' }}
														onClick={() => findNextSignature(false)}
													/>
													{mnSignOnScreen}/{mxSignOnScreen}
													<img
														src={siguiente}
														style={{ cursor: 'pointer' }}
														onClick={() => findNextSignature(true)}
													/>
												</span>
											</div>
										)}
									</Grid>
									<Grid item xs={12} sm={9} md={6} lg={6}>
										<BotoneraEditar
											scale={scale}
											setScale={setScale}
											firmas={firmas}
											reSignImage={reSignImage}
											sendData={sendData}
											deleteSign={deleteSign}
											setReSign={setReSign}
											imageSignature={imageSignature}
										/>
										<Box className={classes.boxes}>
											<Grid container style={{ width: '100%', height: '100%' }}>
												{
													<div
														id={idPdfContainer}
														className='drag-container'
														style={{ width: '100%', height: '100%' }}
													>
														{imgSign &&
															imageSignature.map(signature => (
																<CanvasDragResize
																	colorFirmante={signature.colorFirmante}
																	idPdfContainer={idPdfContainer}
																	currentDoc={currentDoc}
																	setCurrentDoc={setCurrentDoc}
																	imgRoute={signature.imgRoute}
																	endRender={async () => {
																		setImgSign(null);
																		setReSign(false);
																	}}
																	onMouseUp={onMouseUp}
																	typeImg={signature.typeImg}
																	deleteSign={signature.deleteSign}
																	isReSign={isReSign}
																	posX={signature.posX}
																	posY={signature.posY}
																	reSignWidth={signature.reSignWidth}
																	reSignHeigth={signature.reSignHeigth}
																	idReSign={signature.idReSign}
																	predefinedSource={predefinedSource}
																	setPredefinedSource={setPredefinedSource}
																	onclick={!firmaLibre && assignSignatureImage}
																/>
															))}
													</div>
												}
											</Grid>
										</Box>
									</Grid>
									<Grid
										item
										sm={3}
										className={classes.none}
										style={{ paddingLeft: '28px' }}
									>
										<Grid item className={classes.miniContainer}>
											<Grid item className={'fullContainer'}>
												<Grid className='pdf-miniaturas pdf-miniaturasCF'>
													<div style={{ display: 'none' }}>
														<input
															accept='application/pdf'
															multiple=''
															type='file'
															id='Upload'
															onChangeCapture={handlerChange}
														/>
													</div>
													<Grid
														id={idThumbnailsContainer}
														className='pdf-miniaturas-firmas pdf-miniaturas-firmasCF'
														onClick={refreshDoc}
													></Grid>
													<Grid item xs={0}>
														<div
															style={{
																display: 'none',
																padding: '8px',
																float: 'right',
															}}
															id='dvCurrentPageNumber'
														>
															Página: {currentPage}
														</div>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
									<div className={classes.btns2} style={{ marginTop: '20px' }}>
										{data.firmante.esObservador === 0 ? (
											<>
												<button
													className={classes.btnRechazar}
													onClick={() => dispatch(modalReject(true))}
												>
													Rechazar
												</button>
												<button
													className={classes.continue}
													onClick={() => botonFirmar()}
												>
													Firmar
												</button>
											</>
										) : (
											<button
												className={classes.continue}
												onClick={() =>
													window.location.assign(LANDING_PAGE_FIRMAYA)
												}
											>
												Salir
											</button>
										)}
									</div>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Container>
				{modalRejects && <ModalReject correoCreador={correoCreador} />}
				{modalConfirmRejects && (
					<ModalConfirmReject
						idCircuito={data.circuito.id}
						idFirmante={data.firmante.id}
						setLoading={setLoading}
						setErrorS3={setErrorS3}
						setRedireccionar={setRedireccionar}
					/>
				)}
				{modalUpss && (
					<ModalUps correoCreador={correoCreador} mensaje={errorS3} />
				)}
				{modalSignatureRejects && <ModalSignatureRejected />}
				{modalMissingSignature && (
					<ModalMissingSignatures faltantes={numeroFaltante} />
				)}
				{modalSigns && (
					<ModalSign
						eliminarDataFirma={eliminarDataFirma}
						targetId={targetId}
						setTargetId={setTargetId}
						setSignImage={setSignImage}
						setDigitalSign={setDigitalSign}
						setSignPredefined={setSignPredefined}
						setAddDataCert={setAddDataCert}
						aplicarFirma={aplicarFirma}
						digital={digital}
						setTypeImg={setTypeImg}
						manuscrita={manuscrita}
						signPredefined={signPredefined}
						showComplete={showComplete}
						setDigitalImg={setDigitalImg}
						tieneFirmaDigital={false}
						sendData={sendData}
						setStateFirmas={setStateFirmas}
						todosLosCampos={todosLosCampos}
						setTodosLosCampos={setTodosLosCampos}
						devuelveImagen={true}
						campoFirma={campoFirma}
						firmarConCampos={firmarConCampos}
						userFirmaya={false}
					/>
				)}
			</Grid>
			{statusModalSign && (
				<ModalOtpCF
					services={services}
					vencimiento={vencimiento}
					statusModalSign={statusModalSign}
					setStatusModalSign={setStatusModalSign}
					email={data.firmante.correo}
					sendElecSign={sendElecSign}
					setSendElecSign={setSendElecSign}
					setLoading={setLoading}
					loading={loading}
					firmante={data.firmante.id}
					circuito={data.circuito.id}
					sendData={sendData}
					ultimaPosicion={ultimaPosicion}
					setErrorS3={setErrorS3}
					lineal={lineal}
					solicitarCodeOTP={solicitarCodeOTP}
					onMobileNumberChange={handleMobileNumberChange}
					onSendOptionChange={onSendOptionChange}
					option={sendOption}
					cellphone={mobileNumber}
				/>
			)}

			{firmado && <ModalFinishedSigning />}
			{loading && <Loading />}
		</>
	);
};

export default SignerFromMail;
