import { Box, Container, Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import FirmarSection from '../../Firmar/FirmarSection';
import Plan from './Plan';
import MisFirmas from './MisFirmas';
import { getManuscritaByUser } from '../../../redux/actions/SignAction';
import {
	getPlan,
	deleteFile,
	getListSignedDocumentCFHome,
	getListDocumentPending,
	getListDocumentPendingObserve,
} from '../../../redux/actions/ConsultSignedDocumentsAction';
import SignedDocumentsResponsive from '../../../components/SignedDocumentsResponsive';
import { useSnackbar } from 'notistack';
import Loading from '../../../components/Loading';
import DocumentsTable from './DocumentsTable';
import PendingDocsView from '../../../components/PendingDocsView';
import ConsultSignedDocuments from '../../ConsultSignedDocument/ConsultSignedDocuments';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		overflow: 'auto !important',
		flexDirection: 'column',
		alignItems: 'center',
	},
	table: {
		position: 'relative',
		padding: '1px',
		marginBottom: '20px',
		marginTop: '19px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #F5AE19',
		borderRadius: '10px',
		width: '92%',
		marginLeft: '20px',
		marginRight: '20px',
	},
	paper: {
		marginTop: '-33px',
		width: '100% !important',
		paddingRight: '2% !important',
		paddingLeft: '2% !important',
	},
	title: {
		margin: 0,
		color: 'white',
	},
	titleBackground: {
		margin: '0',
		background: 'rgba(0, 0, 0, 0.3)',
		height: '100px',
		width: '100%',
	},
	box: {
		height: '4vh',
	},
	pending: {
		position: 'absolute',
		zIndex: 500,
		top: '80px',
		width: '57.5%',
		maxWidth: '965px',
		'@media(max-width:1279px)': {
			width: '51%',
			maxWidth: '655px',
		},
		'@media(max-width:1235px)': {
			width: '75%',
			maxWidth: '930px',
		},
		'@media(max-width:1200px)': {
			top: '70px',
		},
		'@media(max-width:991px)': {
			top: '0',
			position: 'relative',
			marginBottom: '10px',
		},
	},
}));

const Dashboard = props => {
	const {
		getSignManuscrita,
		listManuscrita,
		getListDocument,
		getPlan,
		rows,
		plan,
		deleteFile,
		rowsPending,
		getListDocumentPending,
	} = props;
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [isResponsive, setResponsive] = useState(
		window.innerWidth <= 600 ? false : true
	);
	const { enqueueSnackbar } = useSnackbar();
	useEffect(() => {
		(async () => {
			await Promise.all([
				getSignManuscrita(enqueueSnackbar),
				getListDocumentPending(true),
				
				getPlan(enqueueSnackbar),
			]);
			setLoading(false);
		})();
	}, []);

	window.addEventListener(
		'resize',
		function (event) {
			if (this.window.innerWidth <= 600) setResponsive(false);
			else setResponsive(true);
		},
		true
	);

	if (loading) return <Loading />;
	return (
		<>
			<div className={classes.root}>
				<div className='title-background-home'>
					<Container component='main' maxWidth='md'>
						<h1></h1>
					</Container>
				</div>

				<Grid
					container
					spacing={0}
					direction='row'
					justify='flex-start'
					alignItems='flex-start'
					className='zone-upload-container'
				>
					{/* {rowsPending.length>0&&
						<Grid item xs={12} sm={12} md={8} lg={9} className={classes.pending}>
						<PendingDocsView cantidad={rowsPending.length}/>
					</Grid>
					} */}

					<Grid
						// style={{ paddingTop: '8px' }}
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
					>
						{/* {isResponsive ? (
							<DocumentsTable
								rows={rows}
								getListDocument={getListDocument}
								setLoading={setLoading}
								deleteFile={deleteFile}
							/>
						) : (
							<Paper elevation={1} className={classes.table}>
								<SignedDocumentsResponsive isDashboard={true} />
							</Paper>
						)} */}

						<ConsultSignedDocuments />
					</Grid>
					{/* <Grid item xs={12} sm={12} md={4} lg={3}>
							<Grid
								container
								direction='row'
								justify='flex-start'
								alignItems='center'
							>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Plan
										paquete={plan.paquete}
										isResponsive={isResponsive}
										fecha={plan.date}
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<MisFirmas listManuscrita={listManuscrita} />
								</Grid>
							</Grid>
						</Grid> */}
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<FirmarSection isDashboard={true} />
						<br />
						<br />
						<br />

						{/* {isResponsive ? (
							<DocumentsTable
								rows={rows}
								getListDocument={getListDocument}
								setLoading={setLoading}
								deleteFile={deleteFile}
							/>
						) : (
							<Paper elevation={1} className={classes.table}>
								<SignedDocumentsResponsive isDashboard={true} />
							</Paper>
						)} */}
					</Grid>
					{/* <Box className={classes.box}></Box> */}
				</Grid>
			</div>
		</>
	);
};

const mapStateToProps = state => ({
	listManuscrita: state.sign.listManuscrita,
	rows: state.consultSignedDocument.listSignedDocument,
	plan: state.consultSignedDocument.planList,
	rowsPending: state.consultSignedDocument.pendingSign,
});

const mapDispatchToProps = {
	getSignManuscrita: getManuscritaByUser,
	getListDocument: getListSignedDocumentCFHome,
	getPlan: getPlan,
	deleteFile: deleteFile,
	getListDocumentPending,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
